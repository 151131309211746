export const VismaPayLogo = (props: React.SVGProps<SVGSVGElement>) => (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="657.73" height="214.37" viewBox="0 0 657.73 214.37" {...props}>
  <g>
    <path fill="#035142" d="m107.11,0H0v.48l83.48,64v56.24h25.2c32.9,0,59.57-26.67,59.57-59.57h0C168.26,27.38,140.88,0,107.11,0Z"/>
    <polygon fill="#50b25c" points="83.48 64.49 83.48 214.37 0 150.37 0 .48 83.48 64.49"/>
  </g>
  <g>
    <path fill="#035142" d="m212.52,69.97h5.73l22.84,63.67,22.84-63.67h5.73l-28.58,78.55-28.58-78.55Z"/>
    <path fill="#035142" d="m278.65,72.75c2.04,0,3.61,1.57,3.61,3.61s-1.57,3.51-3.61,3.51-3.51-1.48-3.51-3.51,1.48-3.61,3.51-3.61Zm2.5,16.19v50.41h-4.99v-50.41h4.99Z"/>
    <path fill="#035142" d="m297.61,100.13c0,4.81,3.24,7.03,10.36,9.25,11.19,2.96,21.09,6.75,21.09,17.02,0,8.05-7.03,14.34-17.02,14.34-11.47,0-18.5-7.95-21.09-11.56l3.88-2.96c1.76,2.13,6.94,9.43,17.29,9.43,6.01,0,11.93-3.33,11.93-9.25,0-6.38-7.31-9.43-15.63-11.65-10.45-2.77-15.82-6.94-15.82-14.71,0-6.66,6.75-12.49,17.02-12.49s15.63,4.99,18.03,8.14l-3.88,2.87c-1.67-2.22-7.03-6.47-14.15-6.47-5.83,0-12.02,2.59-12.02,8.05Z"/>
    <path fill="#035142" d="m344.13,98.55c1.76-3.24,8.51-11.01,18.13-11.01,6.75,0,13.13,2.13,16.09,10.91,1.85-3.24,9.43-10.91,18.96-10.91s18.68,3.88,18.68,23.68v28.12h-4.99v-24.32c0-11.47-.74-22.47-14.61-22.47-7.12,0-13.6,6.01-16.83,11.28.37,2.13.56,4.62.56,7.4v28.12h-4.99v-24.32c0-11.47.09-22.47-13.78-22.47-8.23,0-14.71,8.05-17.2,13.6v33.2h-4.99v-50.41h4.62l.37,9.62Z"/>
    <path fill="#035142" d="m450.95,87.64c7.49,0,15.17,4.07,18.96,10.91l.37-9.62h4.62v50.41h-4.53l-.37-9.62c-3.79,6.84-11.56,10.91-19.05,10.91-14.61,0-25.25-10.91-25.25-26.45s10.64-26.54,25.25-26.54Zm-.65,4.99c-11.84,0-19.79,9.9-19.79,21.64s7.95,21.36,19.79,21.36,19.79-9.53,19.79-21.36-8.6-21.64-19.79-21.64Z"/>
    <path fill="#035142" d="m590,88.49l-.41,5.58c-3.71-4.32-9.49-6.96-15.35-6.96-14.93,0-26.62,11.86-26.62,26.99s11.44,27,26.62,27c5.87,0,11.78-2.68,15.37-6.88l.49,5.59h11.22v-51.3h-11.32Zm-15.4,41.44c-8.57,0-15.27-6.96-15.27-15.84s6.7-16.01,15.27-16.01,15.09,7.34,15.09,16.01-6.63,15.84-15.09,15.84Z"/>
    <polygon fill="#035142" points="657.73 88.49 627.59 162.91 615.36 162.91 627.17 130.94 608.19 88.49 621.2 88.49 633.38 116.46 645.02 88.49 657.73 88.49"/>
    <path fill="#035142" d="m535.18,117.25c-5.3,5.68-12.78,8.94-20.53,8.94-.34,0-5.32,0-5.32,0l-.02-12.62c.49,0,5.39,0,5.73,0,8.71,0,15.03-6.5,15.03-15.45s-6.92-15.42-15.42-15.42-15.03,6.48-15.39,14.77l-.12,51.34h-12.59s.03-44.57.03-50.68c0-7.75,3.26-15.23,8.94-20.53,5.76-5.37,13.27-8.02,21.14-7.48,13.85.97,25.03,12.14,26,26,.55,7.87-2.1,15.38-7.47,21.14Z"/>
  </g>
</svg>
)

