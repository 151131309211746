import { ExternalPayment, InvoicePayment, PenaltyFee } from './modals'
import { Countries } from '../../flamingo/config/ConfigCountries'
import { useGetNonTopAccounts, useGetPersons } from '../../api/cards/queries.ts'
import MyntLoader from '../../mynt-components/components/MyntLoader.tsx'

export type TogglableModals = {
  invoicePenalty?: Parameters<typeof PenaltyFee>[0]['invoice']
  invoicePayment?: boolean
  externalPayment?: boolean
}

// Returns the first paramter type of a prop which is a method. Example: <Component onSubmit={(data) => {}} /> will return the type of data (the first parameter of onSubmit)
type GetPropMethodParameter<C extends (...args: any[]) => any, P extends keyof Parameters<C>[0]> = Parameters<Parameters<C>[0][P]>[0]

type Modal = keyof TogglableModals

type InvoicePenaltyParams = [modal: 'invoicePenalty', data: GetPropMethodParameter<typeof PenaltyFee, 'onSubmit'>]
type InvoicePaymentParams = [modal: 'invoicePayment', data: GetPropMethodParameter<typeof InvoicePayment, 'onSubmit'>]
type ExternalPaymentParams = [modal: 'externalPayment', data: GetPropMethodParameter<typeof ExternalPayment, 'onSubmit'>]

// There is probably a better way of doing the types, but this works for now. We want data to change type depending on the modal string
export type AllOnSubmits = InvoicePenaltyParams | InvoicePaymentParams | ExternalPaymentParams

type InvoiceModalsProps = {
  onClose: (modal: Modal) => void
  onSubmit: ([modal, data]: AllOnSubmits) => void
  modals: TogglableModals
  customer: { country: string; id: string; companyName: string }
}

export const InvoiceModals = ({ onSubmit, modals, customer, onClose }: InvoiceModalsProps) => {
  const handleClose = (modal: Modal) => () => onClose(modal)
  // eslint-disable-next-line prettier/prettier
  const handleSubmit = (modal: AllOnSubmits[0]) => (data: AllOnSubmits[1]) => onSubmit([modal, data] as AllOnSubmits)

  const { data: accounts = [], isLoading: isLoadingAccounts } = useGetNonTopAccounts(customer.id)
  const { data: persons = [], isLoading: isLoadingPersons } = useGetPersons(customer.id)

  if (isLoadingAccounts || isLoadingPersons) return <MyntLoader fullWidth />

  return (
    <>
      <ExternalPayment
        customerId={customer.id}
        isOpen={Boolean(modals.externalPayment)}
        customerCountry={customer.country as Countries}
        onClose={handleClose('externalPayment')}
        onSubmit={handleSubmit('externalPayment')}
      />
      <InvoicePayment
        accounts={accounts}
        persons={persons}
        customerId={customer.id}
        companyName={customer.companyName}
        isOpen={Boolean(modals.invoicePayment)}
        onClose={handleClose('invoicePayment')}
        onSubmit={handleSubmit('invoicePayment')}
      />
      <PenaltyFee
        isOpen={Boolean(modals.invoicePenalty)}
        onClose={handleClose('invoicePenalty')}
        onSubmit={handleSubmit('invoicePenalty')}
        invoice={modals.invoicePenalty}
      />
    </>
  )
}
