import { useMutation, useQuery } from '@tanstack/react-query'
import { getAccounts, getAccountStatus, getPersons, orderCard } from './endpoints.ts'
import { orderCardDtoMapper } from './mappers.ts'
import { OrderCardRequest } from './types.ts'

export const useGetAccounts = (customerId: string) =>
  useQuery(['accounts', customerId], () => getAccounts(customerId), {
    staleTime: 0
  })

export const useGetNonTopAccounts = (customerId: string) =>
  useQuery(['NON_TOP_UP_ACCOUNTS', customerId], () => getAccounts(customerId), {
    staleTime: 0,
    select: (data) => data.filter((account) => account.accountType !== 'TOP_ACCOUNT' && account.accountType !== 'PERSONAL_TOP_ACCOUNT')
  })

export const useGetPersons = (customerId: string) =>
  useQuery(['persons', customerId], () => getPersons(customerId), {
    staleTime: 0
  })

export const useGetAccountStatus = (customerId: string, accountId: string | undefined) =>
  useQuery(['accountStatus', customerId, accountId], () => getAccountStatus(customerId, accountId ?? 'will-never-happen'), {
    staleTime: 0,
    enabled: accountId ? accountId.length > 0 : false
  })

export const useOrderCardV3 = (customerId: string) =>
  useMutation(['orderCard'], (cardRequest: OrderCardRequest) => orderCard(customerId, orderCardDtoMapper(cardRequest)), {})
