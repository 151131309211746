import { BackOfficeOrderCardRequestDtoV3 } from 'api/swagger/definitions/backoffice/index.ts'
import { backofficeApi } from '../index.ts'
import { mapAccounts, mapAccountStatus, mapPersons } from './mappers.ts'

export const getAccounts = async (customerId: string) => backofficeApi.getAccountsV5(customerId).then(({ data }) => mapAccounts(data))

export const getAccountStatus = async (customerId: string, accountId: string) =>
  backofficeApi.getAccountStatusV5(customerId, accountId).then(({ data }) => mapAccountStatus(data))

export const getPersons = async (customerId: string) => backofficeApi.getPersonsWithRoles(customerId).then(({ data }) => mapPersons(data))

export const orderCard = (customerId: string, cardRequest: BackOfficeOrderCardRequestDtoV3) =>
  backofficeApi.orderCardV3(customerId, cardRequest).then(({ data }) => data)
