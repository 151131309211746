import { GetAccountsV5200ResponseInner } from '../swagger/definitions/backoffice'

export const isPersonalAccount = (account: GetAccountsV5200ResponseInner): account is PersonalAccount =>
  account.accountType === 'PERSONAL_ACCOUNT'

export const isCorporateAccount = (account: GetAccountsV5200ResponseInner): account is CorporateAccount =>
  account.accountType === 'CORPORATE_ACCOUNT'

export const isTopPersonalAccount = (account: GetAccountsV5200ResponseInner): account is PersonalTopAccount =>
  account.accountType === 'PERSONAL_TOP_ACCOUNT'

export const isTopAccount = (account: GetAccountsV5200ResponseInner): account is TopAccount => account.accountType === 'TOP_ACCOUNT'

interface AccountBase {
  id: string
  name: string
  externalId: string
  customerId: string
  no: number
  hierarchyId: string
  currency: CorporateAccountCurrency
  createdAt: string
  parentAccount: string
}

export interface PersonalAccount extends AccountBase {
  personId: string
  accountType: 'PERSONAL_ACCOUNT'
}

export interface CorporateAccount extends AccountBase {
  accountType: 'CORPORATE_ACCOUNT'
}

export interface TopAccount extends AccountBase {
  accountType: 'TOP_ACCOUNT'
}

export interface PersonalTopAccount extends AccountBase {
  personId: string
  accountType: 'PERSONAL_TOP_ACCOUNT'
}

export type AccountType = 'PERSONAL_ACCOUNT' | 'CORPORATE_ACCOUNT' | 'TOP_ACCOUNT' | 'PERSONAL_TOP_ACCOUNT'

export type Accounts = PersonalAccount | CorporateAccount | TopAccount | PersonalTopAccount

type CorporateAccountCurrency = {
  currencyCode: string
  displayName: string
  symbol: string
  defaultFractionDigits: number
  numericCode: number
  numericCodeAsString: string
}

export type AccountStatus = {
  creditLimit: {
    value: number
    currency: string
  }
  available: {
    value: number
    currency: string
  }
  balance: {
    value: number
    currency: string
  }
}

export type Person = {
  customerId?: string
  log?: string
  id?: string
  rev?: number
  deleted: boolean
  fullName: string
  email?: string
  modifiedAt?: string
  phoneNumber?: string
  partnerExternalId?: string
  enfuceCustomerId?: string
  findityEmployeeId?: string
  blPersonExternalId?: object
  peEmployeeId?: number
  roles: CustomerRole[]
  inviteSentAt?: string
  ssn?: string
}

export enum CustomerRole {
  ADMIN = 'ADMIN',
  SIGNATORY = 'SIGNATORY',
  CONTACT = 'CONTACT',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  CARD_HOLDER = 'CARD_HOLDER',
  ACCOUNTANT = 'ACCOUNTANT',
  USER_ADMIN = 'USER_ADMIN'
}

export enum CardType {
  PHYSICAL_CORPORATE = 'PHYSICAL_CORPORATE',
  VIRTUAL_CORPORATE = 'VIRTUAL_CORPORATE',
  PHYSICAL_PERSONAL = 'PHYSICAL_PERSONAL'
}

export interface OrderPhysicalCardRequest {
  personId: string
  deliveryCompanyName?: string
  address: DeliveryAddress
  label?: string
  monthlyLimit?: number
  type: CardType.PHYSICAL_CORPORATE
}

export interface OrderVirtualCardRequest {
  personId: string
  label?: string
  monthlyLimit?: number
  type: CardType.VIRTUAL_CORPORATE
}

export interface OrderPhysicalPersonalRequest {
  personId: string
  deliveryCompanyName?: string
  address: DeliveryAddress
  label?: string
  creditLimit: number
  type: CardType.PHYSICAL_PERSONAL
}

interface DeliveryAddress {
  address1: string
  address2?: string
  areaCode: string
  city: string
  country: string
}

export type OrderCardRequest = OrderPhysicalCardRequest | OrderVirtualCardRequest | OrderPhysicalPersonalRequest
