import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { BackofficeCardTxType2 } from '../../api/swagger/definitions/backoffice'
import { ModalConfirmDialog } from '../../components/Modals'
import BorderRadius from '../../figma/tokens/BorderRadius'
import ColorsDeprecated from '../../figma/tokens/ColorsDeprecated'
import Spacings from '../../figma/tokens/Spacings'
import { useTransactionsV2 } from '../../api/react-query'
import { transactionTriggerBusinessEventUsingPOST } from '../../api'
import { BackOfficeAllTransactionDtos } from '../../helpers/ProcessHandler'
import useMaterialNotification from '../../hooks/useMaterialNotification'
import TextKeys from '../../libs/TextKeys'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import MyntPopper from '../../mynt-components/components/MyntPopper'
import { When } from '../../mynt-components/components/When'
import { CardCreditRepaymentTransactionDetails, CardCreditRepaymentTransactionRow } from './card-credit-repayment-row'
import { StandardTransactionDetails, StandardTransactionRow } from './standard-transaction-row'
import { StyledActionWrapper, TableFilters } from './support-components'
import { TopupTransactionDetails, TopupTransactionRow } from './topup-transaction-row'
import { UnknownTransactionDetails, UnknownTransactionRow } from './unknown-transaction-row'
import { Access } from 'contexts/permissions'

const getTransactionRowComponents = (transaction: BackOfficeAllTransactionDtos) => {
  switch (transaction.txType) {
    case BackofficeCardTxType2.STANDARD: {
      return {
        Row: () => <StandardTransactionRow transaction={transaction} />,
        Details: () => <StandardTransactionDetails transaction={transaction} />
      }
    }

    case BackofficeCardTxType2.CARD_CREDIT_REPAYMENT: {
      return {
        Row: () => <CardCreditRepaymentTransactionRow transaction={transaction} />,
        Details: () => <CardCreditRepaymentTransactionDetails transaction={transaction} />
      }
    }

    case BackofficeCardTxType2.TOP_UP: {
      return {
        Row: () => <TopupTransactionRow transaction={transaction} />,
        Details: () => <TopupTransactionDetails transaction={transaction} />
      }
    }

    default: {
      return {
        Row: () => <UnknownTransactionRow transaction={transaction} />,
        Details: () => <UnknownTransactionDetails transaction={transaction} />
      }
    }
  }
}

function Row({ transaction, onNotify }: { transaction: BackOfficeAllTransactionDtos; onNotify: (ref: any, id: string) => any }) {
  const { Details, Row } = useMemo(() => getTransactionRowComponents(transaction), [transaction])
  const [open, setOpen] = React.useState(false)
  const ref = useRef(null)

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <Row />
        <Access permissions="customers.cards.transactions.notify">
          <TableCell>
            <StyledActionWrapper ref={ref} onClick={() => onNotify(ref.current, transaction.id)}>
              <NotificationsNoneIcon />
            </StyledActionWrapper>
          </TableCell>
        </Access>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={100}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, backgroundColor: ColorsDeprecated.baseCloud, borderRadius: BorderRadius.soft }} width="100%">
              <Details />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export const CustomerTransactionsTable = ({ customerId }) => {
  const limit = 10
  const [filters, setFilters] = useState<TableFilters>({} as TableFilters)
  const [page, setPage] = useState(0)
  const [data, setData] = useState<BackOfficeAllTransactionDtos[]>([])
  const [isNotifyTransactionOpen, setIsNotifyTransactionOpen] = useState(false)
  const [transactionToNotify, setTransactionToNotify] = useState('')
  const rowRef = useRef()
  const notify = useMaterialNotification()

  const { data: transactionsData = [], isLoading } = useTransactionsV2(customerId, {
    limit,
    offset: page * limit,
    query: filters.vendor,
    cardIds: filters.cards,
    personIds: filters.persons,
    from: filters.from,
    to: filters.to
  })

  useEffect(() => {
    // Only update the data once it has loaded to prevent the table from jumping around
    if (!isLoading) setData(transactionsData)
  }, [transactionsData, isLoading])

  const notifyTransaction = () => {
    transactionTriggerBusinessEventUsingPOST(customerId, transactionToNotify).then(() => notify(`You successfuly notified!`))
    setIsNotifyTransactionOpen(false)
  }

  const onNotify = (ref, transactionId) => {
    rowRef.current = ref
    setTransactionToNotify(transactionId)
    setIsNotifyTransactionOpen(true)
  }

  useEffect(() => {
    // Reset page if any of the filters change.
    // If there is only a few results and the user is on page >2 then the table will be empty
    setPage(0)
  }, [filters])

  return (
    <>
      <MyntPopper
        visible={isNotifyTransactionOpen}
        noPadding
        marginBottom={Spacings.large}
        position="top"
        anchorRef={rowRef.current}
        content={
          <ModalConfirmDialog
            onCancel={() => setIsNotifyTransactionOpen(false)}
            onConfirm={notifyTransaction}
            description={TextKeys.toolTipAreYouSureTextTransactionNotify}
          />
        }
      />
      <FigmaBox bottom={Spacings.tiny} direction="row" fullWidth justify="flex-end">
        <TableFilters onFilterChange={(filters) => setFilters(filters)} customerId={customerId} />
      </FigmaBox>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Vendor</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Variant</TableCell>
                <TableCell>Card holder</TableCell>
                <TableCell>Card</TableCell>
                <TableCell>Amount</TableCell>
                <Access permissions="customers.cards.transactions.notify">
                  <TableCell>Notify</TableCell>
                </Access>
              </TableRow>
              <When is={isLoading}>
                <TableCell padding="none" colSpan={100}>
                  <LinearProgress />
                </TableCell>
              </When>
            </TableHead>
            <TableBody>
              <When is={data.length === 0 && !isLoading}>
                <TableCell colSpan={100}>
                  <FigmaBox alignText="center" fullWidth justify="center" spacing={Spacings.medium} fullPadding>
                    <h2>Customer has no transactions</h2>
                  </FigmaBox>
                </TableCell>
              </When>
              {data.map((transaction) => (
                <Row key={transaction.id} onNotify={onNotify} transaction={transaction} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={-1}
          rowsPerPage={limit}
          page={page}
          onPageChange={async (event, newPage) => {
            setPage(newPage)
          }}
        />
      </Paper>
    </>
  )
}
