/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountDto } from '../models';
// @ts-ignore
import { AccountResultDto } from '../models';
// @ts-ignore
import { AccountV3Response } from '../models';
// @ts-ignore
import { AccountXYear } from '../models';
// @ts-ignore
import { ActivationGuideDto } from '../models';
// @ts-ignore
import { ActivationGuidePatchDto } from '../models';
// @ts-ignore
import { Amount } from '../models';
// @ts-ignore
import { AttachInvoiceRequestDto } from '../models';
// @ts-ignore
import { AttestStatus } from '../models';
// @ts-ignore
import { BackOfficeAccountBasicDto } from '../models';
// @ts-ignore
import { BackOfficeAccountGetDto } from '../models';
// @ts-ignore
import { BackOfficeAccountPatchDto } from '../models';
// @ts-ignore
import { BackOfficeAccountStatusV5 } from '../models';
// @ts-ignore
import { BackOfficeAgreementTypeV6 } from '../models';
// @ts-ignore
import { BackOfficeCardCreditGetV2Dto } from '../models';
// @ts-ignore
import { BackOfficeCardCreditPatchV2Dto } from '../models';
// @ts-ignore
import { BackOfficeCardDeliveryAddressResponseDto } from '../models';
// @ts-ignore
import { BackOfficeCardPatchDto } from '../models';
// @ts-ignore
import { BackOfficeCardsDto } from '../models';
// @ts-ignore
import { BackOfficeCreatePaymentForCustomerRequestDto } from '../models';
// @ts-ignore
import { BackOfficeCustomerCreateDtoV2 } from '../models';
// @ts-ignore
import { BackOfficeCustomerDtoV2 } from '../models';
// @ts-ignore
import { BackOfficeCustomerKycRequiresRenewalDtoV2 } from '../models';
// @ts-ignore
import { BackOfficeCustomerPatchDtoV2 } from '../models';
// @ts-ignore
import { BackOfficeDefaultFeesAndInterestsDto2 } from '../models';
// @ts-ignore
import { BackOfficeDefaultPenaltyFeeDto2 } from '../models';
// @ts-ignore
import { BackOfficeDirectDebitMandateResponseDtoV3 } from '../models';
// @ts-ignore
import { BackOfficeGoogleAuthResponseDto } from '../models';
// @ts-ignore
import { BackOfficeInvoicePatchDto2 } from '../models';
// @ts-ignore
import { BackOfficeInvoicePdfResponseDto2 } from '../models';
// @ts-ignore
import { BackOfficeInvoiceResponseDtoV2 } from '../models';
// @ts-ignore
import { BackOfficeOrderCardRequestDto } from '../models';
// @ts-ignore
import { BackOfficeOrderCardRequestDtoV3 } from '../models';
// @ts-ignore
import { BackOfficePatchPaymentRequestDto } from '../models';
// @ts-ignore
import { BackOfficePaymentResponseDto } from '../models';
// @ts-ignore
import { BackOfficePaymentWhiteListDto } from '../models';
// @ts-ignore
import { BackOfficeReceiverInformationDto } from '../models';
// @ts-ignore
import { BackOfficeSignatoryDto } from '../models';
// @ts-ignore
import { BackOfficeSignatoryInfoResponseDto } from '../models';
// @ts-ignore
import { BackOfficeTransactionConditionDto } from '../models';
// @ts-ignore
import { BackOfficeTx2StatusFilter2 } from '../models';
// @ts-ignore
import { BackofficeCardSyncResponseDto } from '../models';
// @ts-ignore
import { BackofficeInvoicingConfigDto } from '../models';
// @ts-ignore
import { BackofficeInvoicingConfigPatchDto } from '../models';
// @ts-ignore
import { BaseCompanyCreditDto } from '../models';
// @ts-ignore
import { BillingSettings } from '../models';
// @ts-ignore
import { BillingSettingsFeatureName } from '../models';
// @ts-ignore
import { BillingSettingsFeatureOverridePatch } from '../models';
// @ts-ignore
import { BillingSettingsFieldName } from '../models';
// @ts-ignore
import { BillingSettingsOverridePatch } from '../models';
// @ts-ignore
import { BillingSettingsTrialOverridePatch } from '../models';
// @ts-ignore
import { BoCompanyCreditRequestV2 } from '../models';
// @ts-ignore
import { BureauClientsPendingTaskAndAvailableResponse } from '../models';
// @ts-ignore
import { BureauConfigRequest } from '../models';
// @ts-ignore
import { BureauConfigResponse } from '../models';
// @ts-ignore
import { BureauRelationshipRequest } from '../models';
// @ts-ignore
import { BureauRelationshipResponse } from '../models';
// @ts-ignore
import { BureauUnassingmentRequest } from '../models';
// @ts-ignore
import { BureauUnassingmentResponse } from '../models';
// @ts-ignore
import { CardBalanceConfigPatchDto } from '../models';
// @ts-ignore
import { CardBalanceConfigResponseDto } from '../models';
// @ts-ignore
import { CardConfig } from '../models';
// @ts-ignore
import { CompanyCreditDto } from '../models';
// @ts-ignore
import { CorporateCustomerResponse } from '../models';
// @ts-ignore
import { CreateAgreementV6Request } from '../models';
// @ts-ignore
import { CreateDirectDebitMandateV3Request } from '../models';
// @ts-ignore
import { CreateDirectDebitMandateV4Request } from '../models';
// @ts-ignore
import { CreateInvoiceV2Request } from '../models';
// @ts-ignore
import { CreatePaymentRequestDtoV2 } from '../models';
// @ts-ignore
import { CreatePersonDto } from '../models';
// @ts-ignore
import { CreditDto } from '../models';
// @ts-ignore
import { CreditOverrideDto } from '../models';
// @ts-ignore
import { CustomerEventDto } from '../models';
// @ts-ignore
import { CustomerNoteDto } from '../models';
// @ts-ignore
import { EInvoiceAddress } from '../models';
// @ts-ignore
import { ErrorMessage } from '../models';
// @ts-ignore
import { FastSearchResult } from '../models';
// @ts-ignore
import { FileType } from '../models';
// @ts-ignore
import { FilexDto } from '../models';
// @ts-ignore
import { GetAccountsV5200ResponseInner } from '../models';
// @ts-ignore
import { GetAllAgreementsV6200ResponseInner } from '../models';
// @ts-ignore
import { GetBoTransactionsV2DefaultResponseInner } from '../models';
// @ts-ignore
import { GetDirectDebitMandatesV4200ResponseInner } from '../models';
// @ts-ignore
import { InboxItemDto } from '../models';
// @ts-ignore
import { InvoiceState } from '../models';
// @ts-ignore
import { InvoiceType } from '../models';
// @ts-ignore
import { KycPeople } from '../models';
// @ts-ignore
import { KycPerson } from '../models';
// @ts-ignore
import { KycRisk } from '../models';
// @ts-ignore
import { ListPlasticResponse } from '../models';
// @ts-ignore
import { ListPrepaidAccountResponse } from '../models';
// @ts-ignore
import { ListV3AccountResponse } from '../models';
// @ts-ignore
import { MonthlyReportLog } from '../models';
// @ts-ignore
import { MonthlyReportRequest } from '../models';
// @ts-ignore
import { PatchPaymentRequestDtoV2 } from '../models';
// @ts-ignore
import { PatchPrepaidResourceBody } from '../models';
// @ts-ignore
import { PatchStandardResourceBody } from '../models';
// @ts-ignore
import { PatchV3ResourceBody } from '../models';
// @ts-ignore
import { PaymentRequestDtoV2 } from '../models';
// @ts-ignore
import { PaymentRequestPdfDtoV2 } from '../models';
// @ts-ignore
import { PaymentRequestStatusDtoV2 } from '../models';
// @ts-ignore
import { PaymentState } from '../models';
// @ts-ignore
import { PersonDto } from '../models';
// @ts-ignore
import { PersonLoginRequest } from '../models';
// @ts-ignore
import { PersonPatchDto } from '../models';
// @ts-ignore
import { PrepaidAccountResponse } from '../models';
// @ts-ignore
import { PrivateCustomerResponse } from '../models';
// @ts-ignore
import { QuoteUrlDto } from '../models';
// @ts-ignore
import { QuoteUrlRequestDto } from '../models';
// @ts-ignore
import { RepaymentDto } from '../models';
// @ts-ignore
import { RepaymentInvoiceDto } from '../models';
// @ts-ignore
import { RepaymentPlanDto } from '../models';
// @ts-ignore
import { RepaymentUpdateDto } from '../models';
// @ts-ignore
import { ReportDto } from '../models';
// @ts-ignore
import { ResetRequest } from '../models';
// @ts-ignore
import { ResetResponse } from '../models';
// @ts-ignore
import { ResourceResponse } from '../models';
// @ts-ignore
import { SmsRequestDto } from '../models';
// @ts-ignore
import { SmsTemplateDto } from '../models';
// @ts-ignore
import { SnapshotDto } from '../models';
// @ts-ignore
import { SnoozeDto } from '../models';
// @ts-ignore
import { TransactionDto } from '../models';
// @ts-ignore
import { TransactionFilters } from '../models';
// @ts-ignore
import { UploadRequest } from '../models';
// @ts-ignore
import { UploadUrlDto } from '../models';
// @ts-ignore
import { VisaCardListResponse } from '../models';
// @ts-ignore
import { VisaCardResponse } from '../models';
// @ts-ignore
import { VoucherDto } from '../models';
/**
 * BackOfficeAPIApi - axios parameter creator
 * @export
 */
export const BackOfficeAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/bo/sms-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsBasic: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('accountsBasic', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/v2/accounts-basic`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {string} plasticId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePlastic: async (customerId: string, cardId: string, plasticId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('activatePlastic', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('activatePlastic', 'cardId', cardId)
            // verify required parameter 'plasticId' is not null or undefined
            assertParamExists('activatePlastic', 'plasticId', plasticId)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}/plastics/{plastic-id}/activate`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"plastic-id"}}`, encodeURIComponent(String(plasticId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('add', 'body', body)
            const localVarPath = `/bo/sms-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add1: async (customerId: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('add1', 'customerId', customerId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('add1', 'body', body)
            const localVarPath = `/bo/customers/{customerId}/notes`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFile: async (customerId: string, uploadRequest: UploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('addFile', 'customerId', customerId)
            // verify required parameter 'uploadRequest' is not null or undefined
            assertParamExists('addFile', 'uploadRequest', uploadRequest)
            const localVarPath = `/bo/customers/{customer-id}/files`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} itemId 
         * @param {SnoozeDto} snoozeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSnooze: async (itemId: string, snoozeDto: SnoozeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('addSnooze', 'itemId', itemId)
            // verify required parameter 'snoozeDto' is not null or undefined
            assertParamExists('addSnooze', 'snoozeDto', snoozeDto)
            const localVarPath = `/bo/snoozes/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snoozeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCompanyCreditsV2: async (customerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/company-credits/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {string} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCreditsV2: async (customerId?: string, level?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/credits/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRoaringData: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('allRoaringData', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/kyc/roaring/all`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alreadyExistingException: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/test/already-existing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCredit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveCredit', 'id', id)
            const localVarPath = `/bo/credits/{id}/approval`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCustomerCredit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveCustomerCredit', 'id', id)
            const localVarPath = `/bo/company-credits/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauRelationshipRequest} bureauRelationshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assign: async (bureauRelationshipRequest: BureauRelationshipRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bureauRelationshipRequest' is not null or undefined
            assertParamExists('assign', 'bureauRelationshipRequest', bureauRelationshipRequest)
            const localVarPath = `/bo/v1/bureau/assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bureauRelationshipRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {AttachInvoiceRequestDto} attachInvoiceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachInvoiceToRepayment: async (id: string, planId: string, no: number, attachInvoiceRequestDto: AttachInvoiceRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachInvoiceToRepayment', 'id', id)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('attachInvoiceToRepayment', 'planId', planId)
            // verify required parameter 'no' is not null or undefined
            assertParamExists('attachInvoiceToRepayment', 'no', no)
            // verify required parameter 'attachInvoiceRequestDto' is not null or undefined
            assertParamExists('attachInvoiceToRepayment', 'attachInvoiceRequestDto', attachInvoiceRequestDto)
            const localVarPath = `/bo/credits/{id}/repayment-plan/{plan-id}/repayment/{no}/invoice`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"plan-id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"no"}}`, encodeURIComponent(String(no)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachInvoiceRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backOrderCards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/dev/back-order-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeRoleChecker: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/backoffice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSettings: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingSettings', 'customerId', customerId)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PersonLoginRequest} personLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizOpsLogin: async (personLoginRequest: PersonLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personLoginRequest' is not null or undefined
            assertParamExists('bizOpsLogin', 'personLoginRequest', personLoginRequest)
            const localVarPath = `/bo/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookRepaymentInvoice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookRepaymentInvoice', 'id', id)
            const localVarPath = `/bo/credits/invoices/{id}/booked`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bureau: async (bureauId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bureauId' is not null or undefined
            assertParamExists('bureau', 'bureauId', bureauId)
            const localVarPath = `/bo/v1/bureau/{bureau-id}`
                .replace(`{${"bureau-id"}}`, encodeURIComponent(String(bureauId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bureauClients: async (bureauId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bureauId' is not null or undefined
            assertParamExists('bureauClients', 'bureauId', bureauId)
            const localVarPath = `/bo/v1/bureau/{bureau-id}/bureau-clients`
                .replace(`{${"bureau-id"}}`, encodeURIComponent(String(bureauId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bureauPendingTasks: async (bureauId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bureauId' is not null or undefined
            assertParamExists('bureauPendingTasks', 'bureauId', bureauId)
            const localVarPath = `/bo/v1/bureau/{bureau-id}/pending-tasks`
                .replace(`{${"bureau-id"}}`, encodeURIComponent(String(bureauId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDirectDebitMandateV3: async (customerId: string, mandateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('cancelDirectDebitMandateV3', 'customerId', customerId)
            // verify required parameter 'mandateId' is not null or undefined
            assertParamExists('cancelDirectDebitMandateV3', 'mandateId', mandateId)
            const localVarPath = `/bo/v3/customers/{customer-id}/direct-debit-mandates/{mandate-id}/cancel`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"mandate-id"}}`, encodeURIComponent(String(mandateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDirectDebitMandateV4: async (customerId: string, mandateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('cancelDirectDebitMandateV4', 'customerId', customerId)
            // verify required parameter 'mandateId' is not null or undefined
            assertParamExists('cancelDirectDebitMandateV4', 'mandateId', mandateId)
            const localVarPath = `/bo/v4/customers/{customer-id}/direct-debit-mandates/{mandate-id}/cancel`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"mandate-id"}}`, encodeURIComponent(String(mandateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRepaymentInvoice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelRepaymentInvoice', 'id', id)
            const localVarPath = `/bo/credits/invoices/{id}/cancelled`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardDeliveryAddress: async (customerId: string, personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('cardDeliveryAddress', 'customerId', customerId)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('cardDeliveryAddress', 'personId', personId)
            const localVarPath = `/bo/customers/{customer-id}/card/card-address/{person-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"person-id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cards: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('cards', 'customerId', customerId)
            const localVarPath = `/bo/v1/customers/{customer-id}/cards`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        client: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('client', 'clientId', clientId)
            const localVarPath = `/bo/v1/bureau/client/{client-id}`
                .replace(`{${"client-id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeCredit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('closeCredit', 'id', id)
            const localVarPath = `/bo/credits/{id}/close`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauConfigRequest} bureauConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configure: async (bureauConfigRequest: BureauConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bureauConfigRequest' is not null or undefined
            assertParamExists('configure', 'bureauConfigRequest', bureauConfigRequest)
            const localVarPath = `/bo/v1/bureau/configure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bureauConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (accountDto: AccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountDto' is not null or undefined
            assertParamExists('createAccount', 'accountDto', accountDto)
            const localVarPath = `/bo/accountx/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Create a new Agreement with status draft
         * @param {string} customerId 
         * @param {CreateAgreementV6Request} createAgreementV6Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreementV6: async (customerId: string, createAgreementV6Request: CreateAgreementV6Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createAgreementV6', 'customerId', customerId)
            // verify required parameter 'createAgreementV6Request' is not null or undefined
            assertParamExists('createAgreementV6', 'createAgreementV6Request', createAgreementV6Request)
            const localVarPath = `/bo/v6/customers/{customer-id}/agreements`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgreementV6Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary create a new customer entity
         * @param {BackOfficeCustomerCreateDtoV2} backOfficeCustomerCreateDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (backOfficeCustomerCreateDtoV2: BackOfficeCustomerCreateDtoV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'backOfficeCustomerCreateDtoV2' is not null or undefined
            assertParamExists('createCustomer', 'backOfficeCustomerCreateDtoV2', backOfficeCustomerCreateDtoV2)
            const localVarPath = `/bo/v2/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeCustomerCreateDtoV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreateDirectDebitMandateV3Request} createDirectDebitMandateV3Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectDebitMandateV3: async (customerId: string, createDirectDebitMandateV3Request: CreateDirectDebitMandateV3Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createDirectDebitMandateV3', 'customerId', customerId)
            // verify required parameter 'createDirectDebitMandateV3Request' is not null or undefined
            assertParamExists('createDirectDebitMandateV3', 'createDirectDebitMandateV3Request', createDirectDebitMandateV3Request)
            const localVarPath = `/bo/v3/customers/{customer-id}/direct-debit-mandates`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDirectDebitMandateV3Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreateDirectDebitMandateV4Request} createDirectDebitMandateV4Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectDebitMandateV4: async (customerId: string, createDirectDebitMandateV4Request: CreateDirectDebitMandateV4Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createDirectDebitMandateV4', 'customerId', customerId)
            // verify required parameter 'createDirectDebitMandateV4Request' is not null or undefined
            assertParamExists('createDirectDebitMandateV4', 'createDirectDebitMandateV4Request', createDirectDebitMandateV4Request)
            const localVarPath = `/bo/v4/customers/{customer-id}/direct-debit-mandates`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDirectDebitMandateV4Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftRepaymentInvoice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createDraftRepaymentInvoice', 'id', id)
            const localVarPath = `/bo/credits/invoices/{id}/draft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {CreateInvoiceV2Request} createInvoiceV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceV2: async (createInvoiceV2Request: CreateInvoiceV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvoiceV2Request' is not null or undefined
            assertParamExists('createInvoiceV2', 'createInvoiceV2Request', createInvoiceV2Request)
            const localVarPath = `/bo/v2/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BackOfficeCreatePaymentForCustomerRequestDto} backOfficeCreatePaymentForCustomerRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment: async (backOfficeCreatePaymentForCustomerRequestDto: BackOfficeCreatePaymentForCustomerRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'backOfficeCreatePaymentForCustomerRequestDto' is not null or undefined
            assertParamExists('createPayment', 'backOfficeCreatePaymentForCustomerRequestDto', backOfficeCreatePaymentForCustomerRequestDto)
            const localVarPath = `/bo/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeCreatePaymentForCustomerRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {CreatePersonDto} createPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonWithRoles: async (id: string, createPersonDto: CreatePersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createPersonWithRoles', 'id', id)
            // verify required parameter 'createPersonDto' is not null or undefined
            assertParamExists('createPersonWithRoles', 'createPersonDto', createPersonDto)
            const localVarPath = `/bo/customers/{id}/persons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {RepaymentUpdateDto} repaymentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRepayment: async (id: string, planId: string, repaymentUpdateDto: RepaymentUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createRepayment', 'id', id)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('createRepayment', 'planId', planId)
            // verify required parameter 'repaymentUpdateDto' is not null or undefined
            assertParamExists('createRepayment', 'repaymentUpdateDto', repaymentUpdateDto)
            const localVarPath = `/bo/credits/{id}/repayment-plan/{plan-id}/repayment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"plan-id"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repaymentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Create new signatories data
         * @param {string} customerId 
         * @param {Array<BackOfficeSignatoryDto>} backOfficeSignatoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSignatories: async (customerId: string, backOfficeSignatoryDto: Array<BackOfficeSignatoryDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createSignatories', 'customerId', customerId)
            // verify required parameter 'backOfficeSignatoryDto' is not null or undefined
            assertParamExists('createSignatories', 'backOfficeSignatoryDto', backOfficeSignatoryDto)
            const localVarPath = `/bo/v1/customers/{customer-id}/signatories`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeSignatoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {CreatePaymentRequestDtoV2} createPaymentRequestDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV2: async (createPaymentRequestDtoV2: CreatePaymentRequestDtoV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPaymentRequestDtoV2' is not null or undefined
            assertParamExists('createV2', 'createPaymentRequestDtoV2', createPaymentRequestDtoV2)
            const localVarPath = `/bo/v2/payment-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentRequestDtoV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {VoucherDto} voucherDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVoucher: async (voucherDto: VoucherDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherDto' is not null or undefined
            assertParamExists('createVoucher', 'voucherDto', voucherDto)
            const localVarPath = `/bo/accountx/vouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(voucherDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {AccountXYear} accountXYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createYear: async (accountXYear: AccountXYear, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountXYear' is not null or undefined
            assertParamExists('createYear', 'accountXYear', accountXYear)
            const localVarPath = `/bo/accountx/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountXYear, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditRepaymentInvoice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('creditRepaymentInvoice', 'id', id)
            const localVarPath = `/bo/credits/invoices/{id}/credited`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debtCollectRepaymentInvoice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('debtCollectRepaymentInvoice', 'id', id)
            const localVarPath = `/bo/credits/invoices/{id}/debt-collection`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1: async (customerId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('delete1', 'customerId', customerId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete1', 'id', id)
            const localVarPath = `/bo/customers/{customerId}/notes/{id}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAccount', 'id', id)
            const localVarPath = `/bo/accountx/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditOverride: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCreditOverride', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}/override`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCustomer', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureName} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureOverride: async (customerId: string, featureName: BillingSettingsFeatureName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteFeatureOverride', 'customerId', customerId)
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('deleteFeatureOverride', 'featureName', featureName)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides/features/{feature-name}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"feature-name"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (customerId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteFile', 'customerId', customerId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFile', 'id', id)
            const localVarPath = `/bo/customers/{customer-id}/files/{id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFieldName} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOverrides: async (customerId: string, fieldName: BillingSettingsFieldName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteOverrides', 'customerId', customerId)
            // verify required parameter 'fieldName' is not null or undefined
            assertParamExists('deleteOverrides', 'fieldName', fieldName)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides/{field-name}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"field-name"}}`, encodeURIComponent(String(fieldName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} paymentWhiteListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentWhiteListV1: async (customerId: string, paymentWhiteListId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deletePaymentWhiteListV1', 'customerId', customerId)
            // verify required parameter 'paymentWhiteListId' is not null or undefined
            assertParamExists('deletePaymentWhiteListV1', 'paymentWhiteListId', paymentWhiteListId)
            const localVarPath = `/bo/v1/customers/{customer-id}/payment-white-list/{payment-white-list-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"payment-white-list-id"}}`, encodeURIComponent(String(paymentWhiteListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (id: string, personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePerson', 'id', id)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('deletePerson', 'personId', personId)
            const localVarPath = `/bo/customers/{id}/persons/{person-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"person-id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnooze: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteSnooze', 'itemId', itemId)
            const localVarPath = `/bo/snoozes/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteYear: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteYear', 'id', id)
            const localVarPath = `/bo/accountx/years/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directLoginToken: async (id: string, personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('directLoginToken', 'id', id)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('directLoginToken', 'personId', personId)
            const localVarPath = `/bo/customers/{id}/persons/{person-id}/direct-login`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"person-id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropInvoiceFromRepayment: async (id: string, planId: string, no: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dropInvoiceFromRepayment', 'id', id)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('dropInvoiceFromRepayment', 'planId', planId)
            // verify required parameter 'no' is not null or undefined
            assertParamExists('dropInvoiceFromRepayment', 'no', no)
            const localVarPath = `/bo/credits/{id}/repayment-plan/{plan-id}/repayment/{no}/invoice`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"plan-id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"no"}}`, encodeURIComponent(String(no)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRepaymentPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchRepaymentPlan', 'id', id)
            const localVarPath = `/bo/credits/{id}/repayment-plan`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/sms-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBy: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('findBy', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customerId}/notes`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary fetch a customer entity by id
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerById: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('findCustomerById', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary fetch all customers, or search by orgno (should only return one entry)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findCustomers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/v2/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEInvoiceAddresses: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('findEInvoiceAddresses', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/e-invoice-address`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forbiddenException: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/test/access-denied`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalException: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/test/general-exception`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [onDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCsvReport: async (onDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/credits/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onDate !== undefined) {
                localVarQueryParameter['onDate'] = (onDate as any instanceof Date) ?
                    (onDate as any).toISOString().substr(0,10) :
                    onDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateKycReport: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('generateKycReport', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/kyc/reports`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/bo/sms-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1: async (customerId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('get1', 'customerId', customerId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get1', 'id', id)
            const localVarPath = `/bo/customers/{customerId}/notes/{id}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAccount: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccount', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/account`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAccount3: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccount3', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/account3`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get an accounts balance, available, and creditlimit. This requires a downstream call to enfuce.
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountStatusV5: async (customerId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccountStatusV5', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountStatusV5', 'accountId', accountId)
            const localVarPath = `/bo/v5/customers/{customer-id}/accounts/{account-id}/status`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountV2: async (customerId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccountV2', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountV2', 'accountId', accountId)
            const localVarPath = `/bo/customers/{customer-id}/card/v2/accounts/{account-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get an account on customer
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountV5: async (customerId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccountV5', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountV5', 'accountId', accountId)
            const localVarPath = `/bo/v5/customers/{customer-id}/accounts/{account-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: async (year?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/accountx/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsV2: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccountsV2', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/v2/accounts`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of all accounts on customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsV5: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccountsV5', 'customerId', customerId)
            const localVarPath = `/bo/v5/customers/{customer-id}/accounts`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivationGuide: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getActivationGuide', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}/activation-guide`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivationGuideVisibility: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getActivationGuideVisibility', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/activation-guide`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get agreement details for given agreement-id
         * @param {string} customerId 
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementV6: async (customerId: string, agreementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAgreementV6', 'customerId', customerId)
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getAgreementV6', 'agreementId', agreementId)
            const localVarPath = `/bo/v6/customers/{customer-id}/agreements/{agreement-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"agreement-id"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of all agreements on customer
         * @param {string} customerId 
         * @param {Array<BackOfficeAgreementTypeV6>} [agreementType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgreementsV6: async (customerId: string, agreementType?: Array<BackOfficeAgreementTypeV6>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAllAgreementsV6', 'customerId', customerId)
            const localVarPath = `/bo/v6/customers/{customer-id}/agreements`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (agreementType) {
                localVarQueryParameter['agreement-type'] = Array.from(agreementType);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContexts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/customers/files/contexts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMonthlyReports: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAllMonthlyReports', 'customerId', customerId)
            const localVarPath = `/bo/customer/{customer-id}/card/transactions/pe/monthly-reports`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRepaymentInvoices: async (creditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditId' is not null or undefined
            assertParamExists('getAllRepaymentInvoices', 'creditId', creditId)
            const localVarPath = `/bo/credits/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (creditId !== undefined) {
                localVarQueryParameter['credit-id'] = creditId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRepaymentModels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/credits/repayment-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [years] 
         * @param {Array<number>} [accounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVouchers: async (from?: string, to?: string, years?: Array<string>, accounts?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/accountx/vouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (years) {
                localVarQueryParameter['years'] = years;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary Offset, start transaction list at this offset. Limit is how many transactions to return. transactionDate do not have a timezone
         * @param {string} customerId 
         * @param {number} [offset] pagination offset
         * @param {number} [limit] pagination limit
         * @param {string} [from] from date (inclusive) in ISO format, yyyy-MM-dd
         * @param {string} [to] to date (inclusive) in ISO format, yyyy-MM-dd
         * @param {number} [min] min transaction amount (inclusive)
         * @param {number} [max] max transaction amount (inclusive)
         * @param {Array<TransactionFilters>} [onlyinclude] various transaction property filters, some are mutually exclusive
         * @param {Array<TransactionFilters>} [exclude] 
         * @param {Array<AttestStatus>} [attestStatus] 
         * @param {string} [query] Free text query search. At least 3 characters, otherwise ignored
         * @param {Array<string>} [personIds] \&#39;or-ed\&#39; person id\&#39;s to query for, if omitted then transactions for all users will be returned. note: returned set increases with with more personIds and decreases with less personId, except for the empty set which returns all transactions
         * @param {Array<string>} [cardIds] 
         * @param {Array<BackOfficeTx2StatusFilter2>} [status] Filter on transaction status. Leave empty to include all statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoTransactionsV2: async (customerId: string, offset?: number, limit?: number, from?: string, to?: string, min?: number, max?: number, onlyinclude?: Array<TransactionFilters>, exclude?: Array<TransactionFilters>, attestStatus?: Array<AttestStatus>, query?: string, personIds?: Array<string>, cardIds?: Array<string>, status?: Array<BackOfficeTx2StatusFilter2>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getBoTransactionsV2', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}/transactions`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (min !== undefined) {
                localVarQueryParameter['min'] = min;
            }

            if (max !== undefined) {
                localVarQueryParameter['max'] = max;
            }

            if (onlyinclude) {
                localVarQueryParameter['onlyinclude'] = Array.from(onlyinclude);
            }

            if (exclude) {
                localVarQueryParameter['exclude'] = Array.from(exclude);
            }

            if (attestStatus) {
                localVarQueryParameter['attest-status'] = Array.from(attestStatus);
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (personIds) {
                localVarQueryParameter['person-ids'] = Array.from(personIds);
            }

            if (cardIds) {
                localVarQueryParameter['card-ids'] = cardIds;
            }

            if (status) {
                localVarQueryParameter['status'] = Array.from(status);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard: async (customerId: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCard', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('getCard', 'cardId', cardId)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardBalanceConfig: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCardBalanceConfig', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}/card/card-balance-config`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardConfig: async (customerId: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCardConfig', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('getCardConfig', 'cardId', cardId)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}/config`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardCreditV2: async (customerId: string, creditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCardCreditV2', 'customerId', customerId)
            // verify required parameter 'creditId' is not null or undefined
            assertParamExists('getCardCreditV2', 'creditId', creditId)
            const localVarPath = `/bo/customers/{customer-id}/v2/card-credits/{credit-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"credit-id"}}`, encodeURIComponent(String(creditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardCreditsV2: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCardCreditsV2', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/v2/card-credits`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardPlastics: async (customerId: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCardPlastics', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('getCardPlastics', 'cardId', cardId)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}/plastics`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCards', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/cards`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCredit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyCredit', 'id', id)
            const localVarPath = `/bo/company-credits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorporateCustomer: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCorporateCustomer', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/corporate`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCredit', 'id', id)
            const localVarPath = `/bo/credits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary returns 204 if no override is found
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditOverride: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCreditOverride', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}/override`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditReport: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCreditReport', 'id', id)
            const localVarPath = `/bo/credits/{id}/report`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultFeesAndInterestsV2: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDefaultFeesAndInterestsV2', 'customerId', customerId)
            const localVarPath = `/bo/v2/invoices/default-fees-and-interests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customer-id'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultPenaltyFeeV2: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDefaultPenaltyFeeV2', 'customerId', customerId)
            const localVarPath = `/bo/v2/invoices/default-penalty-fee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customer-id'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectDebitMandatesV3: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDirectDebitMandatesV3', 'customerId', customerId)
            const localVarPath = `/bo/v3/customers/{customer-id}/direct-debit-mandates`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectDebitMandatesV4: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDirectDebitMandatesV4', 'customerId', customerId)
            const localVarPath = `/bo/v4/customers/{customer-id}/direct-debit-mandates`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} uuid 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUrl: async (uuid: string, filename?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getDocumentUrl', 'uuid', uuid)
            const localVarPath = `/bo/documents/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDwhTextFile: async (filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getDwhTextFile', 'filename', filename)
            const localVarPath = `/bo/v1/dwh/get-dwh-text-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {Array<string>} [contexts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles: async (customerId: string, contexts?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getFiles', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/files`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contexts) {
                localVarQueryParameter['contexts'] = contexts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [types] Filter for specific types. Accepts a comma separated list. If omitted, all types are returned
         * @param {string} [snoozed] should snoozed items be included or not
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInbox: async (types?: string, snoozed?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/inbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (snoozed !== undefined) {
                localVarQueryParameter['snoozed'] = snoozed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceV2: async (invoiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getInvoiceV2', 'invoiceId', invoiceId)
            const localVarPath = `/bo/v2/invoices/{invoice-id}`
                .replace(`{${"invoice-id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {Array<InvoiceType>} [type] 
         * @param {Array<InvoiceState>} [state] 
         * @param {string} [invoiceDateFrom] invoice date from including
         * @param {string} [invoiceDateTo] invoice date to including
         * @param {string} [settlementDateFrom] settlement date from including
         * @param {string} [settlementDateTo] settlement date to including
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesV2: async (customerId?: string, type?: Array<InvoiceType>, state?: Array<InvoiceState>, invoiceDateFrom?: string, invoiceDateTo?: string, settlementDateFrom?: string, settlementDateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/v2/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customer-id'] = customerId;
            }

            if (type) {
                localVarQueryParameter['type'] = Array.from(type);
            }

            if (state) {
                localVarQueryParameter['state'] = Array.from(state);
            }

            if (invoiceDateFrom !== undefined) {
                localVarQueryParameter['invoice-date-from'] = (invoiceDateFrom as any instanceof Date) ?
                    (invoiceDateFrom as any).toISOString().substr(0,10) :
                    invoiceDateFrom;
            }

            if (invoiceDateTo !== undefined) {
                localVarQueryParameter['invoice-date-to'] = (invoiceDateTo as any instanceof Date) ?
                    (invoiceDateTo as any).toISOString().substr(0,10) :
                    invoiceDateTo;
            }

            if (settlementDateFrom !== undefined) {
                localVarQueryParameter['settlement-date-from'] = (settlementDateFrom as any instanceof Date) ?
                    (settlementDateFrom as any).toISOString().substr(0,10) :
                    settlementDateFrom;
            }

            if (settlementDateTo !== undefined) {
                localVarQueryParameter['settlement-date-to'] = (settlementDateTo as any instanceof Date) ?
                    (settlementDateTo as any).toISOString().substr(0,10) :
                    settlementDateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicingConfigV1: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getInvoicingConfigV1', 'customerId', customerId)
            const localVarPath = `/bo/v1/customers/{customer-id}/invoicing-config`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} ssn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycForPerson: async (customerId: string, ssn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getKycForPerson', 'customerId', customerId)
            // verify required parameter 'ssn' is not null or undefined
            assertParamExists('getKycForPerson', 'ssn', ssn)
            const localVarPath = `/bo/customers/{customer-id}/kyc/people/{ssn}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"ssn"}}`, encodeURIComponent(String(ssn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycList: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKycList', 'id', id)
            const localVarPath = `/bo/customers/{id}/kycs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment: async (paymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('getPayment', 'paymentId', paymentId)
            const localVarPath = `/bo/payments/{payment-id}`
                .replace(`{${"payment-id"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentReceiverV1: async (paymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('getPaymentReceiverV1', 'paymentId', paymentId)
            const localVarPath = `/bo/payments/{payment-id}/receiver`
                .replace(`{${"payment-id"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentRequestV2: async (paymentRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentRequestId' is not null or undefined
            assertParamExists('getPaymentRequestV2', 'paymentRequestId', paymentRequestId)
            const localVarPath = `/bo/v2/payment-request/{payment-request-id}`
                .replace(`{${"payment-request-id"}}`, encodeURIComponent(String(paymentRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {string} [cardCreditId] 
         * @param {Array<string>} [currency] 
         * @param {boolean} [hasPaidEnough] 
         * @param {Array<PaymentRequestStatusDtoV2>} [status] 
         * @param {string} [dueDateFrom] 
         * @param {string} [dueDateTo] 
         * @param {string} [paymentRequestDateFrom] 
         * @param {string} [paymentRequestDateTo] 
         * @param {string} [periodFrom] 
         * @param {string} [periodTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentRequestsV2: async (customerId?: string, cardCreditId?: string, currency?: Array<string>, hasPaidEnough?: boolean, status?: Array<PaymentRequestStatusDtoV2>, dueDateFrom?: string, dueDateTo?: string, paymentRequestDateFrom?: string, paymentRequestDateTo?: string, periodFrom?: string, periodTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/v2/payment-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customer-id'] = customerId;
            }

            if (cardCreditId !== undefined) {
                localVarQueryParameter['card-credit-id'] = cardCreditId;
            }

            if (currency) {
                localVarQueryParameter['currency'] = Array.from(currency);
            }

            if (hasPaidEnough !== undefined) {
                localVarQueryParameter['has-paid-enough'] = hasPaidEnough;
            }

            if (status) {
                localVarQueryParameter['status'] = Array.from(status);
            }

            if (dueDateFrom !== undefined) {
                localVarQueryParameter['due-date-from'] = (dueDateFrom as any instanceof Date) ?
                    (dueDateFrom as any).toISOString().substr(0,10) :
                    dueDateFrom;
            }

            if (dueDateTo !== undefined) {
                localVarQueryParameter['due-date-to'] = (dueDateTo as any instanceof Date) ?
                    (dueDateTo as any).toISOString().substr(0,10) :
                    dueDateTo;
            }

            if (paymentRequestDateFrom !== undefined) {
                localVarQueryParameter['payment-request-date-from'] = (paymentRequestDateFrom as any instanceof Date) ?
                    (paymentRequestDateFrom as any).toISOString().substr(0,10) :
                    paymentRequestDateFrom;
            }

            if (paymentRequestDateTo !== undefined) {
                localVarQueryParameter['payment-request-date-to'] = (paymentRequestDateTo as any instanceof Date) ?
                    (paymentRequestDateTo as any).toISOString().substr(0,10) :
                    paymentRequestDateTo;
            }

            if (periodFrom !== undefined) {
                localVarQueryParameter['period-from'] = (periodFrom as any instanceof Date) ?
                    (periodFrom as any).toISOString().substr(0,10) :
                    periodFrom;
            }

            if (periodTo !== undefined) {
                localVarQueryParameter['period-to'] = (periodTo as any instanceof Date) ?
                    (periodTo as any).toISOString().substr(0,10) :
                    periodTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentWhiteListV1: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getPaymentWhiteListV1', 'customerId', customerId)
            const localVarPath = `/bo/v1/customers/{customer-id}/payment-white-list`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {Array<PaymentState>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments: async (state?: Array<PaymentState>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfV2: async (paymentRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentRequestId' is not null or undefined
            assertParamExists('getPdfV2', 'paymentRequestId', paymentRequestId)
            const localVarPath = `/bo/v2/payment-request/{payment-request-id}/pdf`
                .replace(`{${"payment-request-id"}}`, encodeURIComponent(String(paymentRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfV21: async (invoiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getPdfV21', 'invoiceId', invoiceId)
            const localVarPath = `/bo/v2/invoices/{invoice-id}/pdf`
                .replace(`{${"invoice-id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeople: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getPeople', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/kyc/people`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonsWithRoles: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonsWithRoles', 'id', id)
            const localVarPath = `/bo/customers/{id}/persons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreSignedUrl: async (customerId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getPreSignedUrl', 'customerId', customerId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPreSignedUrl', 'id', id)
            const localVarPath = `/bo/customers/{customer-id}/files/{id}/url`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferredDirectDebitMandateV3: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getPreferredDirectDebitMandateV3', 'customerId', customerId)
            const localVarPath = `/bo/v3/customers/{customer-id}/direct-debit-mandates/preferred`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferredDirectDebitMandateV4: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getPreferredDirectDebitMandateV4', 'customerId', customerId)
            const localVarPath = `/bo/v4/customers/{customer-id}/direct-debit-mandates/preferred`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivateCustomers: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getPrivateCustomers', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/private`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {QuoteUrlRequestDto} quoteUrlRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteUrl: async (id: string, quoteUrlRequestDto: QuoteUrlRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuoteUrl', 'id', id)
            // verify required parameter 'quoteUrlRequestDto' is not null or undefined
            assertParamExists('getQuoteUrl', 'quoteUrlRequestDto', quoteUrlRequestDto)
            const localVarPath = `/bo/company-credits/{id}/quote-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quoteUrlRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawAccountV3: async (customerId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getRawAccountV3', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getRawAccountV3', 'accountId', accountId)
            const localVarPath = `/bo/customers/{customer-id}/card/raw/accounts/v3/{account-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawAccountV4: async (customerId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getRawAccountV4', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getRawAccountV4', 'accountId', accountId)
            const localVarPath = `/bo/customers/{customer-id}/card/raw/accounts/v4/{account-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawAccountsV3: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getRawAccountsV3', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/raw/accounts/v3`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawAccountsV4: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getRawAccountsV4', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/raw/accounts/v4`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminderPdfV2: async (invoiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getReminderPdfV2', 'invoiceId', invoiceId)
            const localVarPath = `/bo/v2/invoices/{invoice-id}/reminder-pdf`
                .replace(`{${"invoice-id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepaymentInvoice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRepaymentInvoice', 'id', id)
            const localVarPath = `/bo/credits/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepaymentInvoiceSummary: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRepaymentInvoiceSummary', 'id', id)
            const localVarPath = `/bo/credits/invoices/{id}/summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepaymentInvoicesForCompanyCredit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRepaymentInvoicesForCompanyCredit', 'id', id)
            const localVarPath = `/bo/company-credits/{id}/invoices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTextFile: async (filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getReportTextFile', 'filename', filename)
            const localVarPath = `/bo/v1/dwh/get-report-text-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRisk: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getRisk', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/kyc/risk`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get signatories data
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignatories: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getSignatories', 'customerId', customerId)
            const localVarPath = `/bo/v1/customers/{customer-id}/signatories`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {Array<string>} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshots: async (dates?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/credits/snapshots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dates) {
                localVarQueryParameter['dates'] = dates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (customerId: string, txId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getTransaction', 'customerId', customerId)
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('getTransaction', 'txId', txId)
            const localVarPath = `/bo/customers/{customer-id}/card/transactions/{tx-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"tx-id"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary offset takes precedence over page if provided. Page is still available for backward compatibility. limit defaults to 20
         * @param {string} customerId 
         * @param {number} [page] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsCombined: async (customerId: string, page?: number, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getTransactionsCombined', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/card/transactions`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [years] 
         * @param {Array<number>} [accounts] 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVouchersResultReport: async (from?: string, to?: string, years?: Array<string>, accounts?: Array<number>, currency?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/accountx/vouchers/result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (years) {
                localVarQueryParameter['years'] = years;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYears: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/accountx/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} callback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleOAuthRedir: async (callback: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callback' is not null or undefined
            assertParamExists('googleOAuthRedir', 'callback', callback)
            const localVarPath = `/bo/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (callback !== undefined) {
                localVarQueryParameter['callback'] = callback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        illegalArgumentException: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/test/illegal-argument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        illegalStateException: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/test/illegal-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isLoggedIn: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/is-logged-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApproved: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('kycApproved', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}/notify-kyc-approved`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeCustomerKycRequiresRenewalDtoV2} backOfficeCustomerKycRequiresRenewalDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycRequiresRenewal: async (customerId: string, backOfficeCustomerKycRequiresRenewalDtoV2: BackOfficeCustomerKycRequiresRenewalDtoV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('kycRequiresRenewal', 'customerId', customerId)
            // verify required parameter 'backOfficeCustomerKycRequiresRenewalDtoV2' is not null or undefined
            assertParamExists('kycRequiresRenewal', 'backOfficeCustomerKycRequiresRenewalDtoV2', backOfficeCustomerKycRequiresRenewalDtoV2)
            const localVarPath = `/bo/v2/customers/{customer-id}/notify-kyc-requires-renewal`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeCustomerKycRequiresRenewalDtoV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/v1/bureau`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('log', 'id', id)
            const localVarPath = `/bo/customers/{id}/log`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ls: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/v1/dwh/ls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCreditAsDefault: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markCreditAsDefault', 'id', id)
            const localVarPath = `/bo/credits/{id}/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCreditAsNetted: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markCreditAsNetted', 'id', id)
            const localVarPath = `/bo/credits/{id}/netted`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCreditAsRepaid: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markCreditAsRepaid', 'id', id)
            const localVarPath = `/bo/credits/{id}/repaid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notFoundException: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/test/not-found`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyCardCreditDenied: async (customerId: string, creditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('notifyCardCreditDenied', 'customerId', customerId)
            // verify required parameter 'creditId' is not null or undefined
            assertParamExists('notifyCardCreditDenied', 'creditId', creditId)
            const localVarPath = `/bo/customers/{customer-id}/v2/card-credits/{credit-id}/notify-denied`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"credit-id"}}`, encodeURIComponent(String(creditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyCustomerV2: async (paymentRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentRequestId' is not null or undefined
            assertParamExists('notifyCustomerV2', 'paymentRequestId', paymentRequestId)
            const localVarPath = `/bo/v2/payment-request/{payment-request-id}/notify-customer`
                .replace(`{${"payment-request-id"}}`, encodeURIComponent(String(paymentRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeOrderCardRequestDto} backOfficeOrderCardRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCard: async (customerId: string, backOfficeOrderCardRequestDto: BackOfficeOrderCardRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('orderCard', 'customerId', customerId)
            // verify required parameter 'backOfficeOrderCardRequestDto' is not null or undefined
            assertParamExists('orderCard', 'backOfficeOrderCardRequestDto', backOfficeOrderCardRequestDto)
            const localVarPath = `/bo/customers/{customer-id}/card/cards`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeOrderCardRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeOrderCardRequestDtoV3} backOfficeOrderCardRequestDtoV3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCardV3: async (customerId: string, backOfficeOrderCardRequestDtoV3: BackOfficeOrderCardRequestDtoV3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('orderCardV3', 'customerId', customerId)
            // verify required parameter 'backOfficeOrderCardRequestDtoV3' is not null or undefined
            assertParamExists('orderCardV3', 'backOfficeOrderCardRequestDtoV3', backOfficeOrderCardRequestDtoV3)
            const localVarPath = `/bo/customers/{customer-id}/v3/card/order`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeOrderCardRequestDtoV3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {FileType} type 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseDwhFull: async (type: FileType, filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('parseDwhFull', 'type', type)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('parseDwhFull', 'filename', filename)
            const localVarPath = `/bo/v1/dwh/parse-dwh-full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {PatchPrepaidResourceBody} patchPrepaidResourceBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchAccount: async (customerId: string, patchPrepaidResourceBody: PatchPrepaidResourceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchAccount', 'customerId', customerId)
            // verify required parameter 'patchPrepaidResourceBody' is not null or undefined
            assertParamExists('patchAccount', 'patchPrepaidResourceBody', patchPrepaidResourceBody)
            const localVarPath = `/bo/customers/{customer-id}/card/account`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPrepaidResourceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {BackOfficeAccountPatchDto} backOfficeAccountPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAccountV2: async (customerId: string, accountId: string, backOfficeAccountPatchDto: BackOfficeAccountPatchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchAccountV2', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('patchAccountV2', 'accountId', accountId)
            // verify required parameter 'backOfficeAccountPatchDto' is not null or undefined
            assertParamExists('patchAccountV2', 'backOfficeAccountPatchDto', backOfficeAccountPatchDto)
            const localVarPath = `/bo/customers/{customer-id}/card/v2/accounts/{account-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeAccountPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {ActivationGuidePatchDto} activationGuidePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchActivationGuideVisibility: async (customerId: string, activationGuidePatchDto: ActivationGuidePatchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchActivationGuideVisibility', 'customerId', customerId)
            // verify required parameter 'activationGuidePatchDto' is not null or undefined
            assertParamExists('patchActivationGuideVisibility', 'activationGuidePatchDto', activationGuidePatchDto)
            const localVarPath = `/bo/v2/customers/{customer-id}/activation-guide`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activationGuidePatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {ActivationGuidePatchDto} activationGuidePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchActivationGuideVisibility1: async (customerId: string, activationGuidePatchDto: ActivationGuidePatchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchActivationGuideVisibility1', 'customerId', customerId)
            // verify required parameter 'activationGuidePatchDto' is not null or undefined
            assertParamExists('patchActivationGuideVisibility1', 'activationGuidePatchDto', activationGuidePatchDto)
            const localVarPath = `/bo/customers/{customer-id}/activation-guide`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activationGuidePatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {PatchStandardResourceBody} patchStandardResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCard: async (customerId: string, cardId: string, patchStandardResourceBody: PatchStandardResourceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchCard', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('patchCard', 'cardId', cardId)
            // verify required parameter 'patchStandardResourceBody' is not null or undefined
            assertParamExists('patchCard', 'patchStandardResourceBody', patchStandardResourceBody)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchStandardResourceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CardBalanceConfigPatchDto} cardBalanceConfigPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCardBalanceConfig: async (customerId: string, cardBalanceConfigPatchDto: CardBalanceConfigPatchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchCardBalanceConfig', 'customerId', customerId)
            // verify required parameter 'cardBalanceConfigPatchDto' is not null or undefined
            assertParamExists('patchCardBalanceConfig', 'cardBalanceConfigPatchDto', cardBalanceConfigPatchDto)
            const localVarPath = `/bo/v2/customers/{customer-id}/card/card-balance-config`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardBalanceConfigPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCardConfig: async (customerId: string, cardId: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchCardConfig', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('patchCardConfig', 'cardId', cardId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('patchCardConfig', 'body', body)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}/config`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {BackOfficeCardCreditPatchV2Dto} backOfficeCardCreditPatchV2Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCardCreditV2: async (customerId: string, creditId: string, backOfficeCardCreditPatchV2Dto: BackOfficeCardCreditPatchV2Dto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchCardCreditV2', 'customerId', customerId)
            // verify required parameter 'creditId' is not null or undefined
            assertParamExists('patchCardCreditV2', 'creditId', creditId)
            // verify required parameter 'backOfficeCardCreditPatchV2Dto' is not null or undefined
            assertParamExists('patchCardCreditV2', 'backOfficeCardCreditPatchV2Dto', backOfficeCardCreditPatchV2Dto)
            const localVarPath = `/bo/customers/{customer-id}/v2/card-credits/{credit-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"credit-id"}}`, encodeURIComponent(String(creditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeCardCreditPatchV2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {BackOfficeCardPatchDto} backOfficeCardPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCardV1: async (customerId: string, cardId: string, backOfficeCardPatchDto: BackOfficeCardPatchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchCardV1', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('patchCardV1', 'cardId', cardId)
            // verify required parameter 'backOfficeCardPatchDto' is not null or undefined
            assertParamExists('patchCardV1', 'backOfficeCardPatchDto', backOfficeCardPatchDto)
            const localVarPath = `/bo/v1/customers/{customer-id}/cards/{card-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeCardPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRoleOrSalesRoleAndAllowed(#patch.changesOnlyProducts())
         * @param {string} customerId 
         * @param {BackOfficeCustomerPatchDtoV2} backOfficeCustomerPatchDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCustomer: async (customerId: string, backOfficeCustomerPatchDtoV2: BackOfficeCustomerPatchDtoV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchCustomer', 'customerId', customerId)
            // verify required parameter 'backOfficeCustomerPatchDtoV2' is not null or undefined
            assertParamExists('patchCustomer', 'backOfficeCustomerPatchDtoV2', backOfficeCustomerPatchDtoV2)
            const localVarPath = `/bo/v2/customers/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeCustomerPatchDtoV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureOverridePatch} billingSettingsFeatureOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureOverrides: async (customerId: string, billingSettingsFeatureOverridePatch: BillingSettingsFeatureOverridePatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchFeatureOverrides', 'customerId', customerId)
            // verify required parameter 'billingSettingsFeatureOverridePatch' is not null or undefined
            assertParamExists('patchFeatureOverrides', 'billingSettingsFeatureOverridePatch', billingSettingsFeatureOverridePatch)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides/features`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingSettingsFeatureOverridePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackofficeInvoicingConfigPatchDto} backofficeInvoicingConfigPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInvoicingConfigV1: async (customerId: string, backofficeInvoicingConfigPatchDto: BackofficeInvoicingConfigPatchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchInvoicingConfigV1', 'customerId', customerId)
            // verify required parameter 'backofficeInvoicingConfigPatchDto' is not null or undefined
            assertParamExists('patchInvoicingConfigV1', 'backofficeInvoicingConfigPatchDto', backofficeInvoicingConfigPatchDto)
            const localVarPath = `/bo/v1/customers/{customer-id}/invoicing-config`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backofficeInvoicingConfigPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsOverridePatch} billingSettingsOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOverrides: async (customerId: string, billingSettingsOverridePatch: BillingSettingsOverridePatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchOverrides', 'customerId', customerId)
            // verify required parameter 'billingSettingsOverridePatch' is not null or undefined
            assertParamExists('patchOverrides', 'billingSettingsOverridePatch', billingSettingsOverridePatch)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingSettingsOverridePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {BackOfficePatchPaymentRequestDto} backOfficePatchPaymentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPayment: async (paymentId: string, backOfficePatchPaymentRequestDto: BackOfficePatchPaymentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('patchPayment', 'paymentId', paymentId)
            // verify required parameter 'backOfficePatchPaymentRequestDto' is not null or undefined
            assertParamExists('patchPayment', 'backOfficePatchPaymentRequestDto', backOfficePatchPaymentRequestDto)
            const localVarPath = `/bo/payments/{payment-id}`
                .replace(`{${"payment-id"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficePatchPaymentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {PatchPaymentRequestDtoV2} patchPaymentRequestDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPaymentRequestV2: async (paymentRequestId: string, patchPaymentRequestDtoV2: PatchPaymentRequestDtoV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentRequestId' is not null or undefined
            assertParamExists('patchPaymentRequestV2', 'paymentRequestId', paymentRequestId)
            // verify required parameter 'patchPaymentRequestDtoV2' is not null or undefined
            assertParamExists('patchPaymentRequestV2', 'patchPaymentRequestDtoV2', patchPaymentRequestDtoV2)
            const localVarPath = `/bo/v2/payment-request/{payment-request-id}`
                .replace(`{${"payment-request-id"}}`, encodeURIComponent(String(paymentRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPaymentRequestDtoV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {PatchV3ResourceBody} patchV3ResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRawAccountV3: async (customerId: string, accountId: string, patchV3ResourceBody: PatchV3ResourceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchRawAccountV3', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('patchRawAccountV3', 'accountId', accountId)
            // verify required parameter 'patchV3ResourceBody' is not null or undefined
            assertParamExists('patchRawAccountV3', 'patchV3ResourceBody', patchV3ResourceBody)
            const localVarPath = `/bo/customers/{customer-id}/card/raw/accounts/v3/{account-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchV3ResourceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {PatchPrepaidResourceBody} patchPrepaidResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRawAccountV4: async (customerId: string, accountId: string, patchPrepaidResourceBody: PatchPrepaidResourceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchRawAccountV4', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('patchRawAccountV4', 'accountId', accountId)
            // verify required parameter 'patchPrepaidResourceBody' is not null or undefined
            assertParamExists('patchRawAccountV4', 'patchPrepaidResourceBody', patchPrepaidResourceBody)
            const localVarPath = `/bo/customers/{customer-id}/card/raw/accounts/v4/{account-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPrepaidResourceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsTrialOverridePatch} billingSettingsTrialOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTrialOverrides: async (customerId: string, billingSettingsTrialOverridePatch: BillingSettingsTrialOverridePatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchTrialOverrides', 'customerId', customerId)
            // verify required parameter 'billingSettingsTrialOverridePatch' is not null or undefined
            assertParamExists('patchTrialOverrides', 'billingSettingsTrialOverridePatch', billingSettingsTrialOverridePatch)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides/trials`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingSettingsTrialOverridePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {Amount} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutCompanyCredit: async (id: string, amount: Amount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('payoutCompanyCredit', 'id', id)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('payoutCompanyCredit', 'amount', amount)
            const localVarPath = `/bo/company-credits/{id}/payout`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(amount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        person: async (id: string, personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('person', 'id', id)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('person', 'personId', personId)
            const localVarPath = `/bo/customers/{id}/persons/{person-id}/kyc`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"person-id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        person1: async (id: string, personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('person1', 'id', id)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('person1', 'personId', personId)
            const localVarPath = `/bo/customers/{id}/persons/{person-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"person-id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BoCompanyCreditRequestV2} boCompanyCreditRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNewCompanyCreditRequestV2: async (boCompanyCreditRequestV2: BoCompanyCreditRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boCompanyCreditRequestV2' is not null or undefined
            assertParamExists('postNewCompanyCreditRequestV2', 'boCompanyCreditRequestV2', boCompanyCreditRequestV2)
            const localVarPath = `/bo/company-credits/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boCompanyCreditRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRecalculateV2: async (paymentRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentRequestId' is not null or undefined
            assertParamExists('postRecalculateV2', 'paymentRequestId', paymentRequestId)
            const localVarPath = `/bo/v2/payment-request/{payment-request-id}/recalculate`
                .replace(`{${"payment-request-id"}}`, encodeURIComponent(String(paymentRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('put', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('put', 'body', body)
            const localVarPath = `/bo/sms-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put1: async (customerId: string, id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('put1', 'customerId', customerId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('put1', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('put1', 'body', body)
            const localVarPath = `/bo/customers/{customerId}/notes/{id}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreditOverrideDto} creditOverrideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCreditOverride: async (customerId: string, creditOverrideDto: CreditOverrideDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putCreditOverride', 'customerId', customerId)
            // verify required parameter 'creditOverrideDto' is not null or undefined
            assertParamExists('putCreditOverride', 'creditOverrideDto', creditOverrideDto)
            const localVarPath = `/bo/v2/customers/{customer-id}/override`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creditOverrideDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {KycRisk} kycRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRisk: async (customerId: string, kycRisk: KycRisk, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putRisk', 'customerId', customerId)
            // verify required parameter 'kycRisk' is not null or undefined
            assertParamExists('putRisk', 'kycRisk', kycRisk)
            const localVarPath = `/bo/customers/{customer-id}/kyc/risk`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kycRisk, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reCreateRepaymentInvoice: async (id: string, planId: string, no: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reCreateRepaymentInvoice', 'id', id)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('reCreateRepaymentInvoice', 'planId', planId)
            // verify required parameter 'no' is not null or undefined
            assertParamExists('reCreateRepaymentInvoice', 'no', no)
            const localVarPath = `/bo/credits/{id}/repayment-plan/{plan-id}/repayment/{no}/invoice`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"plan-id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"no"}}`, encodeURIComponent(String(no)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rebuildInvoicesAndRepaymentPlanFromFortnox: async (id: string, planId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rebuildInvoicesAndRepaymentPlanFromFortnox', 'id', id)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('rebuildInvoicesAndRepaymentPlanFromFortnox', 'planId', planId)
            const localVarPath = `/bo/credits/{id}/repayment-plan/{plan-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"plan-id"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAddress: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('refreshAddress', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}/refresh-address`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateAgreementDocument: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('regenerateAgreementDocument', 'id', id)
            const localVarPath = `/bo/company-credits/{id}/agreement-document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateRepaymentPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('regenerateRepaymentPlan', 'id', id)
            const localVarPath = `/bo/company-credits/{id}/repayment-plan`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reissueCard: async (customerId: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('reissueCard', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('reissueCard', 'cardId', cardId)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}/update/REISSUE_CARD`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {Amount} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remindRepaymentInvoice: async (id: string, amount: Amount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remindRepaymentInvoice', 'id', id)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('remindRepaymentInvoice', 'amount', amount)
            const localVarPath = `/bo/credits/invoices/{id}/reminder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(amount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendDirectDebitMandateV3: async (customerId: string, mandateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('resendDirectDebitMandateV3', 'customerId', customerId)
            // verify required parameter 'mandateId' is not null or undefined
            assertParamExists('resendDirectDebitMandateV3', 'mandateId', mandateId)
            const localVarPath = `/bo/v3/customers/{customer-id}/direct-debit-mandates/{mandate-id}/resend`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"mandate-id"}}`, encodeURIComponent(String(mandateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendDirectDebitMandateV4: async (customerId: string, mandateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('resendDirectDebitMandateV4', 'customerId', customerId)
            // verify required parameter 'mandateId' is not null or undefined
            assertParamExists('resendDirectDebitMandateV4', 'mandateId', mandateId)
            const localVarPath = `/bo/v4/customers/{customer-id}/direct-debit-mandates/{mandate-id}/resend`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"mandate-id"}}`, encodeURIComponent(String(mandateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {ResetRequest} resetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetBureau: async (resetRequest: ResetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetRequest' is not null or undefined
            assertParamExists('resetBureau', 'resetRequest', resetRequest)
            const localVarPath = `/bo/v1/bureau/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPinAttempt: async (customerId: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('resetPinAttempt', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('resetPinAttempt', 'cardId', cardId)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}/update/RESET_PIN_ATTEMPT`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasRole(\'BACKOFFICE_SALES\')
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesRoleChecker: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} q 
         * @param {number} [n] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (q: string, n?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('search', 'q', q)
            const localVarPath = `/bo/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (n !== undefined) {
                localVarQueryParameter['n'] = n;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/search/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {MonthlyReportRequest} monthlyReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMonthlyReport: async (customerId: string, monthlyReportRequest: MonthlyReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('sendMonthlyReport', 'customerId', customerId)
            // verify required parameter 'monthlyReportRequest' is not null or undefined
            assertParamExists('sendMonthlyReport', 'monthlyReportRequest', monthlyReportRequest)
            const localVarPath = `/bo/customer/{customer-id}/card/transactions/pe/monthly-reports`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthlyReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRenewalEmail: async (customerId: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('sendRenewalEmail', 'customerId', customerId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendRenewalEmail', 'body', body)
            const localVarPath = `/bo/customers/{customer-id}/kyc/send-renewal-email`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} cid 
         * @param {string} pid 
         * @param {SmsRequestDto} smsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSms: async (cid: string, pid: string, smsRequestDto: SmsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cid' is not null or undefined
            assertParamExists('sendSms', 'cid', cid)
            // verify required parameter 'pid' is not null or undefined
            assertParamExists('sendSms', 'pid', pid)
            // verify required parameter 'smsRequestDto' is not null or undefined
            assertParamExists('sendSms', 'smsRequestDto', smsRequestDto)
            const localVarPath = `/bo/customers/{cid}/persons/{pid}/sms`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)))
                .replace(`{${"pid"}}`, encodeURIComponent(String(pid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWelcomeEmail: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('sendWelcomeEmail', 'customerId', customerId)
            const localVarPath = `/bo/v2/customers/{customer-id}/send-welcome-email`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStandardTermsCustomerCredit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setStandardTermsCustomerCredit', 'id', id)
            const localVarPath = `/bo/company-credits/{id}/standard-terms`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCard: async (customerId: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('syncCard', 'customerId', customerId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('syncCard', 'cardId', cardId)
            const localVarPath = `/bo/customers/{customer-id}/card/cards/{card-id}/sync`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"card-id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCompanyCreditWithFortnox: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncCompanyCreditWithFortnox', 'id', id)
            const localVarPath = `/bo/company-credits/{id}/sync-fortnox`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDirectDebitMandateV3: async (customerId: string, mandateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('syncDirectDebitMandateV3', 'customerId', customerId)
            // verify required parameter 'mandateId' is not null or undefined
            assertParamExists('syncDirectDebitMandateV3', 'mandateId', mandateId)
            const localVarPath = `/bo/v3/customers/{customer-id}/direct-debit-mandates/{mandate-id}/sync`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"mandate-id"}}`, encodeURIComponent(String(mandateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDirectDebitMandateV4: async (customerId: string, mandateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('syncDirectDebitMandateV4', 'customerId', customerId)
            // verify required parameter 'mandateId' is not null or undefined
            assertParamExists('syncDirectDebitMandateV4', 'mandateId', mandateId)
            const localVarPath = `/bo/v4/customers/{customer-id}/direct-debit-mandates/{mandate-id}/sync`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"mandate-id"}}`, encodeURIComponent(String(mandateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncFile: async (customerId: string, id: string, uploadRequest: UploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('syncFile', 'customerId', customerId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncFile', 'id', id)
            // verify required parameter 'uploadRequest' is not null or undefined
            assertParamExists('syncFile', 'uploadRequest', uploadRequest)
            const localVarPath = `/bo/customers/{customer-id}/files/{id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithFortnox: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncWithFortnox', 'id', id)
            const localVarPath = `/bo/credits/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of transaction conditions from Enfuce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionConditions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/v1/transaction-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerBusinessEvent: async (customerId: string, txId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('triggerBusinessEvent', 'customerId', customerId)
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('triggerBusinessEvent', 'txId', txId)
            const localVarPath = `/bo/customers/{customer-id}/card/transactions/{tx-id}/trigger-business-event`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"tx-id"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerBusinessEventV2: async (customerId: string, txId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('triggerBusinessEventV2', 'customerId', customerId)
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('triggerBusinessEventV2', 'txId', txId)
            const localVarPath = `/bo/v2/customers/{customer-id}/transactions/{tx-id}/trigger-business-event`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"tx-id"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerDwhJob: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/v1/dwh/trigger-dwh-job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauUnassingmentRequest} bureauUnassingmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassign: async (bureauUnassingmentRequest: BureauUnassingmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bureauUnassingmentRequest' is not null or undefined
            assertParamExists('unassign', 'bureauUnassingmentRequest', bureauUnassingmentRequest)
            const localVarPath = `/bo/v1/bureau/unassign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bureauUnassingmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unauthorizedException: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/test/unauthorized`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (id: string, accountDto: AccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccount', 'id', id)
            // verify required parameter 'accountDto' is not null or undefined
            assertParamExists('updateAccount', 'accountDto', accountDto)
            const localVarPath = `/bo/accountx/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardGroupsForAllCards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/dev/update-card-groups-for-all-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {CompanyCreditDto} companyCreditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCredit: async (id: string, companyCreditDto: CompanyCreditDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyCredit', 'id', id)
            // verify required parameter 'companyCreditDto' is not null or undefined
            assertParamExists('updateCompanyCredit', 'companyCreditDto', companyCreditDto)
            const localVarPath = `/bo/company-credits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyCreditDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {BackOfficeInvoicePatchDto2} backOfficeInvoicePatchDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceV2: async (invoiceId: string, backOfficeInvoicePatchDto2: BackOfficeInvoicePatchDto2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('updateInvoiceV2', 'invoiceId', invoiceId)
            // verify required parameter 'backOfficeInvoicePatchDto2' is not null or undefined
            assertParamExists('updateInvoiceV2', 'backOfficeInvoicePatchDto2', backOfficeInvoicePatchDto2)
            const localVarPath = `/bo/v2/invoices/{invoice-id}`
                .replace(`{${"invoice-id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficeInvoicePatchDto2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePeople: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updatePeople', 'customerId', customerId)
            const localVarPath = `/bo/customers/{customer-id}/kyc/people`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRoleOrSalesRoleAndAllowed(#patch.onlyInvite())
         * @param {string} id 
         * @param {string} personId 
         * @param {PersonPatchDto} personPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (id: string, personId: string, personPatchDto: PersonPatchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePerson', 'id', id)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('updatePerson', 'personId', personId)
            // verify required parameter 'personPatchDto' is not null or undefined
            assertParamExists('updatePerson', 'personPatchDto', personPatchDto)
            const localVarPath = `/bo/customers/{id}/persons/{person-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"person-id"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {RepaymentUpdateDto} repaymentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRepayment: async (id: string, planId: string, no: number, repaymentUpdateDto: RepaymentUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRepayment', 'id', id)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('updateRepayment', 'planId', planId)
            // verify required parameter 'no' is not null or undefined
            assertParamExists('updateRepayment', 'no', no)
            // verify required parameter 'repaymentUpdateDto' is not null or undefined
            assertParamExists('updateRepayment', 'repaymentUpdateDto', repaymentUpdateDto)
            const localVarPath = `/bo/credits/{id}/repayment-plan/{plan-id}/repayment/{no}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"plan-id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"no"}}`, encodeURIComponent(String(no)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repaymentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {AccountXYear} accountXYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYear: async (id: string, accountXYear: AccountXYear, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateYear', 'id', id)
            // verify required parameter 'accountXYear' is not null or undefined
            assertParamExists('updateYear', 'accountXYear', accountXYear)
            const localVarPath = `/bo/accountx/years/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountXYear, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whiteListPaymentV1: async (paymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('whiteListPaymentV1', 'paymentId', paymentId)
            const localVarPath = `/bo/payments/{payment-id}/whitelist`
                .replace(`{${"payment-id"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackOfficeAPIApi - functional programming interface
 * @export
 */
export const BackOfficeAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackOfficeAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsBasic(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficeAccountBasicDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsBasic(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {string} plasticId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activatePlastic(customerId: string, cardId: string, plasticId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activatePlastic(customerId, cardId, plasticId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add1(customerId: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add1(customerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFile(customerId: string, uploadRequest: UploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFile(customerId, uploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} itemId 
         * @param {SnoozeDto} snoozeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSnooze(itemId: string, snoozeDto: SnoozeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnoozeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSnooze(itemId, snoozeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allCompanyCreditsV2(customerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCreditDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allCompanyCreditsV2(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {string} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allCreditsV2(customerId?: string, level?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreditDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allCreditsV2(customerId, level, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allRoaringData(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allRoaringData(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alreadyExistingException(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alreadyExistingException(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveCredit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveCredit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveCustomerCredit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveCustomerCredit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauRelationshipRequest} bureauRelationshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assign(bureauRelationshipRequest: BureauRelationshipRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BureauRelationshipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assign(bureauRelationshipRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {AttachInvoiceRequestDto} attachInvoiceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachInvoiceToRepayment(id: string, planId: string, no: number, attachInvoiceRequestDto: AttachInvoiceRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachInvoiceToRepayment(id, planId, no, attachInvoiceRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backOrderCards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backOrderCards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeRoleChecker(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeRoleChecker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSettings(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSettings(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PersonLoginRequest} personLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bizOpsLogin(personLoginRequest: PersonLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bizOpsLogin(personLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookRepaymentInvoice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookRepaymentInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bureau(bureauId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BureauConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bureau(bureauId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bureauClients(bureauId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BureauRelationshipResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bureauClients(bureauId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bureauPendingTasks(bureauId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BureauClientsPendingTaskAndAvailableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bureauPendingTasks(bureauId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelDirectDebitMandateV3(customerId: string, mandateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelDirectDebitMandateV3(customerId, mandateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelDirectDebitMandateV4(customerId: string, mandateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDirectDebitMandatesV4200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelDirectDebitMandateV4(customerId, mandateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelRepaymentInvoice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelRepaymentInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardDeliveryAddress(customerId: string, personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeCardDeliveryAddressResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cardDeliveryAddress(customerId, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cards(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficeCardsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cards(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async client(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BureauRelationshipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.client(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeCredit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeCredit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauConfigRequest} bureauConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configure(bureauConfigRequest: BureauConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BureauConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configure(bureauConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(accountDto: AccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(accountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Create a new Agreement with status draft
         * @param {string} customerId 
         * @param {CreateAgreementV6Request} createAgreementV6Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgreementV6(customerId: string, createAgreementV6Request: CreateAgreementV6Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllAgreementsV6200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgreementV6(customerId, createAgreementV6Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary create a new customer entity
         * @param {BackOfficeCustomerCreateDtoV2} backOfficeCustomerCreateDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(backOfficeCustomerCreateDtoV2: BackOfficeCustomerCreateDtoV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeCustomerDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(backOfficeCustomerCreateDtoV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreateDirectDebitMandateV3Request} createDirectDebitMandateV3Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDirectDebitMandateV3(customerId: string, createDirectDebitMandateV3Request: CreateDirectDebitMandateV3Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDirectDebitMandateV3(customerId, createDirectDebitMandateV3Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreateDirectDebitMandateV4Request} createDirectDebitMandateV4Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDirectDebitMandateV4(customerId: string, createDirectDebitMandateV4Request: CreateDirectDebitMandateV4Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDirectDebitMandatesV4200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDirectDebitMandateV4(customerId, createDirectDebitMandateV4Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftRepaymentInvoice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftRepaymentInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {CreateInvoiceV2Request} createInvoiceV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoiceV2(createInvoiceV2Request: CreateInvoiceV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeInvoiceResponseDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoiceV2(createInvoiceV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BackOfficeCreatePaymentForCustomerRequestDto} backOfficeCreatePaymentForCustomerRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayment(backOfficeCreatePaymentForCustomerRequestDto: BackOfficeCreatePaymentForCustomerRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficePaymentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPayment(backOfficeCreatePaymentForCustomerRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {CreatePersonDto} createPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPersonWithRoles(id: string, createPersonDto: CreatePersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPersonWithRoles(id, createPersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {RepaymentUpdateDto} repaymentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRepayment(id: string, planId: string, repaymentUpdateDto: RepaymentUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRepayment(id, planId, repaymentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Create new signatories data
         * @param {string} customerId 
         * @param {Array<BackOfficeSignatoryDto>} backOfficeSignatoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSignatories(customerId: string, backOfficeSignatoryDto: Array<BackOfficeSignatoryDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeSignatoryInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSignatories(customerId, backOfficeSignatoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {CreatePaymentRequestDtoV2} createPaymentRequestDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createV2(createPaymentRequestDtoV2: CreatePaymentRequestDtoV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRequestDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV2(createPaymentRequestDtoV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {VoucherDto} voucherDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVoucher(voucherDto: VoucherDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVoucher(voucherDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {AccountXYear} accountXYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createYear(accountXYear: AccountXYear, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountXYear>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createYear(accountXYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditRepaymentInvoice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditRepaymentInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async debtCollectRepaymentInvoice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.debtCollectRepaymentInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete1(customerId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(customerId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCreditOverride(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCreditOverride(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomer(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomer(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureName} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureOverride(customerId: string, featureName: BillingSettingsFeatureName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureOverride(customerId, featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(customerId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilexDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(customerId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFieldName} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOverrides(customerId: string, fieldName: BillingSettingsFieldName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOverrides(customerId, fieldName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} paymentWhiteListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePaymentWhiteListV1(customerId: string, paymentWhiteListId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePaymentWhiteListV1(customerId, paymentWhiteListId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(id: string, personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(id, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSnooze(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSnooze(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteYear(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteYear(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async directLoginToken(id: string, personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.directLoginToken(id, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dropInvoiceFromRepayment(id: string, planId: string, no: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dropInvoiceFromRepayment(id, planId, no, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchRepaymentPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchRepaymentPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SmsTemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBy(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerNoteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBy(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary fetch a customer entity by id
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerById(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeCustomerDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCustomerById(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary fetch all customers, or search by orgno (should only return one entry)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async findCustomers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficeCustomerDtoV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCustomers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEInvoiceAddresses(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EInvoiceAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEInvoiceAddresses(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forbiddenException(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forbiddenException(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalException(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalException(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [onDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCsvReport(onDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCsvReport(onDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateKycReport(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilexDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateKycReport(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get1(customerId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get1(customerId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAccount(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrepaidAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAccount3(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountV3Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount3(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get an accounts balance, available, and creditlimit. This requires a downstream call to enfuce.
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountStatusV5(customerId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeAccountStatusV5>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountStatusV5(customerId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountV2(customerId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeAccountGetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountV2(customerId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get an account on customer
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountV5(customerId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountsV5200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountV5(customerId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccounts(year?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsV2(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficeAccountGetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsV2(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of all accounts on customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsV5(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAccountsV5200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsV5(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivationGuide(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationGuideDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivationGuide(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivationGuideVisibility(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationGuideDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivationGuideVisibility(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get agreement details for given agreement-id
         * @param {string} customerId 
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgreementV6(customerId: string, agreementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllAgreementsV6200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgreementV6(customerId, agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of all agreements on customer
         * @param {string} customerId 
         * @param {Array<BackOfficeAgreementTypeV6>} [agreementType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAgreementsV6(customerId: string, agreementType?: Array<BackOfficeAgreementTypeV6>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllAgreementsV6200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAgreementsV6(customerId, agreementType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllContexts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllContexts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMonthlyReports(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthlyReportLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMonthlyReports(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRepaymentInvoices(creditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepaymentInvoiceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRepaymentInvoices(creditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRepaymentModels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRepaymentModels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [years] 
         * @param {Array<number>} [accounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVouchers(from?: string, to?: string, years?: Array<string>, accounts?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoucherDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVouchers(from, to, years, accounts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary Offset, start transaction list at this offset. Limit is how many transactions to return. transactionDate do not have a timezone
         * @param {string} customerId 
         * @param {number} [offset] pagination offset
         * @param {number} [limit] pagination limit
         * @param {string} [from] from date (inclusive) in ISO format, yyyy-MM-dd
         * @param {string} [to] to date (inclusive) in ISO format, yyyy-MM-dd
         * @param {number} [min] min transaction amount (inclusive)
         * @param {number} [max] max transaction amount (inclusive)
         * @param {Array<TransactionFilters>} [onlyinclude] various transaction property filters, some are mutually exclusive
         * @param {Array<TransactionFilters>} [exclude] 
         * @param {Array<AttestStatus>} [attestStatus] 
         * @param {string} [query] Free text query search. At least 3 characters, otherwise ignored
         * @param {Array<string>} [personIds] \&#39;or-ed\&#39; person id\&#39;s to query for, if omitted then transactions for all users will be returned. note: returned set increases with with more personIds and decreases with less personId, except for the empty set which returns all transactions
         * @param {Array<string>} [cardIds] 
         * @param {Array<BackOfficeTx2StatusFilter2>} [status] Filter on transaction status. Leave empty to include all statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoTransactionsV2(customerId: string, offset?: number, limit?: number, from?: string, to?: string, min?: number, max?: number, onlyinclude?: Array<TransactionFilters>, exclude?: Array<TransactionFilters>, attestStatus?: Array<AttestStatus>, query?: string, personIds?: Array<string>, cardIds?: Array<string>, status?: Array<BackOfficeTx2StatusFilter2>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetBoTransactionsV2DefaultResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoTransactionsV2(customerId, offset, limit, from, to, min, max, onlyinclude, exclude, attestStatus, query, personIds, cardIds, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCard(customerId: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisaCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCard(customerId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardBalanceConfig(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardBalanceConfigResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardBalanceConfig(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardConfig(customerId: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardConfig(customerId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardCreditV2(customerId: string, creditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeCardCreditGetV2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardCreditV2(customerId, creditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardCreditsV2(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficeCardCreditGetV2Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardCreditsV2(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardPlastics(customerId: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListPlasticResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardPlastics(customerId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCards(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisaCardListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCards(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyCredit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyCredit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCorporateCustomer(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorporateCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCorporateCustomer(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCredit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCredit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary returns 204 if no override is found
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreditOverride(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditOverrideDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreditOverride(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreditReport(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreditReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultFeesAndInterestsV2(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeDefaultFeesAndInterestsDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultFeesAndInterestsV2(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultPenaltyFeeV2(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeDefaultPenaltyFeeDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultPenaltyFeeV2(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectDebitMandatesV3(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficeDirectDebitMandateResponseDtoV3>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDirectDebitMandatesV3(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectDebitMandatesV4(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDirectDebitMandatesV4200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDirectDebitMandatesV4(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} uuid 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentUrl(uuid: string, filename?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentUrl(uuid, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDwhTextFile(filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDwhTextFile(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {Array<string>} [contexts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiles(customerId: string, contexts?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilexDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiles(customerId, contexts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [types] Filter for specific types. Accepts a comma separated list. If omitted, all types are returned
         * @param {string} [snoozed] should snoozed items be included or not
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getInbox(types?: string, snoozed?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboxItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInbox(types, snoozed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceV2(invoiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeInvoiceResponseDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceV2(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {Array<InvoiceType>} [type] 
         * @param {Array<InvoiceState>} [state] 
         * @param {string} [invoiceDateFrom] invoice date from including
         * @param {string} [invoiceDateTo] invoice date to including
         * @param {string} [settlementDateFrom] settlement date from including
         * @param {string} [settlementDateTo] settlement date to including
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesV2(customerId?: string, type?: Array<InvoiceType>, state?: Array<InvoiceState>, invoiceDateFrom?: string, invoiceDateTo?: string, settlementDateFrom?: string, settlementDateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficeInvoiceResponseDtoV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicesV2(customerId, type, state, invoiceDateFrom, invoiceDateTo, settlementDateFrom, settlementDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicingConfigV1(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackofficeInvoicingConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicingConfigV1(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} ssn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKycForPerson(customerId: string, ssn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycPerson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKycForPerson(customerId, ssn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKycList(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKycList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayment(paymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficePaymentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayment(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentReceiverV1(paymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeReceiverInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentReceiverV1(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentRequestV2(paymentRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRequestDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentRequestV2(paymentRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {string} [cardCreditId] 
         * @param {Array<string>} [currency] 
         * @param {boolean} [hasPaidEnough] 
         * @param {Array<PaymentRequestStatusDtoV2>} [status] 
         * @param {string} [dueDateFrom] 
         * @param {string} [dueDateTo] 
         * @param {string} [paymentRequestDateFrom] 
         * @param {string} [paymentRequestDateTo] 
         * @param {string} [periodFrom] 
         * @param {string} [periodTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentRequestsV2(customerId?: string, cardCreditId?: string, currency?: Array<string>, hasPaidEnough?: boolean, status?: Array<PaymentRequestStatusDtoV2>, dueDateFrom?: string, dueDateTo?: string, paymentRequestDateFrom?: string, paymentRequestDateTo?: string, periodFrom?: string, periodTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentRequestDtoV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentRequestsV2(customerId, cardCreditId, currency, hasPaidEnough, status, dueDateFrom, dueDateTo, paymentRequestDateFrom, paymentRequestDateTo, periodFrom, periodTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentWhiteListV1(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficePaymentWhiteListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentWhiteListV1(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {Array<PaymentState>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayments(state?: Array<PaymentState>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficePaymentResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayments(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfV2(paymentRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRequestPdfDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfV2(paymentRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfV21(invoiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeInvoicePdfResponseDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfV21(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeople(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycPeople>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeople(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonsWithRoles(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonsWithRoles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreSignedUrl(customerId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreSignedUrl(customerId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreferredDirectDebitMandateV3(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreferredDirectDebitMandateV3(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreferredDirectDebitMandateV4(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDirectDebitMandatesV4200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreferredDirectDebitMandateV4(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivateCustomers(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrivateCustomerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrivateCustomers(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {QuoteUrlRequestDto} quoteUrlRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuoteUrl(id: string, quoteUrlRequestDto: QuoteUrlRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuoteUrl(id, quoteUrlRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRawAccountV3(customerId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountV3Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRawAccountV3(customerId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRawAccountV4(customerId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrepaidAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRawAccountV4(customerId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRawAccountsV3(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListV3AccountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRawAccountsV3(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRawAccountsV4(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListPrepaidAccountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRawAccountsV4(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReminderPdfV2(invoiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeInvoicePdfResponseDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReminderPdfV2(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRepaymentInvoice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRepaymentInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRepaymentInvoiceSummary(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRepaymentInvoiceSummary(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRepaymentInvoicesForCompanyCredit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRepaymentInvoicesForCompanyCredit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTextFile(filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTextFile(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRisk(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycRisk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRisk(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get signatories data
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignatories(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeSignatoryInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignatories(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {Array<string>} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshots(dates?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SnapshotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshots(dates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(customerId: string, txId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(customerId, txId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary offset takes precedence over page if provided. Page is still available for backward compatibility. limit defaults to 20
         * @param {string} customerId 
         * @param {number} [page] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionsCombined(customerId: string, page?: number, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionsCombined(customerId, page, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [years] 
         * @param {Array<number>} [accounts] 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVouchersResultReport(from?: string, to?: string, years?: Array<string>, accounts?: Array<number>, currency?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVouchersResultReport(from, to, years, accounts, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYears(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountXYear>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYears(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} callback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleOAuthRedir(callback: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleOAuthRedir(callback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async illegalArgumentException(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.illegalArgumentException(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async illegalStateException(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.illegalStateException(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isLoggedIn(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeGoogleAuthResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isLoggedIn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycApproved(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycApproved(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeCustomerKycRequiresRenewalDtoV2} backOfficeCustomerKycRequiresRenewalDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycRequiresRenewal(customerId: string, backOfficeCustomerKycRequiresRenewalDtoV2: BackOfficeCustomerKycRequiresRenewalDtoV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycRequiresRenewal(customerId, backOfficeCustomerKycRequiresRenewalDtoV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BureauConfigResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async log(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerEventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.log(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ls(path?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ls(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markCreditAsDefault(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markCreditAsDefault(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markCreditAsNetted(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markCreditAsNetted(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markCreditAsRepaid(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markCreditAsRepaid(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notFoundException(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notFoundException(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyCardCreditDenied(customerId: string, creditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyCardCreditDenied(customerId, creditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyCustomerV2(paymentRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyCustomerV2(paymentRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeOrderCardRequestDto} backOfficeOrderCardRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderCard(customerId: string, backOfficeOrderCardRequestDto: BackOfficeOrderCardRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderCard(customerId, backOfficeOrderCardRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeOrderCardRequestDtoV3} backOfficeOrderCardRequestDtoV3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderCardV3(customerId: string, backOfficeOrderCardRequestDtoV3: BackOfficeOrderCardRequestDtoV3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderCardV3(customerId, backOfficeOrderCardRequestDtoV3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {FileType} type 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseDwhFull(type: FileType, filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseDwhFull(type, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {PatchPrepaidResourceBody} patchPrepaidResourceBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async patchAccount(customerId: string, patchPrepaidResourceBody: PatchPrepaidResourceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAccount(customerId, patchPrepaidResourceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {BackOfficeAccountPatchDto} backOfficeAccountPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAccountV2(customerId: string, accountId: string, backOfficeAccountPatchDto: BackOfficeAccountPatchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeAccountGetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAccountV2(customerId, accountId, backOfficeAccountPatchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {ActivationGuidePatchDto} activationGuidePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchActivationGuideVisibility(customerId: string, activationGuidePatchDto: ActivationGuidePatchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationGuideDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchActivationGuideVisibility(customerId, activationGuidePatchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {ActivationGuidePatchDto} activationGuidePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchActivationGuideVisibility1(customerId: string, activationGuidePatchDto: ActivationGuidePatchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationGuideDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchActivationGuideVisibility1(customerId, activationGuidePatchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {PatchStandardResourceBody} patchStandardResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCard(customerId: string, cardId: string, patchStandardResourceBody: PatchStandardResourceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCard(customerId, cardId, patchStandardResourceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CardBalanceConfigPatchDto} cardBalanceConfigPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCardBalanceConfig(customerId: string, cardBalanceConfigPatchDto: CardBalanceConfigPatchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardBalanceConfigResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCardBalanceConfig(customerId, cardBalanceConfigPatchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCardConfig(customerId: string, cardId: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCardConfig(customerId, cardId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {BackOfficeCardCreditPatchV2Dto} backOfficeCardCreditPatchV2Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCardCreditV2(customerId: string, creditId: string, backOfficeCardCreditPatchV2Dto: BackOfficeCardCreditPatchV2Dto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeCardCreditGetV2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCardCreditV2(customerId, creditId, backOfficeCardCreditPatchV2Dto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {BackOfficeCardPatchDto} backOfficeCardPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCardV1(customerId: string, cardId: string, backOfficeCardPatchDto: BackOfficeCardPatchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeCardsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCardV1(customerId, cardId, backOfficeCardPatchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRoleOrSalesRoleAndAllowed(#patch.changesOnlyProducts())
         * @param {string} customerId 
         * @param {BackOfficeCustomerPatchDtoV2} backOfficeCustomerPatchDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCustomer(customerId: string, backOfficeCustomerPatchDtoV2: BackOfficeCustomerPatchDtoV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeCustomerDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCustomer(customerId, backOfficeCustomerPatchDtoV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureOverridePatch} billingSettingsFeatureOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFeatureOverrides(customerId: string, billingSettingsFeatureOverridePatch: BillingSettingsFeatureOverridePatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFeatureOverrides(customerId, billingSettingsFeatureOverridePatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackofficeInvoicingConfigPatchDto} backofficeInvoicingConfigPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchInvoicingConfigV1(customerId: string, backofficeInvoicingConfigPatchDto: BackofficeInvoicingConfigPatchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackofficeInvoicingConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchInvoicingConfigV1(customerId, backofficeInvoicingConfigPatchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsOverridePatch} billingSettingsOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOverrides(customerId: string, billingSettingsOverridePatch: BillingSettingsOverridePatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOverrides(customerId, billingSettingsOverridePatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {BackOfficePatchPaymentRequestDto} backOfficePatchPaymentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPayment(paymentId: string, backOfficePatchPaymentRequestDto: BackOfficePatchPaymentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficePaymentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPayment(paymentId, backOfficePatchPaymentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {PatchPaymentRequestDtoV2} patchPaymentRequestDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPaymentRequestV2(paymentRequestId: string, patchPaymentRequestDtoV2: PatchPaymentRequestDtoV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRequestDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPaymentRequestV2(paymentRequestId, patchPaymentRequestDtoV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {PatchV3ResourceBody} patchV3ResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRawAccountV3(customerId: string, accountId: string, patchV3ResourceBody: PatchV3ResourceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchRawAccountV3(customerId, accountId, patchV3ResourceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {PatchPrepaidResourceBody} patchPrepaidResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRawAccountV4(customerId: string, accountId: string, patchPrepaidResourceBody: PatchPrepaidResourceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchRawAccountV4(customerId, accountId, patchPrepaidResourceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsTrialOverridePatch} billingSettingsTrialOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTrialOverrides(customerId: string, billingSettingsTrialOverridePatch: BillingSettingsTrialOverridePatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTrialOverrides(customerId, billingSettingsTrialOverridePatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {Amount} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutCompanyCredit(id: string, amount: Amount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutCompanyCredit(id, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async person(id: string, personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.person(id, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async person1(id: string, personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.person1(id, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BoCompanyCreditRequestV2} boCompanyCreditRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNewCompanyCreditRequestV2(boCompanyCreditRequestV2: BoCompanyCreditRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNewCompanyCreditRequestV2(boCompanyCreditRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRecalculateV2(paymentRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRequestDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRecalculateV2(paymentRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async put(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.put(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async put1(customerId: string, id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.put1(customerId, id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreditOverrideDto} creditOverrideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCreditOverride(customerId: string, creditOverrideDto: CreditOverrideDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditOverrideDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCreditOverride(customerId, creditOverrideDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {KycRisk} kycRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRisk(customerId: string, kycRisk: KycRisk, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycRisk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRisk(customerId, kycRisk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reCreateRepaymentInvoice(id: string, planId: string, no: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reCreateRepaymentInvoice(id, planId, no, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rebuildInvoicesAndRepaymentPlanFromFortnox(id: string, planId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rebuildInvoicesAndRepaymentPlanFromFortnox(id, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAddress(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAddress(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateAgreementDocument(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateAgreementDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateRepaymentPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateRepaymentPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reissueCard(customerId: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reissueCard(customerId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {Amount} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remindRepaymentInvoice(id: string, amount: Amount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remindRepaymentInvoice(id, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendDirectDebitMandateV3(customerId: string, mandateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendDirectDebitMandateV3(customerId, mandateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendDirectDebitMandateV4(customerId: string, mandateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDirectDebitMandatesV4200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendDirectDebitMandateV4(customerId, mandateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {ResetRequest} resetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetBureau(resetRequest: ResetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetBureau(resetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPinAttempt(customerId: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPinAttempt(customerId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasRole(\'BACKOFFICE_SALES\')
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesRoleChecker(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesRoleChecker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} q 
         * @param {number} [n] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(q: string, n?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FastSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(q, n, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {MonthlyReportRequest} monthlyReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMonthlyReport(customerId: string, monthlyReportRequest: MonthlyReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMonthlyReport(customerId, monthlyReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRenewalEmail(customerId: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRenewalEmail(customerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} cid 
         * @param {string} pid 
         * @param {SmsRequestDto} smsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSms(cid: string, pid: string, smsRequestDto: SmsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSms(cid, pid, smsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendWelcomeEmail(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendWelcomeEmail(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStandardTermsCustomerCredit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStandardTermsCustomerCredit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCard(customerId: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackofficeCardSyncResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCard(customerId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCompanyCreditWithFortnox(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCompanyCreditWithFortnox(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDirectDebitMandateV3(customerId: string, mandateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDirectDebitMandateV3(customerId, mandateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDirectDebitMandateV4(customerId: string, mandateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDirectDebitMandatesV4200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDirectDebitMandateV4(customerId, mandateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncFile(customerId: string, id: string, uploadRequest: UploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilexDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncFile(customerId, id, uploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncWithFortnox(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncWithFortnox(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of transaction conditions from Enfuce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionConditions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackOfficeTransactionConditionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionConditions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerBusinessEvent(customerId: string, txId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerBusinessEvent(customerId, txId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerBusinessEventV2(customerId: string, txId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerBusinessEventV2(customerId, txId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerDwhJob(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerDwhJob(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauUnassingmentRequest} bureauUnassingmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassign(bureauUnassingmentRequest: BureauUnassingmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BureauUnassingmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassign(bureauUnassingmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unauthorizedException(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unauthorizedException(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(id: string, accountDto: AccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(id, accountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCardGroupsForAllCards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCardGroupsForAllCards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {CompanyCreditDto} companyCreditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyCredit(id: string, companyCreditDto: CompanyCreditDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyCredit(id, companyCreditDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {BackOfficeInvoicePatchDto2} backOfficeInvoicePatchDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvoiceV2(invoiceId: string, backOfficeInvoicePatchDto2: BackOfficeInvoicePatchDto2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOfficeInvoiceResponseDtoV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvoiceV2(invoiceId, backOfficeInvoicePatchDto2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePeople(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycPeople>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePeople(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRoleOrSalesRoleAndAllowed(#patch.onlyInvite())
         * @param {string} id 
         * @param {string} personId 
         * @param {PersonPatchDto} personPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(id: string, personId: string, personPatchDto: PersonPatchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(id, personId, personPatchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {RepaymentUpdateDto} repaymentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRepayment(id: string, planId: string, no: number, repaymentUpdateDto: RepaymentUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRepayment(id, planId, no, repaymentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {AccountXYear} accountXYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateYear(id: string, accountXYear: AccountXYear, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountXYear>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateYear(id, accountXYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whiteListPaymentV1(paymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whiteListPaymentV1(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackOfficeAPIApi - factory interface
 * @export
 */
export const BackOfficeAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackOfficeAPIApiFp(configuration)
    return {
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<string> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsBasic(customerId: string, options?: any): AxiosPromise<Array<BackOfficeAccountBasicDto>> {
            return localVarFp.accountsBasic(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {string} plasticId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePlastic(customerId: string, cardId: string, plasticId: string, options?: any): AxiosPromise<void> {
            return localVarFp.activatePlastic(customerId, cardId, plasticId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(body: object, options?: any): AxiosPromise<SmsTemplateDto> {
            return localVarFp.add(body, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add1(customerId: string, body: object, options?: any): AxiosPromise<CustomerNoteDto> {
            return localVarFp.add1(customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFile(customerId: string, uploadRequest: UploadRequest, options?: any): AxiosPromise<UploadUrlDto> {
            return localVarFp.addFile(customerId, uploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} itemId 
         * @param {SnoozeDto} snoozeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSnooze(itemId: string, snoozeDto: SnoozeDto, options?: any): AxiosPromise<SnoozeDto> {
            return localVarFp.addSnooze(itemId, snoozeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCompanyCreditsV2(customerId?: string, options?: any): AxiosPromise<Array<CompanyCreditDto>> {
            return localVarFp.allCompanyCreditsV2(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {string} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCreditsV2(customerId?: string, level?: string, options?: any): AxiosPromise<Array<CreditDto>> {
            return localVarFp.allCreditsV2(customerId, level, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRoaringData(customerId: string, options?: any): AxiosPromise<object> {
            return localVarFp.allRoaringData(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alreadyExistingException(options?: any): AxiosPromise<object> {
            return localVarFp.alreadyExistingException(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCredit(id: string, options?: any): AxiosPromise<BaseCompanyCreditDto> {
            return localVarFp.approveCredit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCustomerCredit(id: string, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.approveCustomerCredit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauRelationshipRequest} bureauRelationshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assign(bureauRelationshipRequest: BureauRelationshipRequest, options?: any): AxiosPromise<BureauRelationshipResponse> {
            return localVarFp.assign(bureauRelationshipRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {AttachInvoiceRequestDto} attachInvoiceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachInvoiceToRepayment(id: string, planId: string, no: number, attachInvoiceRequestDto: AttachInvoiceRequestDto, options?: any): AxiosPromise<RepaymentDto> {
            return localVarFp.attachInvoiceToRepayment(id, planId, no, attachInvoiceRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backOrderCards(options?: any): AxiosPromise<void> {
            return localVarFp.backOrderCards(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeRoleChecker(options?: any): AxiosPromise<string> {
            return localVarFp.backofficeRoleChecker(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSettings(customerId: string, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.billingSettings(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PersonLoginRequest} personLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizOpsLogin(personLoginRequest: PersonLoginRequest, options?: any): AxiosPromise<object> {
            return localVarFp.bizOpsLogin(personLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookRepaymentInvoice(id: string, options?: any): AxiosPromise<RepaymentInvoiceDto> {
            return localVarFp.bookRepaymentInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bureau(bureauId: string, options?: any): AxiosPromise<BureauConfigResponse> {
            return localVarFp.bureau(bureauId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bureauClients(bureauId: string, options?: any): AxiosPromise<Array<BureauRelationshipResponse>> {
            return localVarFp.bureauClients(bureauId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} bureauId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bureauPendingTasks(bureauId: string, options?: any): AxiosPromise<BureauClientsPendingTaskAndAvailableResponse> {
            return localVarFp.bureauPendingTasks(bureauId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDirectDebitMandateV3(customerId: string, mandateId: string, options?: any): AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3> {
            return localVarFp.cancelDirectDebitMandateV3(customerId, mandateId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDirectDebitMandateV4(customerId: string, mandateId: string, options?: any): AxiosPromise<GetDirectDebitMandatesV4200ResponseInner> {
            return localVarFp.cancelDirectDebitMandateV4(customerId, mandateId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRepaymentInvoice(id: string, options?: any): AxiosPromise<RepaymentInvoiceDto> {
            return localVarFp.cancelRepaymentInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardDeliveryAddress(customerId: string, personId: string, options?: any): AxiosPromise<BackOfficeCardDeliveryAddressResponseDto> {
            return localVarFp.cardDeliveryAddress(customerId, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cards(customerId: string, options?: any): AxiosPromise<Array<BackOfficeCardsDto>> {
            return localVarFp.cards(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        client(clientId: string, options?: any): AxiosPromise<BureauRelationshipResponse> {
            return localVarFp.client(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeCredit(id: string, options?: any): AxiosPromise<BaseCompanyCreditDto> {
            return localVarFp.closeCredit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauConfigRequest} bureauConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configure(bureauConfigRequest: BureauConfigRequest, options?: any): AxiosPromise<BureauConfigResponse> {
            return localVarFp.configure(bureauConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(accountDto: AccountDto, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.createAccount(accountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Create a new Agreement with status draft
         * @param {string} customerId 
         * @param {CreateAgreementV6Request} createAgreementV6Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreementV6(customerId: string, createAgreementV6Request: CreateAgreementV6Request, options?: any): AxiosPromise<GetAllAgreementsV6200ResponseInner> {
            return localVarFp.createAgreementV6(customerId, createAgreementV6Request, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary create a new customer entity
         * @param {BackOfficeCustomerCreateDtoV2} backOfficeCustomerCreateDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(backOfficeCustomerCreateDtoV2: BackOfficeCustomerCreateDtoV2, options?: any): AxiosPromise<BackOfficeCustomerDtoV2> {
            return localVarFp.createCustomer(backOfficeCustomerCreateDtoV2, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreateDirectDebitMandateV3Request} createDirectDebitMandateV3Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectDebitMandateV3(customerId: string, createDirectDebitMandateV3Request: CreateDirectDebitMandateV3Request, options?: any): AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3> {
            return localVarFp.createDirectDebitMandateV3(customerId, createDirectDebitMandateV3Request, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreateDirectDebitMandateV4Request} createDirectDebitMandateV4Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectDebitMandateV4(customerId: string, createDirectDebitMandateV4Request: CreateDirectDebitMandateV4Request, options?: any): AxiosPromise<GetDirectDebitMandatesV4200ResponseInner> {
            return localVarFp.createDirectDebitMandateV4(customerId, createDirectDebitMandateV4Request, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftRepaymentInvoice(id: string, options?: any): AxiosPromise<RepaymentInvoiceDto> {
            return localVarFp.createDraftRepaymentInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {CreateInvoiceV2Request} createInvoiceV2Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceV2(createInvoiceV2Request: CreateInvoiceV2Request, options?: any): AxiosPromise<BackOfficeInvoiceResponseDtoV2> {
            return localVarFp.createInvoiceV2(createInvoiceV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BackOfficeCreatePaymentForCustomerRequestDto} backOfficeCreatePaymentForCustomerRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(backOfficeCreatePaymentForCustomerRequestDto: BackOfficeCreatePaymentForCustomerRequestDto, options?: any): AxiosPromise<BackOfficePaymentResponseDto> {
            return localVarFp.createPayment(backOfficeCreatePaymentForCustomerRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {CreatePersonDto} createPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonWithRoles(id: string, createPersonDto: CreatePersonDto, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.createPersonWithRoles(id, createPersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {RepaymentUpdateDto} repaymentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRepayment(id: string, planId: string, repaymentUpdateDto: RepaymentUpdateDto, options?: any): AxiosPromise<RepaymentDto> {
            return localVarFp.createRepayment(id, planId, repaymentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Create new signatories data
         * @param {string} customerId 
         * @param {Array<BackOfficeSignatoryDto>} backOfficeSignatoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSignatories(customerId: string, backOfficeSignatoryDto: Array<BackOfficeSignatoryDto>, options?: any): AxiosPromise<BackOfficeSignatoryInfoResponseDto> {
            return localVarFp.createSignatories(customerId, backOfficeSignatoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {CreatePaymentRequestDtoV2} createPaymentRequestDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createV2(createPaymentRequestDtoV2: CreatePaymentRequestDtoV2, options?: any): AxiosPromise<PaymentRequestDtoV2> {
            return localVarFp.createV2(createPaymentRequestDtoV2, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {VoucherDto} voucherDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVoucher(voucherDto: VoucherDto, options?: any): AxiosPromise<VoucherDto> {
            return localVarFp.createVoucher(voucherDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {AccountXYear} accountXYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createYear(accountXYear: AccountXYear, options?: any): AxiosPromise<AccountXYear> {
            return localVarFp.createYear(accountXYear, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditRepaymentInvoice(id: string, options?: any): AxiosPromise<RepaymentInvoiceDto> {
            return localVarFp.creditRepaymentInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debtCollectRepaymentInvoice(id: string, options?: any): AxiosPromise<RepaymentInvoiceDto> {
            return localVarFp.debtCollectRepaymentInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(customerId: string, id: string, options?: any): AxiosPromise<string> {
            return localVarFp.delete1(customerId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditOverride(customerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCreditOverride(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer(customerId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureName} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureOverride(customerId: string, featureName: BillingSettingsFeatureName, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.deleteFeatureOverride(customerId, featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(customerId: string, id: string, options?: any): AxiosPromise<FilexDto> {
            return localVarFp.deleteFile(customerId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFieldName} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOverrides(customerId: string, fieldName: BillingSettingsFieldName, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.deleteOverrides(customerId, fieldName, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} paymentWhiteListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentWhiteListV1(customerId: string, paymentWhiteListId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePaymentWhiteListV1(customerId, paymentWhiteListId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(id: string, personId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerson(id, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnooze(itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSnooze(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteYear(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteYear(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directLoginToken(id: string, personId: string, options?: any): AxiosPromise<void> {
            return localVarFp.directLoginToken(id, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropInvoiceFromRepayment(id: string, planId: string, no: number, options?: any): AxiosPromise<RepaymentDto> {
            return localVarFp.dropInvoiceFromRepayment(id, planId, no, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRepaymentPlan(id: string, options?: any): AxiosPromise<RepaymentPlanDto> {
            return localVarFp.fetchRepaymentPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<SmsTemplateDto>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBy(customerId: string, options?: any): AxiosPromise<Array<CustomerNoteDto>> {
            return localVarFp.findBy(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary fetch a customer entity by id
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerById(customerId: string, options?: any): AxiosPromise<BackOfficeCustomerDtoV2> {
            return localVarFp.findCustomerById(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary fetch all customers, or search by orgno (should only return one entry)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findCustomers(options?: any): AxiosPromise<Array<BackOfficeCustomerDtoV2>> {
            return localVarFp.findCustomers(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEInvoiceAddresses(customerId: string, options?: any): AxiosPromise<EInvoiceAddress> {
            return localVarFp.findEInvoiceAddresses(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forbiddenException(options?: any): AxiosPromise<object> {
            return localVarFp.forbiddenException(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalException(options?: any): AxiosPromise<object> {
            return localVarFp.generalException(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [onDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCsvReport(onDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.generateCsvReport(onDate, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateKycReport(customerId: string, options?: any): AxiosPromise<FilexDto> {
            return localVarFp.generateKycReport(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): AxiosPromise<SmsTemplateDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1(customerId: string, id: string, options?: any): AxiosPromise<CustomerNoteDto> {
            return localVarFp.get1(customerId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAccount(customerId: string, options?: any): AxiosPromise<PrepaidAccountResponse> {
            return localVarFp.getAccount(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAccount3(customerId: string, options?: any): AxiosPromise<AccountV3Response> {
            return localVarFp.getAccount3(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get an accounts balance, available, and creditlimit. This requires a downstream call to enfuce.
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountStatusV5(customerId: string, accountId: string, options?: any): AxiosPromise<BackOfficeAccountStatusV5> {
            return localVarFp.getAccountStatusV5(customerId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountV2(customerId: string, accountId: string, options?: any): AxiosPromise<BackOfficeAccountGetDto> {
            return localVarFp.getAccountV2(customerId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get an account on customer
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountV5(customerId: string, accountId: string, options?: any): AxiosPromise<GetAccountsV5200ResponseInner> {
            return localVarFp.getAccountV5(customerId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(year?: string, options?: any): AxiosPromise<Array<AccountDto>> {
            return localVarFp.getAccounts(year, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsV2(customerId: string, options?: any): AxiosPromise<Array<BackOfficeAccountGetDto>> {
            return localVarFp.getAccountsV2(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of all accounts on customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsV5(customerId: string, options?: any): AxiosPromise<Array<GetAccountsV5200ResponseInner>> {
            return localVarFp.getAccountsV5(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivationGuide(customerId: string, options?: any): AxiosPromise<ActivationGuideDto> {
            return localVarFp.getActivationGuide(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivationGuideVisibility(customerId: string, options?: any): AxiosPromise<ActivationGuideDto> {
            return localVarFp.getActivationGuideVisibility(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get agreement details for given agreement-id
         * @param {string} customerId 
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementV6(customerId: string, agreementId: string, options?: any): AxiosPromise<GetAllAgreementsV6200ResponseInner> {
            return localVarFp.getAgreementV6(customerId, agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of all agreements on customer
         * @param {string} customerId 
         * @param {Array<BackOfficeAgreementTypeV6>} [agreementType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgreementsV6(customerId: string, agreementType?: Array<BackOfficeAgreementTypeV6>, options?: any): AxiosPromise<Array<GetAllAgreementsV6200ResponseInner>> {
            return localVarFp.getAllAgreementsV6(customerId, agreementType, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContexts(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllContexts(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMonthlyReports(customerId: string, options?: any): AxiosPromise<Array<MonthlyReportLog>> {
            return localVarFp.getAllMonthlyReports(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRepaymentInvoices(creditId: string, options?: any): AxiosPromise<Array<RepaymentInvoiceDto>> {
            return localVarFp.getAllRepaymentInvoices(creditId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRepaymentModels(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllRepaymentModels(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [years] 
         * @param {Array<number>} [accounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVouchers(from?: string, to?: string, years?: Array<string>, accounts?: Array<number>, options?: any): AxiosPromise<Array<VoucherDto>> {
            return localVarFp.getAllVouchers(from, to, years, accounts, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary Offset, start transaction list at this offset. Limit is how many transactions to return. transactionDate do not have a timezone
         * @param {string} customerId 
         * @param {number} [offset] pagination offset
         * @param {number} [limit] pagination limit
         * @param {string} [from] from date (inclusive) in ISO format, yyyy-MM-dd
         * @param {string} [to] to date (inclusive) in ISO format, yyyy-MM-dd
         * @param {number} [min] min transaction amount (inclusive)
         * @param {number} [max] max transaction amount (inclusive)
         * @param {Array<TransactionFilters>} [onlyinclude] various transaction property filters, some are mutually exclusive
         * @param {Array<TransactionFilters>} [exclude] 
         * @param {Array<AttestStatus>} [attestStatus] 
         * @param {string} [query] Free text query search. At least 3 characters, otherwise ignored
         * @param {Array<string>} [personIds] \&#39;or-ed\&#39; person id\&#39;s to query for, if omitted then transactions for all users will be returned. note: returned set increases with with more personIds and decreases with less personId, except for the empty set which returns all transactions
         * @param {Array<string>} [cardIds] 
         * @param {Array<BackOfficeTx2StatusFilter2>} [status] Filter on transaction status. Leave empty to include all statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoTransactionsV2(customerId: string, offset?: number, limit?: number, from?: string, to?: string, min?: number, max?: number, onlyinclude?: Array<TransactionFilters>, exclude?: Array<TransactionFilters>, attestStatus?: Array<AttestStatus>, query?: string, personIds?: Array<string>, cardIds?: Array<string>, status?: Array<BackOfficeTx2StatusFilter2>, options?: any): AxiosPromise<Array<GetBoTransactionsV2DefaultResponseInner>> {
            return localVarFp.getBoTransactionsV2(customerId, offset, limit, from, to, min, max, onlyinclude, exclude, attestStatus, query, personIds, cardIds, status, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard(customerId: string, cardId: string, options?: any): AxiosPromise<VisaCardResponse> {
            return localVarFp.getCard(customerId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardBalanceConfig(customerId: string, options?: any): AxiosPromise<CardBalanceConfigResponseDto> {
            return localVarFp.getCardBalanceConfig(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardConfig(customerId: string, cardId: string, options?: any): AxiosPromise<CardConfig> {
            return localVarFp.getCardConfig(customerId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardCreditV2(customerId: string, creditId: string, options?: any): AxiosPromise<BackOfficeCardCreditGetV2Dto> {
            return localVarFp.getCardCreditV2(customerId, creditId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardCreditsV2(customerId: string, options?: any): AxiosPromise<Array<BackOfficeCardCreditGetV2Dto>> {
            return localVarFp.getCardCreditsV2(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardPlastics(customerId: string, cardId: string, options?: any): AxiosPromise<Array<ListPlasticResponse>> {
            return localVarFp.getCardPlastics(customerId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(customerId: string, options?: any): AxiosPromise<Array<VisaCardListResponse>> {
            return localVarFp.getCards(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCredit(id: string, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.getCompanyCredit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorporateCustomer(customerId: string, options?: any): AxiosPromise<CorporateCustomerResponse> {
            return localVarFp.getCorporateCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredit(id: string, options?: any): AxiosPromise<BaseCompanyCreditDto> {
            return localVarFp.getCredit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary returns 204 if no override is found
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditOverride(customerId: string, options?: any): AxiosPromise<CreditOverrideDto> {
            return localVarFp.getCreditOverride(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditReport(id: string, options?: any): AxiosPromise<ReportDto> {
            return localVarFp.getCreditReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultFeesAndInterestsV2(customerId: string, options?: any): AxiosPromise<BackOfficeDefaultFeesAndInterestsDto2> {
            return localVarFp.getDefaultFeesAndInterestsV2(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultPenaltyFeeV2(customerId: string, options?: any): AxiosPromise<BackOfficeDefaultPenaltyFeeDto2> {
            return localVarFp.getDefaultPenaltyFeeV2(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectDebitMandatesV3(customerId: string, options?: any): AxiosPromise<Array<BackOfficeDirectDebitMandateResponseDtoV3>> {
            return localVarFp.getDirectDebitMandatesV3(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectDebitMandatesV4(customerId: string, options?: any): AxiosPromise<Array<GetDirectDebitMandatesV4200ResponseInner>> {
            return localVarFp.getDirectDebitMandatesV4(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} uuid 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUrl(uuid: string, filename?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getDocumentUrl(uuid, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDwhTextFile(filename: string, options?: any): AxiosPromise<string> {
            return localVarFp.getDwhTextFile(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {Array<string>} [contexts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(customerId: string, contexts?: Array<string>, options?: any): AxiosPromise<Array<FilexDto>> {
            return localVarFp.getFiles(customerId, contexts, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [types] Filter for specific types. Accepts a comma separated list. If omitted, all types are returned
         * @param {string} [snoozed] should snoozed items be included or not
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInbox(types?: string, snoozed?: string, options?: any): AxiosPromise<Array<InboxItemDto>> {
            return localVarFp.getInbox(types, snoozed, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceV2(invoiceId: string, options?: any): AxiosPromise<BackOfficeInvoiceResponseDtoV2> {
            return localVarFp.getInvoiceV2(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {Array<InvoiceType>} [type] 
         * @param {Array<InvoiceState>} [state] 
         * @param {string} [invoiceDateFrom] invoice date from including
         * @param {string} [invoiceDateTo] invoice date to including
         * @param {string} [settlementDateFrom] settlement date from including
         * @param {string} [settlementDateTo] settlement date to including
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesV2(customerId?: string, type?: Array<InvoiceType>, state?: Array<InvoiceState>, invoiceDateFrom?: string, invoiceDateTo?: string, settlementDateFrom?: string, settlementDateTo?: string, options?: any): AxiosPromise<Array<BackOfficeInvoiceResponseDtoV2>> {
            return localVarFp.getInvoicesV2(customerId, type, state, invoiceDateFrom, invoiceDateTo, settlementDateFrom, settlementDateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicingConfigV1(customerId: string, options?: any): AxiosPromise<BackofficeInvoicingConfigDto> {
            return localVarFp.getInvoicingConfigV1(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} ssn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycForPerson(customerId: string, ssn: string, options?: any): AxiosPromise<KycPerson> {
            return localVarFp.getKycForPerson(customerId, ssn, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycList(id: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getKycList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartners(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPartners(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment(paymentId: string, options?: any): AxiosPromise<BackOfficePaymentResponseDto> {
            return localVarFp.getPayment(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentReceiverV1(paymentId: string, options?: any): AxiosPromise<BackOfficeReceiverInformationDto> {
            return localVarFp.getPaymentReceiverV1(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentRequestV2(paymentRequestId: string, options?: any): AxiosPromise<PaymentRequestDtoV2> {
            return localVarFp.getPaymentRequestV2(paymentRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {string} [cardCreditId] 
         * @param {Array<string>} [currency] 
         * @param {boolean} [hasPaidEnough] 
         * @param {Array<PaymentRequestStatusDtoV2>} [status] 
         * @param {string} [dueDateFrom] 
         * @param {string} [dueDateTo] 
         * @param {string} [paymentRequestDateFrom] 
         * @param {string} [paymentRequestDateTo] 
         * @param {string} [periodFrom] 
         * @param {string} [periodTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentRequestsV2(customerId?: string, cardCreditId?: string, currency?: Array<string>, hasPaidEnough?: boolean, status?: Array<PaymentRequestStatusDtoV2>, dueDateFrom?: string, dueDateTo?: string, paymentRequestDateFrom?: string, paymentRequestDateTo?: string, periodFrom?: string, periodTo?: string, options?: any): AxiosPromise<Array<PaymentRequestDtoV2>> {
            return localVarFp.getPaymentRequestsV2(customerId, cardCreditId, currency, hasPaidEnough, status, dueDateFrom, dueDateTo, paymentRequestDateFrom, paymentRequestDateTo, periodFrom, periodTo, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentWhiteListV1(customerId: string, options?: any): AxiosPromise<Array<BackOfficePaymentWhiteListDto>> {
            return localVarFp.getPaymentWhiteListV1(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {Array<PaymentState>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments(state?: Array<PaymentState>, options?: any): AxiosPromise<Array<BackOfficePaymentResponseDto>> {
            return localVarFp.getPayments(state, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfV2(paymentRequestId: string, options?: any): AxiosPromise<PaymentRequestPdfDtoV2> {
            return localVarFp.getPdfV2(paymentRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfV21(invoiceId: string, options?: any): AxiosPromise<BackOfficeInvoicePdfResponseDto2> {
            return localVarFp.getPdfV21(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeople(customerId: string, options?: any): AxiosPromise<KycPeople> {
            return localVarFp.getPeople(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonsWithRoles(id: string, options?: any): AxiosPromise<Array<PersonDto>> {
            return localVarFp.getPersonsWithRoles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreSignedUrl(customerId: string, id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPreSignedUrl(customerId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferredDirectDebitMandateV3(customerId: string, options?: any): AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3> {
            return localVarFp.getPreferredDirectDebitMandateV3(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferredDirectDebitMandateV4(customerId: string, options?: any): AxiosPromise<GetDirectDebitMandatesV4200ResponseInner> {
            return localVarFp.getPreferredDirectDebitMandateV4(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivateCustomers(customerId: string, options?: any): AxiosPromise<Array<PrivateCustomerResponse>> {
            return localVarFp.getPrivateCustomers(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {QuoteUrlRequestDto} quoteUrlRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteUrl(id: string, quoteUrlRequestDto: QuoteUrlRequestDto, options?: any): AxiosPromise<QuoteUrlDto> {
            return localVarFp.getQuoteUrl(id, quoteUrlRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawAccountV3(customerId: string, accountId: string, options?: any): AxiosPromise<AccountV3Response> {
            return localVarFp.getRawAccountV3(customerId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawAccountV4(customerId: string, accountId: string, options?: any): AxiosPromise<PrepaidAccountResponse> {
            return localVarFp.getRawAccountV4(customerId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawAccountsV3(customerId: string, options?: any): AxiosPromise<Array<ListV3AccountResponse>> {
            return localVarFp.getRawAccountsV3(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawAccountsV4(customerId: string, options?: any): AxiosPromise<Array<ListPrepaidAccountResponse>> {
            return localVarFp.getRawAccountsV4(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminderPdfV2(invoiceId: string, options?: any): AxiosPromise<BackOfficeInvoicePdfResponseDto2> {
            return localVarFp.getReminderPdfV2(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepaymentInvoice(id: string, options?: any): AxiosPromise<RepaymentInvoiceDto> {
            return localVarFp.getRepaymentInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepaymentInvoiceSummary(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getRepaymentInvoiceSummary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepaymentInvoicesForCompanyCredit(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getRepaymentInvoicesForCompanyCredit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTextFile(filename: string, options?: any): AxiosPromise<string> {
            return localVarFp.getReportTextFile(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRisk(customerId: string, options?: any): AxiosPromise<KycRisk> {
            return localVarFp.getRisk(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get signatories data
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignatories(customerId: string, options?: any): AxiosPromise<BackOfficeSignatoryInfoResponseDto> {
            return localVarFp.getSignatories(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {Array<string>} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshots(dates?: Array<string>, options?: any): AxiosPromise<Array<SnapshotDto>> {
            return localVarFp.getSnapshots(dates, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(customerId: string, txId: string, options?: any): AxiosPromise<TransactionDto> {
            return localVarFp.getTransaction(customerId, txId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @summary offset takes precedence over page if provided. Page is still available for backward compatibility. limit defaults to 20
         * @param {string} customerId 
         * @param {number} [page] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsCombined(customerId: string, page?: number, offset?: number, limit?: number, options?: any): AxiosPromise<Array<TransactionDto>> {
            return localVarFp.getTransactionsCombined(customerId, page, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [years] 
         * @param {Array<number>} [accounts] 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVouchersResultReport(from?: string, to?: string, years?: Array<string>, accounts?: Array<number>, currency?: string, options?: any): AxiosPromise<Array<AccountResultDto>> {
            return localVarFp.getVouchersResultReport(from, to, years, accounts, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYears(options?: any): AxiosPromise<Array<AccountXYear>> {
            return localVarFp.getYears(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} callback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleOAuthRedir(callback: string, options?: any): AxiosPromise<string> {
            return localVarFp.googleOAuthRedir(callback, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        illegalArgumentException(options?: any): AxiosPromise<object> {
            return localVarFp.illegalArgumentException(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        illegalStateException(options?: any): AxiosPromise<object> {
            return localVarFp.illegalStateException(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isLoggedIn(options?: any): AxiosPromise<BackOfficeGoogleAuthResponseDto> {
            return localVarFp.isLoggedIn(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApproved(customerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.kycApproved(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeCustomerKycRequiresRenewalDtoV2} backOfficeCustomerKycRequiresRenewalDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycRequiresRenewal(customerId: string, backOfficeCustomerKycRequiresRenewalDtoV2: BackOfficeCustomerKycRequiresRenewalDtoV2, options?: any): AxiosPromise<void> {
            return localVarFp.kycRequiresRenewal(customerId, backOfficeCustomerKycRequiresRenewalDtoV2, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): AxiosPromise<Array<BureauConfigResponse>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log(id: string, options?: any): AxiosPromise<Array<CustomerEventDto>> {
            return localVarFp.log(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<string> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ls(path?: string, options?: any): AxiosPromise<string> {
            return localVarFp.ls(path, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCreditAsDefault(id: string, options?: any): AxiosPromise<BaseCompanyCreditDto> {
            return localVarFp.markCreditAsDefault(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCreditAsNetted(id: string, options?: any): AxiosPromise<BaseCompanyCreditDto> {
            return localVarFp.markCreditAsNetted(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCreditAsRepaid(id: string, options?: any): AxiosPromise<BaseCompanyCreditDto> {
            return localVarFp.markCreditAsRepaid(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notFoundException(options?: any): AxiosPromise<object> {
            return localVarFp.notFoundException(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyCardCreditDenied(customerId: string, creditId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notifyCardCreditDenied(customerId, creditId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyCustomerV2(paymentRequestId: string, options?: any): AxiosPromise<object> {
            return localVarFp.notifyCustomerV2(paymentRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeOrderCardRequestDto} backOfficeOrderCardRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCard(customerId: string, backOfficeOrderCardRequestDto: BackOfficeOrderCardRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.orderCard(customerId, backOfficeOrderCardRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackOfficeOrderCardRequestDtoV3} backOfficeOrderCardRequestDtoV3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCardV3(customerId: string, backOfficeOrderCardRequestDtoV3: BackOfficeOrderCardRequestDtoV3, options?: any): AxiosPromise<void> {
            return localVarFp.orderCardV3(customerId, backOfficeOrderCardRequestDtoV3, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {FileType} type 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseDwhFull(type: FileType, filename: string, options?: any): AxiosPromise<object> {
            return localVarFp.parseDwhFull(type, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {PatchPrepaidResourceBody} patchPrepaidResourceBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchAccount(customerId: string, patchPrepaidResourceBody: PatchPrepaidResourceBody, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.patchAccount(customerId, patchPrepaidResourceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {BackOfficeAccountPatchDto} backOfficeAccountPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAccountV2(customerId: string, accountId: string, backOfficeAccountPatchDto: BackOfficeAccountPatchDto, options?: any): AxiosPromise<BackOfficeAccountGetDto> {
            return localVarFp.patchAccountV2(customerId, accountId, backOfficeAccountPatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {ActivationGuidePatchDto} activationGuidePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchActivationGuideVisibility(customerId: string, activationGuidePatchDto: ActivationGuidePatchDto, options?: any): AxiosPromise<ActivationGuideDto> {
            return localVarFp.patchActivationGuideVisibility(customerId, activationGuidePatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {ActivationGuidePatchDto} activationGuidePatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchActivationGuideVisibility1(customerId: string, activationGuidePatchDto: ActivationGuidePatchDto, options?: any): AxiosPromise<ActivationGuideDto> {
            return localVarFp.patchActivationGuideVisibility1(customerId, activationGuidePatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {PatchStandardResourceBody} patchStandardResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCard(customerId: string, cardId: string, patchStandardResourceBody: PatchStandardResourceBody, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.patchCard(customerId, cardId, patchStandardResourceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CardBalanceConfigPatchDto} cardBalanceConfigPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCardBalanceConfig(customerId: string, cardBalanceConfigPatchDto: CardBalanceConfigPatchDto, options?: any): AxiosPromise<CardBalanceConfigResponseDto> {
            return localVarFp.patchCardBalanceConfig(customerId, cardBalanceConfigPatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCardConfig(customerId: string, cardId: string, body: object, options?: any): AxiosPromise<CardConfig> {
            return localVarFp.patchCardConfig(customerId, cardId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} creditId 
         * @param {BackOfficeCardCreditPatchV2Dto} backOfficeCardCreditPatchV2Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCardCreditV2(customerId: string, creditId: string, backOfficeCardCreditPatchV2Dto: BackOfficeCardCreditPatchV2Dto, options?: any): AxiosPromise<BackOfficeCardCreditGetV2Dto> {
            return localVarFp.patchCardCreditV2(customerId, creditId, backOfficeCardCreditPatchV2Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {BackOfficeCardPatchDto} backOfficeCardPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCardV1(customerId: string, cardId: string, backOfficeCardPatchDto: BackOfficeCardPatchDto, options?: any): AxiosPromise<BackOfficeCardsDto> {
            return localVarFp.patchCardV1(customerId, cardId, backOfficeCardPatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRoleOrSalesRoleAndAllowed(#patch.changesOnlyProducts())
         * @param {string} customerId 
         * @param {BackOfficeCustomerPatchDtoV2} backOfficeCustomerPatchDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCustomer(customerId: string, backOfficeCustomerPatchDtoV2: BackOfficeCustomerPatchDtoV2, options?: any): AxiosPromise<BackOfficeCustomerDtoV2> {
            return localVarFp.patchCustomer(customerId, backOfficeCustomerPatchDtoV2, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureOverridePatch} billingSettingsFeatureOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureOverrides(customerId: string, billingSettingsFeatureOverridePatch: BillingSettingsFeatureOverridePatch, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.patchFeatureOverrides(customerId, billingSettingsFeatureOverridePatch, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BackofficeInvoicingConfigPatchDto} backofficeInvoicingConfigPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInvoicingConfigV1(customerId: string, backofficeInvoicingConfigPatchDto: BackofficeInvoicingConfigPatchDto, options?: any): AxiosPromise<BackofficeInvoicingConfigDto> {
            return localVarFp.patchInvoicingConfigV1(customerId, backofficeInvoicingConfigPatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsOverridePatch} billingSettingsOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOverrides(customerId: string, billingSettingsOverridePatch: BillingSettingsOverridePatch, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.patchOverrides(customerId, billingSettingsOverridePatch, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {BackOfficePatchPaymentRequestDto} backOfficePatchPaymentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPayment(paymentId: string, backOfficePatchPaymentRequestDto: BackOfficePatchPaymentRequestDto, options?: any): AxiosPromise<BackOfficePaymentResponseDto> {
            return localVarFp.patchPayment(paymentId, backOfficePatchPaymentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {PatchPaymentRequestDtoV2} patchPaymentRequestDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPaymentRequestV2(paymentRequestId: string, patchPaymentRequestDtoV2: PatchPaymentRequestDtoV2, options?: any): AxiosPromise<PaymentRequestDtoV2> {
            return localVarFp.patchPaymentRequestV2(paymentRequestId, patchPaymentRequestDtoV2, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {PatchV3ResourceBody} patchV3ResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRawAccountV3(customerId: string, accountId: string, patchV3ResourceBody: PatchV3ResourceBody, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.patchRawAccountV3(customerId, accountId, patchV3ResourceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} accountId 
         * @param {PatchPrepaidResourceBody} patchPrepaidResourceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRawAccountV4(customerId: string, accountId: string, patchPrepaidResourceBody: PatchPrepaidResourceBody, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.patchRawAccountV4(customerId, accountId, patchPrepaidResourceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsTrialOverridePatch} billingSettingsTrialOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTrialOverrides(customerId: string, billingSettingsTrialOverridePatch: BillingSettingsTrialOverridePatch, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.patchTrialOverrides(customerId, billingSettingsTrialOverridePatch, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {Amount} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutCompanyCredit(id: string, amount: Amount, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.payoutCompanyCredit(id, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        person(id: string, personId: string, options?: any): AxiosPromise<void> {
            return localVarFp.person(id, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} id 
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        person1(id: string, personId: string, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.person1(id, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BoCompanyCreditRequestV2} boCompanyCreditRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNewCompanyCreditRequestV2(boCompanyCreditRequestV2: BoCompanyCreditRequestV2, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.postNewCompanyCreditRequestV2(boCompanyCreditRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRecalculateV2(paymentRequestId: string, options?: any): AxiosPromise<PaymentRequestDtoV2> {
            return localVarFp.postRecalculateV2(paymentRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put(id: string, body: object, options?: any): AxiosPromise<SmsTemplateDto> {
            return localVarFp.put(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put1(customerId: string, id: string, body: object, options?: any): AxiosPromise<CustomerNoteDto> {
            return localVarFp.put1(customerId, id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {CreditOverrideDto} creditOverrideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCreditOverride(customerId: string, creditOverrideDto: CreditOverrideDto, options?: any): AxiosPromise<CreditOverrideDto> {
            return localVarFp.putCreditOverride(customerId, creditOverrideDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {KycRisk} kycRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRisk(customerId: string, kycRisk: KycRisk, options?: any): AxiosPromise<KycRisk> {
            return localVarFp.putRisk(customerId, kycRisk, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reCreateRepaymentInvoice(id: string, planId: string, no: number, options?: any): AxiosPromise<RepaymentDto> {
            return localVarFp.reCreateRepaymentInvoice(id, planId, no, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rebuildInvoicesAndRepaymentPlanFromFortnox(id: string, planId: string, options?: any): AxiosPromise<RepaymentPlanDto> {
            return localVarFp.rebuildInvoicesAndRepaymentPlanFromFortnox(id, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAddress(customerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.refreshAddress(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateAgreementDocument(id: string, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.regenerateAgreementDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateRepaymentPlan(id: string, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.regenerateRepaymentPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reissueCard(customerId: string, cardId: string, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.reissueCard(customerId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {Amount} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remindRepaymentInvoice(id: string, amount: Amount, options?: any): AxiosPromise<RepaymentInvoiceDto> {
            return localVarFp.remindRepaymentInvoice(id, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendDirectDebitMandateV3(customerId: string, mandateId: string, options?: any): AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3> {
            return localVarFp.resendDirectDebitMandateV3(customerId, mandateId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendDirectDebitMandateV4(customerId: string, mandateId: string, options?: any): AxiosPromise<GetDirectDebitMandatesV4200ResponseInner> {
            return localVarFp.resendDirectDebitMandateV4(customerId, mandateId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {ResetRequest} resetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetBureau(resetRequest: ResetRequest, options?: any): AxiosPromise<ResetResponse> {
            return localVarFp.resetBureau(resetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPinAttempt(customerId: string, cardId: string, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.resetPinAttempt(customerId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasRole(\'BACKOFFICE_SALES\')
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesRoleChecker(options?: any): AxiosPromise<string> {
            return localVarFp.salesRoleChecker(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} q 
         * @param {number} [n] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(q: string, n?: number, options?: any): AxiosPromise<FastSearchResult> {
            return localVarFp.search(q, n, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTypes(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.searchTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {MonthlyReportRequest} monthlyReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMonthlyReport(customerId: string, monthlyReportRequest: MonthlyReportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendMonthlyReport(customerId, monthlyReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRenewalEmail(customerId: string, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendRenewalEmail(customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} cid 
         * @param {string} pid 
         * @param {SmsRequestDto} smsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSms(cid: string, pid: string, smsRequestDto: SmsRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendSms(cid, pid, smsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWelcomeEmail(customerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendWelcomeEmail(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStandardTermsCustomerCredit(id: string, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.setStandardTermsCustomerCredit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCard(customerId: string, cardId: string, options?: any): AxiosPromise<BackofficeCardSyncResponseDto> {
            return localVarFp.syncCard(customerId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCompanyCreditWithFortnox(id: string, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.syncCompanyCreditWithFortnox(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDirectDebitMandateV3(customerId: string, mandateId: string, options?: any): AxiosPromise<BackOfficeDirectDebitMandateResponseDtoV3> {
            return localVarFp.syncDirectDebitMandateV3(customerId, mandateId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} mandateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDirectDebitMandateV4(customerId: string, mandateId: string, options?: any): AxiosPromise<GetDirectDebitMandatesV4200ResponseInner> {
            return localVarFp.syncDirectDebitMandateV4(customerId, mandateId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} id 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncFile(customerId: string, id: string, uploadRequest: UploadRequest, options?: any): AxiosPromise<FilexDto> {
            return localVarFp.syncFile(customerId, id, uploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithFortnox(id: string, options?: any): AxiosPromise<RepaymentInvoiceDto> {
            return localVarFp.syncWithFortnox(id, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @summary Get a list of transaction conditions from Enfuce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionConditions(options?: any): AxiosPromise<Array<BackOfficeTransactionConditionDto>> {
            return localVarFp.transactionConditions(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerBusinessEvent(customerId: string, txId: string, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.triggerBusinessEvent(customerId, txId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerBusinessEventV2(customerId: string, txId: string, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.triggerBusinessEventV2(customerId, txId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerDwhJob(options?: any): AxiosPromise<object> {
            return localVarFp.triggerDwhJob(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {BureauUnassingmentRequest} bureauUnassingmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassign(bureauUnassingmentRequest: BureauUnassingmentRequest, options?: any): AxiosPromise<BureauUnassingmentResponse> {
            return localVarFp.unassign(bureauUnassingmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unauthorizedException(options?: any): AxiosPromise<object> {
            return localVarFp.unauthorizedException(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(id: string, accountDto: AccountDto, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.updateAccount(id, accountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardGroupsForAllCards(options?: any): AxiosPromise<void> {
            return localVarFp.updateCardGroupsForAllCards(options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {CompanyCreditDto} companyCreditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCredit(id: string, companyCreditDto: CompanyCreditDto, options?: any): AxiosPromise<CompanyCreditDto> {
            return localVarFp.updateCompanyCredit(id, companyCreditDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} invoiceId 
         * @param {BackOfficeInvoicePatchDto2} backOfficeInvoicePatchDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceV2(invoiceId: string, backOfficeInvoicePatchDto2: BackOfficeInvoicePatchDto2, options?: any): AxiosPromise<BackOfficeInvoiceResponseDtoV2> {
            return localVarFp.updateInvoiceV2(invoiceId, backOfficeInvoicePatchDto2, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePeople(customerId: string, options?: any): AxiosPromise<KycPeople> {
            return localVarFp.updatePeople(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRoleOrSalesRoleAndAllowed(#patch.onlyInvite())
         * @param {string} id 
         * @param {string} personId 
         * @param {PersonPatchDto} personPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(id: string, personId: string, personPatchDto: PersonPatchDto, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.updatePerson(id, personId, personPatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {string} planId 
         * @param {number} no 
         * @param {RepaymentUpdateDto} repaymentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRepayment(id: string, planId: string, no: number, repaymentUpdateDto: RepaymentUpdateDto, options?: any): AxiosPromise<RepaymentDto> {
            return localVarFp.updateRepayment(id, planId, no, repaymentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} id 
         * @param {AccountXYear} accountXYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYear(id: string, accountXYear: AccountXYear, options?: any): AxiosPromise<AccountXYear> {
            return localVarFp.updateYear(id, accountXYear, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whiteListPaymentV1(paymentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.whiteListPaymentV1(paymentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackOfficeAPIApi - object-oriented interface
 * @export
 * @class BackOfficeAPIApi
 * @extends {BaseAPI}
 */
export class BackOfficeAPIApi extends BaseAPI {
    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public _delete(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public accountsBasic(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).accountsBasic(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {string} plasticId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public activatePlastic(customerId: string, cardId: string, plasticId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).activatePlastic(customerId, cardId, plasticId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public add(body: object, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).add(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public add1(customerId: string, body: object, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).add1(customerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {UploadRequest} uploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public addFile(customerId: string, uploadRequest: UploadRequest, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).addFile(customerId, uploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} itemId 
     * @param {SnoozeDto} snoozeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public addSnooze(itemId: string, snoozeDto: SnoozeDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).addSnooze(itemId, snoozeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public allCompanyCreditsV2(customerId?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).allCompanyCreditsV2(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [customerId] 
     * @param {string} [level] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public allCreditsV2(customerId?: string, level?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).allCreditsV2(customerId, level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public allRoaringData(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).allRoaringData(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public alreadyExistingException(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).alreadyExistingException(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public approveCredit(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).approveCredit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public approveCustomerCredit(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).approveCustomerCredit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {BureauRelationshipRequest} bureauRelationshipRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public assign(bureauRelationshipRequest: BureauRelationshipRequest, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).assign(bureauRelationshipRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {string} planId 
     * @param {number} no 
     * @param {AttachInvoiceRequestDto} attachInvoiceRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public attachInvoiceToRepayment(id: string, planId: string, no: number, attachInvoiceRequestDto: AttachInvoiceRequestDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).attachInvoiceToRepayment(id, planId, no, attachInvoiceRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public backOrderCards(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).backOrderCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public backofficeRoleChecker(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).backofficeRoleChecker(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public billingSettings(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).billingSettings(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PersonLoginRequest} personLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public bizOpsLogin(personLoginRequest: PersonLoginRequest, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).bizOpsLogin(personLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public bookRepaymentInvoice(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).bookRepaymentInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} bureauId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public bureau(bureauId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).bureau(bureauId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} bureauId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public bureauClients(bureauId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).bureauClients(bureauId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} bureauId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public bureauPendingTasks(bureauId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).bureauPendingTasks(bureauId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} mandateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public cancelDirectDebitMandateV3(customerId: string, mandateId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).cancelDirectDebitMandateV3(customerId, mandateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} mandateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public cancelDirectDebitMandateV4(customerId: string, mandateId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).cancelDirectDebitMandateV4(customerId, mandateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public cancelRepaymentInvoice(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).cancelRepaymentInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public cardDeliveryAddress(customerId: string, personId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).cardDeliveryAddress(customerId, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public cards(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).cards(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public client(clientId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).client(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public closeCredit(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).closeCredit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {BureauConfigRequest} bureauConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public configure(bureauConfigRequest: BureauConfigRequest, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).configure(bureauConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {AccountDto} accountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createAccount(accountDto: AccountDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createAccount(accountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Create a new Agreement with status draft
     * @param {string} customerId 
     * @param {CreateAgreementV6Request} createAgreementV6Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createAgreementV6(customerId: string, createAgreementV6Request: CreateAgreementV6Request, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createAgreementV6(customerId, createAgreementV6Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary create a new customer entity
     * @param {BackOfficeCustomerCreateDtoV2} backOfficeCustomerCreateDtoV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createCustomer(backOfficeCustomerCreateDtoV2: BackOfficeCustomerCreateDtoV2, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createCustomer(backOfficeCustomerCreateDtoV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {CreateDirectDebitMandateV3Request} createDirectDebitMandateV3Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createDirectDebitMandateV3(customerId: string, createDirectDebitMandateV3Request: CreateDirectDebitMandateV3Request, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createDirectDebitMandateV3(customerId, createDirectDebitMandateV3Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {CreateDirectDebitMandateV4Request} createDirectDebitMandateV4Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createDirectDebitMandateV4(customerId: string, createDirectDebitMandateV4Request: CreateDirectDebitMandateV4Request, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createDirectDebitMandateV4(customerId, createDirectDebitMandateV4Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createDraftRepaymentInvoice(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createDraftRepaymentInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {CreateInvoiceV2Request} createInvoiceV2Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createInvoiceV2(createInvoiceV2Request: CreateInvoiceV2Request, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createInvoiceV2(createInvoiceV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {BackOfficeCreatePaymentForCustomerRequestDto} backOfficeCreatePaymentForCustomerRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createPayment(backOfficeCreatePaymentForCustomerRequestDto: BackOfficeCreatePaymentForCustomerRequestDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createPayment(backOfficeCreatePaymentForCustomerRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {CreatePersonDto} createPersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createPersonWithRoles(id: string, createPersonDto: CreatePersonDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createPersonWithRoles(id, createPersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {string} planId 
     * @param {RepaymentUpdateDto} repaymentUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createRepayment(id: string, planId: string, repaymentUpdateDto: RepaymentUpdateDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createRepayment(id, planId, repaymentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Create new signatories data
     * @param {string} customerId 
     * @param {Array<BackOfficeSignatoryDto>} backOfficeSignatoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createSignatories(customerId: string, backOfficeSignatoryDto: Array<BackOfficeSignatoryDto>, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createSignatories(customerId, backOfficeSignatoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {CreatePaymentRequestDtoV2} createPaymentRequestDtoV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createV2(createPaymentRequestDtoV2: CreatePaymentRequestDtoV2, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createV2(createPaymentRequestDtoV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {VoucherDto} voucherDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createVoucher(voucherDto: VoucherDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createVoucher(voucherDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {AccountXYear} accountXYear 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public createYear(accountXYear: AccountXYear, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).createYear(accountXYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public creditRepaymentInvoice(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).creditRepaymentInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public debtCollectRepaymentInvoice(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).debtCollectRepaymentInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public delete1(customerId: string, id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).delete1(customerId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deleteAccount(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deleteAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deleteCreditOverride(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deleteCreditOverride(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deleteCustomer(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deleteCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsFeatureName} featureName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deleteFeatureOverride(customerId: string, featureName: BillingSettingsFeatureName, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deleteFeatureOverride(customerId, featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deleteFile(customerId: string, id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deleteFile(customerId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsFieldName} fieldName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deleteOverrides(customerId: string, fieldName: BillingSettingsFieldName, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deleteOverrides(customerId, fieldName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} paymentWhiteListId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deletePaymentWhiteListV1(customerId: string, paymentWhiteListId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deletePaymentWhiteListV1(customerId, paymentWhiteListId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deletePerson(id: string, personId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deletePerson(id, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deleteSnooze(itemId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deleteSnooze(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public deleteYear(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).deleteYear(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public directLoginToken(id: string, personId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).directLoginToken(id, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {string} planId 
     * @param {number} no 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public dropInvoiceFromRepayment(id: string, planId: string, no: number, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).dropInvoiceFromRepayment(id, planId, no, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public fetchRepaymentPlan(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).fetchRepaymentPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public findBy(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).findBy(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @summary fetch a customer entity by id
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public findCustomerById(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).findCustomerById(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary fetch all customers, or search by orgno (should only return one entry)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public findCustomers(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).findCustomers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public findEInvoiceAddresses(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).findEInvoiceAddresses(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public forbiddenException(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).forbiddenException(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public generalException(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).generalException(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [onDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public generateCsvReport(onDate?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).generateCsvReport(onDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public generateKycReport(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).generateKycReport(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public get(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public get1(customerId: string, id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).get1(customerId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAccount(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAccount(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAccount3(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAccount3(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Get an accounts balance, available, and creditlimit. This requires a downstream call to enfuce.
     * @param {string} customerId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAccountStatusV5(customerId: string, accountId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAccountStatusV5(customerId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAccountV2(customerId: string, accountId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAccountV2(customerId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Get an account on customer
     * @param {string} customerId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAccountV5(customerId: string, accountId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAccountV5(customerId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAccounts(year?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAccounts(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAccountsV2(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAccountsV2(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Get a list of all accounts on customer
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAccountsV5(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAccountsV5(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getActivationGuide(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getActivationGuide(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getActivationGuideVisibility(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getActivationGuideVisibility(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Get agreement details for given agreement-id
     * @param {string} customerId 
     * @param {string} agreementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAgreementV6(customerId: string, agreementId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAgreementV6(customerId, agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Get a list of all agreements on customer
     * @param {string} customerId 
     * @param {Array<BackOfficeAgreementTypeV6>} [agreementType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAllAgreementsV6(customerId: string, agreementType?: Array<BackOfficeAgreementTypeV6>, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAllAgreementsV6(customerId, agreementType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAllContexts(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAllContexts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAllMonthlyReports(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAllMonthlyReports(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} creditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAllRepaymentInvoices(creditId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAllRepaymentInvoices(creditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAllRepaymentModels(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAllRepaymentModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<string>} [years] 
     * @param {Array<number>} [accounts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getAllVouchers(from?: string, to?: string, years?: Array<string>, accounts?: Array<number>, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getAllVouchers(from, to, years, accounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @summary Offset, start transaction list at this offset. Limit is how many transactions to return. transactionDate do not have a timezone
     * @param {string} customerId 
     * @param {number} [offset] pagination offset
     * @param {number} [limit] pagination limit
     * @param {string} [from] from date (inclusive) in ISO format, yyyy-MM-dd
     * @param {string} [to] to date (inclusive) in ISO format, yyyy-MM-dd
     * @param {number} [min] min transaction amount (inclusive)
     * @param {number} [max] max transaction amount (inclusive)
     * @param {Array<TransactionFilters>} [onlyinclude] various transaction property filters, some are mutually exclusive
     * @param {Array<TransactionFilters>} [exclude] 
     * @param {Array<AttestStatus>} [attestStatus] 
     * @param {string} [query] Free text query search. At least 3 characters, otherwise ignored
     * @param {Array<string>} [personIds] \&#39;or-ed\&#39; person id\&#39;s to query for, if omitted then transactions for all users will be returned. note: returned set increases with with more personIds and decreases with less personId, except for the empty set which returns all transactions
     * @param {Array<string>} [cardIds] 
     * @param {Array<BackOfficeTx2StatusFilter2>} [status] Filter on transaction status. Leave empty to include all statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getBoTransactionsV2(customerId: string, offset?: number, limit?: number, from?: string, to?: string, min?: number, max?: number, onlyinclude?: Array<TransactionFilters>, exclude?: Array<TransactionFilters>, attestStatus?: Array<AttestStatus>, query?: string, personIds?: Array<string>, cardIds?: Array<string>, status?: Array<BackOfficeTx2StatusFilter2>, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getBoTransactionsV2(customerId, offset, limit, from, to, min, max, onlyinclude, exclude, attestStatus, query, personIds, cardIds, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCard(customerId: string, cardId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCard(customerId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCardBalanceConfig(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCardBalanceConfig(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCardConfig(customerId: string, cardId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCardConfig(customerId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {string} creditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCardCreditV2(customerId: string, creditId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCardCreditV2(customerId, creditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCardCreditsV2(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCardCreditsV2(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCardPlastics(customerId: string, cardId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCardPlastics(customerId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCards(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCards(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCompanyCredit(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCompanyCredit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCorporateCustomer(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCorporateCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCredit(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCredit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary returns 204 if no override is found
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCreditOverride(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCreditOverride(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getCreditReport(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getCreditReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getDefaultFeesAndInterestsV2(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getDefaultFeesAndInterestsV2(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getDefaultPenaltyFeeV2(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getDefaultPenaltyFeeV2(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getDirectDebitMandatesV3(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getDirectDebitMandatesV3(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getDirectDebitMandatesV4(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getDirectDebitMandatesV4(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} uuid 
     * @param {string} [filename] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getDocumentUrl(uuid: string, filename?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getDocumentUrl(uuid, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getDwhTextFile(filename: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getDwhTextFile(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {Array<string>} [contexts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getFiles(customerId: string, contexts?: Array<string>, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getFiles(customerId, contexts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [types] Filter for specific types. Accepts a comma separated list. If omitted, all types are returned
     * @param {string} [snoozed] should snoozed items be included or not
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getInbox(types?: string, snoozed?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getInbox(types, snoozed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getInvoiceV2(invoiceId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getInvoiceV2(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [customerId] 
     * @param {Array<InvoiceType>} [type] 
     * @param {Array<InvoiceState>} [state] 
     * @param {string} [invoiceDateFrom] invoice date from including
     * @param {string} [invoiceDateTo] invoice date to including
     * @param {string} [settlementDateFrom] settlement date from including
     * @param {string} [settlementDateTo] settlement date to including
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getInvoicesV2(customerId?: string, type?: Array<InvoiceType>, state?: Array<InvoiceState>, invoiceDateFrom?: string, invoiceDateTo?: string, settlementDateFrom?: string, settlementDateTo?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getInvoicesV2(customerId, type, state, invoiceDateFrom, invoiceDateTo, settlementDateFrom, settlementDateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getInvoicingConfigV1(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getInvoicingConfigV1(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} ssn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getKycForPerson(customerId: string, ssn: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getKycForPerson(customerId, ssn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getKycList(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getKycList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPartners(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPartners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPayment(paymentId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPayment(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPaymentReceiverV1(paymentId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPaymentReceiverV1(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPaymentRequestV2(paymentRequestId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPaymentRequestV2(paymentRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [customerId] 
     * @param {string} [cardCreditId] 
     * @param {Array<string>} [currency] 
     * @param {boolean} [hasPaidEnough] 
     * @param {Array<PaymentRequestStatusDtoV2>} [status] 
     * @param {string} [dueDateFrom] 
     * @param {string} [dueDateTo] 
     * @param {string} [paymentRequestDateFrom] 
     * @param {string} [paymentRequestDateTo] 
     * @param {string} [periodFrom] 
     * @param {string} [periodTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPaymentRequestsV2(customerId?: string, cardCreditId?: string, currency?: Array<string>, hasPaidEnough?: boolean, status?: Array<PaymentRequestStatusDtoV2>, dueDateFrom?: string, dueDateTo?: string, paymentRequestDateFrom?: string, paymentRequestDateTo?: string, periodFrom?: string, periodTo?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPaymentRequestsV2(customerId, cardCreditId, currency, hasPaidEnough, status, dueDateFrom, dueDateTo, paymentRequestDateFrom, paymentRequestDateTo, periodFrom, periodTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPaymentWhiteListV1(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPaymentWhiteListV1(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {Array<PaymentState>} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPayments(state?: Array<PaymentState>, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPayments(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPdfV2(paymentRequestId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPdfV2(paymentRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPdfV21(invoiceId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPdfV21(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPeople(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPeople(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPersonsWithRoles(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPersonsWithRoles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPreSignedUrl(customerId: string, id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPreSignedUrl(customerId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPreferredDirectDebitMandateV3(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPreferredDirectDebitMandateV3(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPreferredDirectDebitMandateV4(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPreferredDirectDebitMandateV4(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getPrivateCustomers(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getPrivateCustomers(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {QuoteUrlRequestDto} quoteUrlRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getQuoteUrl(id: string, quoteUrlRequestDto: QuoteUrlRequestDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getQuoteUrl(id, quoteUrlRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getRawAccountV3(customerId: string, accountId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getRawAccountV3(customerId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getRawAccountV4(customerId: string, accountId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getRawAccountV4(customerId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getRawAccountsV3(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getRawAccountsV3(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getRawAccountsV4(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getRawAccountsV4(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getReminderPdfV2(invoiceId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getReminderPdfV2(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getRepaymentInvoice(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getRepaymentInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getRepaymentInvoiceSummary(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getRepaymentInvoiceSummary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getRepaymentInvoicesForCompanyCredit(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getRepaymentInvoicesForCompanyCredit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getReportTextFile(filename: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getReportTextFile(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getRisk(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getRisk(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Get signatories data
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getSignatories(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getSignatories(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {Array<string>} [dates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getSnapshots(dates?: Array<string>, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getSnapshots(dates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getTransaction(customerId: string, txId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getTransaction(customerId, txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @summary offset takes precedence over page if provided. Page is still available for backward compatibility. limit defaults to 20
     * @param {string} customerId 
     * @param {number} [page] 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getTransactionsCombined(customerId: string, page?: number, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getTransactionsCombined(customerId, page, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<string>} [years] 
     * @param {Array<number>} [accounts] 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getVouchersResultReport(from?: string, to?: string, years?: Array<string>, accounts?: Array<number>, currency?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getVouchersResultReport(from, to, years, accounts, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public getYears(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).getYears(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} callback 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public googleOAuthRedir(callback: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).googleOAuthRedir(callback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public illegalArgumentException(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).illegalArgumentException(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public illegalStateException(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).illegalStateException(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public isLoggedIn(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).isLoggedIn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public kycApproved(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).kycApproved(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BackOfficeCustomerKycRequiresRenewalDtoV2} backOfficeCustomerKycRequiresRenewalDtoV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public kycRequiresRenewal(customerId: string, backOfficeCustomerKycRequiresRenewalDtoV2: BackOfficeCustomerKycRequiresRenewalDtoV2, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).kycRequiresRenewal(customerId, backOfficeCustomerKycRequiresRenewalDtoV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public list(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public log(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).log(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public logout(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [path] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public ls(path?: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).ls(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public markCreditAsDefault(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).markCreditAsDefault(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public markCreditAsNetted(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).markCreditAsNetted(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public markCreditAsRepaid(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).markCreditAsRepaid(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public notFoundException(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).notFoundException(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} creditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public notifyCardCreditDenied(customerId: string, creditId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).notifyCardCreditDenied(customerId, creditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public notifyCustomerV2(paymentRequestId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).notifyCustomerV2(paymentRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BackOfficeOrderCardRequestDto} backOfficeOrderCardRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public orderCard(customerId: string, backOfficeOrderCardRequestDto: BackOfficeOrderCardRequestDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).orderCard(customerId, backOfficeOrderCardRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BackOfficeOrderCardRequestDtoV3} backOfficeOrderCardRequestDtoV3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public orderCardV3(customerId: string, backOfficeOrderCardRequestDtoV3: BackOfficeOrderCardRequestDtoV3, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).orderCardV3(customerId, backOfficeOrderCardRequestDtoV3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {FileType} type 
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public parseDwhFull(type: FileType, filename: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).parseDwhFull(type, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {PatchPrepaidResourceBody} patchPrepaidResourceBody 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchAccount(customerId: string, patchPrepaidResourceBody: PatchPrepaidResourceBody, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchAccount(customerId, patchPrepaidResourceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} accountId 
     * @param {BackOfficeAccountPatchDto} backOfficeAccountPatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchAccountV2(customerId: string, accountId: string, backOfficeAccountPatchDto: BackOfficeAccountPatchDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchAccountV2(customerId, accountId, backOfficeAccountPatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {ActivationGuidePatchDto} activationGuidePatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchActivationGuideVisibility(customerId: string, activationGuidePatchDto: ActivationGuidePatchDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchActivationGuideVisibility(customerId, activationGuidePatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {ActivationGuidePatchDto} activationGuidePatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchActivationGuideVisibility1(customerId: string, activationGuidePatchDto: ActivationGuidePatchDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchActivationGuideVisibility1(customerId, activationGuidePatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {PatchStandardResourceBody} patchStandardResourceBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchCard(customerId: string, cardId: string, patchStandardResourceBody: PatchStandardResourceBody, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchCard(customerId, cardId, patchStandardResourceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {CardBalanceConfigPatchDto} cardBalanceConfigPatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchCardBalanceConfig(customerId: string, cardBalanceConfigPatchDto: CardBalanceConfigPatchDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchCardBalanceConfig(customerId, cardBalanceConfigPatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchCardConfig(customerId: string, cardId: string, body: object, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchCardConfig(customerId, cardId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} creditId 
     * @param {BackOfficeCardCreditPatchV2Dto} backOfficeCardCreditPatchV2Dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchCardCreditV2(customerId: string, creditId: string, backOfficeCardCreditPatchV2Dto: BackOfficeCardCreditPatchV2Dto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchCardCreditV2(customerId, creditId, backOfficeCardCreditPatchV2Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {BackOfficeCardPatchDto} backOfficeCardPatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchCardV1(customerId: string, cardId: string, backOfficeCardPatchDto: BackOfficeCardPatchDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchCardV1(customerId, cardId, backOfficeCardPatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRoleOrSalesRoleAndAllowed(#patch.changesOnlyProducts())
     * @param {string} customerId 
     * @param {BackOfficeCustomerPatchDtoV2} backOfficeCustomerPatchDtoV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchCustomer(customerId: string, backOfficeCustomerPatchDtoV2: BackOfficeCustomerPatchDtoV2, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchCustomer(customerId, backOfficeCustomerPatchDtoV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsFeatureOverridePatch} billingSettingsFeatureOverridePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchFeatureOverrides(customerId: string, billingSettingsFeatureOverridePatch: BillingSettingsFeatureOverridePatch, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchFeatureOverrides(customerId, billingSettingsFeatureOverridePatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BackofficeInvoicingConfigPatchDto} backofficeInvoicingConfigPatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchInvoicingConfigV1(customerId: string, backofficeInvoicingConfigPatchDto: BackofficeInvoicingConfigPatchDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchInvoicingConfigV1(customerId, backofficeInvoicingConfigPatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsOverridePatch} billingSettingsOverridePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchOverrides(customerId: string, billingSettingsOverridePatch: BillingSettingsOverridePatch, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchOverrides(customerId, billingSettingsOverridePatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentId 
     * @param {BackOfficePatchPaymentRequestDto} backOfficePatchPaymentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchPayment(paymentId: string, backOfficePatchPaymentRequestDto: BackOfficePatchPaymentRequestDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchPayment(paymentId, backOfficePatchPaymentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentRequestId 
     * @param {PatchPaymentRequestDtoV2} patchPaymentRequestDtoV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchPaymentRequestV2(paymentRequestId: string, patchPaymentRequestDtoV2: PatchPaymentRequestDtoV2, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchPaymentRequestV2(paymentRequestId, patchPaymentRequestDtoV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} accountId 
     * @param {PatchV3ResourceBody} patchV3ResourceBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchRawAccountV3(customerId: string, accountId: string, patchV3ResourceBody: PatchV3ResourceBody, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchRawAccountV3(customerId, accountId, patchV3ResourceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} accountId 
     * @param {PatchPrepaidResourceBody} patchPrepaidResourceBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchRawAccountV4(customerId: string, accountId: string, patchPrepaidResourceBody: PatchPrepaidResourceBody, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchRawAccountV4(customerId, accountId, patchPrepaidResourceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsTrialOverridePatch} billingSettingsTrialOverridePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public patchTrialOverrides(customerId: string, billingSettingsTrialOverridePatch: BillingSettingsTrialOverridePatch, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).patchTrialOverrides(customerId, billingSettingsTrialOverridePatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {Amount} amount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public payoutCompanyCredit(id: string, amount: Amount, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).payoutCompanyCredit(id, amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} id 
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public person(id: string, personId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).person(id, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} id 
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public person1(id: string, personId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).person1(id, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {BoCompanyCreditRequestV2} boCompanyCreditRequestV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public postNewCompanyCreditRequestV2(boCompanyCreditRequestV2: BoCompanyCreditRequestV2, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).postNewCompanyCreditRequestV2(boCompanyCreditRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public postRecalculateV2(paymentRequestId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).postRecalculateV2(paymentRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public put(id: string, body: object, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).put(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public put1(customerId: string, id: string, body: object, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).put1(customerId, id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {CreditOverrideDto} creditOverrideDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public putCreditOverride(customerId: string, creditOverrideDto: CreditOverrideDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).putCreditOverride(customerId, creditOverrideDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {KycRisk} kycRisk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public putRisk(customerId: string, kycRisk: KycRisk, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).putRisk(customerId, kycRisk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {string} planId 
     * @param {number} no 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public reCreateRepaymentInvoice(id: string, planId: string, no: number, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).reCreateRepaymentInvoice(id, planId, no, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {string} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public rebuildInvoicesAndRepaymentPlanFromFortnox(id: string, planId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).rebuildInvoicesAndRepaymentPlanFromFortnox(id, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public refreshAddress(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).refreshAddress(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public regenerateAgreementDocument(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).regenerateAgreementDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public regenerateRepaymentPlan(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).regenerateRepaymentPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public reissueCard(customerId: string, cardId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).reissueCard(customerId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {Amount} amount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public remindRepaymentInvoice(id: string, amount: Amount, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).remindRepaymentInvoice(id, amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} mandateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public resendDirectDebitMandateV3(customerId: string, mandateId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).resendDirectDebitMandateV3(customerId, mandateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} mandateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public resendDirectDebitMandateV4(customerId: string, mandateId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).resendDirectDebitMandateV4(customerId, mandateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {ResetRequest} resetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public resetBureau(resetRequest: ResetRequest, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).resetBureau(resetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public resetPinAttempt(customerId: string, cardId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).resetPinAttempt(customerId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasRole(\'BACKOFFICE_SALES\')
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public salesRoleChecker(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).salesRoleChecker(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} q 
     * @param {number} [n] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public search(q: string, n?: number, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).search(q, n, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public searchTypes(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).searchTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {MonthlyReportRequest} monthlyReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public sendMonthlyReport(customerId: string, monthlyReportRequest: MonthlyReportRequest, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).sendMonthlyReport(customerId, monthlyReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public sendRenewalEmail(customerId: string, body: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).sendRenewalEmail(customerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} cid 
     * @param {string} pid 
     * @param {SmsRequestDto} smsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public sendSms(cid: string, pid: string, smsRequestDto: SmsRequestDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).sendSms(cid, pid, smsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public sendWelcomeEmail(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).sendWelcomeEmail(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public setStandardTermsCustomerCredit(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).setStandardTermsCustomerCredit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public syncCard(customerId: string, cardId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).syncCard(customerId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public syncCompanyCreditWithFortnox(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).syncCompanyCreditWithFortnox(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} mandateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public syncDirectDebitMandateV3(customerId: string, mandateId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).syncDirectDebitMandateV3(customerId, mandateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} mandateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public syncDirectDebitMandateV4(customerId: string, mandateId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).syncDirectDebitMandateV4(customerId, mandateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} id 
     * @param {UploadRequest} uploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public syncFile(customerId: string, id: string, uploadRequest: UploadRequest, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).syncFile(customerId, id, uploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public syncWithFortnox(id: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).syncWithFortnox(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @summary Get a list of transaction conditions from Enfuce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public transactionConditions(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).transactionConditions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public triggerBusinessEvent(customerId: string, txId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).triggerBusinessEvent(customerId, txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public triggerBusinessEventV2(customerId: string, txId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).triggerBusinessEventV2(customerId, txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public triggerDwhJob(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).triggerDwhJob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {BureauUnassingmentRequest} bureauUnassingmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public unassign(bureauUnassingmentRequest: BureauUnassingmentRequest, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).unassign(bureauUnassingmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public unauthorizedException(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).unauthorizedException(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {AccountDto} accountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public updateAccount(id: string, accountDto: AccountDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).updateAccount(id, accountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public updateCardGroupsForAllCards(options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).updateCardGroupsForAllCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {CompanyCreditDto} companyCreditDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public updateCompanyCredit(id: string, companyCreditDto: CompanyCreditDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).updateCompanyCredit(id, companyCreditDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} invoiceId 
     * @param {BackOfficeInvoicePatchDto2} backOfficeInvoicePatchDto2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public updateInvoiceV2(invoiceId: string, backOfficeInvoicePatchDto2: BackOfficeInvoicePatchDto2, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).updateInvoiceV2(invoiceId, backOfficeInvoicePatchDto2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public updatePeople(customerId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).updatePeople(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRoleOrSalesRoleAndAllowed(#patch.onlyInvite())
     * @param {string} id 
     * @param {string} personId 
     * @param {PersonPatchDto} personPatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public updatePerson(id: string, personId: string, personPatchDto: PersonPatchDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).updatePerson(id, personId, personPatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {string} planId 
     * @param {number} no 
     * @param {RepaymentUpdateDto} repaymentUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public updateRepayment(id: string, planId: string, no: number, repaymentUpdateDto: RepaymentUpdateDto, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).updateRepayment(id, planId, no, repaymentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} id 
     * @param {AccountXYear} accountXYear 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public updateYear(id: string, accountXYear: AccountXYear, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).updateYear(id, accountXYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackOfficeAPIApi
     */
    public whiteListPaymentV1(paymentId: string, options?: AxiosRequestConfig) {
        return BackOfficeAPIApiFp(this.configuration).whiteListPaymentV1(paymentId, options).then((request) => request(this.axios, this.basePath));
    }
}
