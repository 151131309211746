import Box from '@mui/material/Box'
import { Amount, BackOfficeAccountStatusV5 } from '../../api/swagger/definitions/backoffice'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { BorderContainer, BorderContainerHeader } from 'helpers/CreditOnCardHelpers'
import Typography from '@mui/material/Typography'
import { Accounts } from '../../api/cards/types.ts'

export const SelectAccount = ({
  selectedAccountInfo,
  accountOptions,
  accountBalance,
  selectedAccount,
  setSelectedAccount,
  formatAmount
}: {
  accountOptions: {
    label: string
    value: string
  }[]
  selectedAccountInfo: Accounts | undefined
  accountBalance: BackOfficeAccountStatusV5 | undefined
  selectedAccount: string | undefined
  setSelectedAccount: (value: string) => void
  formatAmount: (amountDto: Amount, style?: 'currency' | 'decimal' | undefined) => string
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }}
  >
    <BorderContainer fullWidth>
      <BorderContainerHeader noBorder>
        <Typography variant={'h2'}>Account</Typography>
      </BorderContainerHeader>
      <Autocomplete
        disablePortal
        options={accountOptions}
        sx={{ width: 300 }}
        onChange={(_, value) => {
          if (value && value.value) {
            setSelectedAccount(value.value)
          } else {
            setSelectedAccount('')
          }
        }}
        value={accountOptions.find((option) => option.value === selectedAccount)}
        renderInput={(params) => <TextField {...params} label="Select account" />}
      />
      <Box
        sx={{
          display: 'flex',
          mt: 3,
          gap: 2
        }}
      >
        <AccountInfo label="Settled balance" amount={accountBalance?.balance} formatAmount={formatAmount} />
        <AccountInfo label="Available to spend" amount={accountBalance?.available} formatAmount={formatAmount} />
        <AccountInfo label="Credit limit" amount={accountBalance?.creditLimit} formatAmount={formatAmount} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mt: 4
        }}
      >
        <Row title={'Enfuce id'} value={selectedAccountInfo?.externalId} />
        <Row title={'Mynt internal id'} value={selectedAccountInfo?.id} />
      </Box>
    </BorderContainer>
  </Box>
)

const Row = ({ title, value }: { title: string; value: string | number | undefined }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      gap: 1
    }}
  >
    <Typography
      sx={{
        flex: 2
      }}
      variant={'subtitle1'}
      fontWeight={'bold'}
    >
      {title}:
    </Typography>
    <Typography
      sx={{
        flex: 3
      }}
      variant={'subtitle1'}
    >
      {value}
    </Typography>
  </Box>
)

export const AccountInfo = ({
  label,
  amount,
  formatAmount
}: {
  label: string
  amount: Amount | undefined
  formatAmount: (amountDto: Amount, style?: 'currency' | 'decimal' | undefined) => string
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid rgba(216, 216, 218, 1)',
      padding: 1.5,
      borderRadius: 2,
      flex: 1
    }}
  >
    <Typography variant={'body2'}>{label}:</Typography>
    {amount ? (
      <Typography variant={'subtitle1'} fontWeight={'bold'}>
        {formatAmount(amount)}
      </Typography>
    ) : (
      <Typography variant={'subtitle1'} fontWeight={'bold'}>
        -
      </Typography>
    )}
  </Box>
)
