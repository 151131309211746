import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import TextField from '@mui/material/TextField'
import { DataGridPro, GridApiPro, GridFilterModel, GridFilterOperator } from '@mui/x-data-grid-pro'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'
import { InvoiceState, InvoiceType } from '../../api/swagger/definitions/backoffice'
import { prettifyEnumLabel } from '../../helpers/CreditOnCardHelpers'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import { YYYY_MM_DD } from '../../tiger/Constants'
import InvoiceDetails from './InvoiceDetails'
import Spacings from '../../figma/tokens/Spacings'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import Stack from '@mui/material/Stack'
import get from 'lodash/get'

type Invoice = {
  id: string
  no: number
  creditNo?: number
  state: string
  invoiceDate: string
  dueDate: string
  settleDate?: string
  type: string
  grossTotal: {
    value: number
    currency: string
  }
  remaining: {
    value: number
    currency: string
  }
  receiverName: string
  accountingMethod: string
}

export type InvoiceTableProps<T extends Invoice[]> = {
  invoices: T
  filters: {
    state: InvoiceState[]
    type: InvoiceType[]
    invoiceDate: { from?: Dayjs; to?: Dayjs }
    settleDate: { from?: Dayjs; to?: Dayjs }
  }
  onFilterApiChange: (filters: {
    state: InvoiceState[]
    type: InvoiceType[]
    invoiceDate: { from?: Dayjs; to?: Dayjs }
    settleDate: { from?: Dayjs; to?: Dayjs }
  }) => void
  onClientFilterInvoiceChange?: (field: string, search: string) => void
  isLoading?: boolean
  actions: (invoice, onClose?: () => unknown) => React.ReactNode
  formatAmount?: (amount: { value: number; currency: string }) => React.ReactNode | string
  page: 'ACCOUNTING' | 'CUSTOMER'
}

const noop = () => null

const createSelectOperator = (label: string, options: Record<string, string>, operator: string): GridFilterOperator => ({
  label: label,
  value: operator,
  getApplyFilterFn: noop, // noop when using server filters
  InputComponent: ({ applyValue, ...params }) => (
    <Box sx={{ width: '100%', paddingTop: 2 }}>
      <Autocomplete
        onChange={(event, value) => {
          applyValue({ ...params.item, value })
        }}
        value={params.item.value}
        multiple
        fullWidth
        options={Object.values(options)}
        getOptionLabel={(option) => prettifyEnumLabel(option)}
        renderTags={(values) => <span>{values.length} selected</span>}
        renderInput={(props) => <TextField variant="standard" {...props} label={params.label} />}
      />
    </Box>
  ),
  headerLabel: label
})

const applyUpdatedFilters = ({ filters, onClientFilterInvoiceChange, model }) =>
  model.items.reduce((acc, item) => {
    switch (item.field) {
      case 'state': {
        return { ...acc, state: item.value as InvoiceState[] }
      }

      case 'type': {
        return { ...acc, type: item.value as InvoiceType[] }
      }

      case 'invoiceDate': {
        const [from, to] = item.value as [Dayjs, Dayjs]

        return { ...acc, invoiceDate: { from, to } }
      }

      case 'settleDate': {
        const [from, to] = item.value as [Dayjs, Dayjs]

        return { ...acc, settleDate: { from, to } }
      }

      default: {
        onClientFilterInvoiceChange(item.field, item.value.toString())

        return filters
      }
    }
  }, filters)

// Since we are using server filters, we need to apply our own client side filters on select columns
const applyQuickFilter = <T extends Invoice[]>(filters: Record<string, string>, rows: T) => {
  if (Object.keys(filters).length === 0) return rows

  return rows.filter((row) =>
    Object.entries(filters).every(([field, value]) => {
      if (!value) return true

      const rowValue = get(row, field)?.toString().toLowerCase()

      if (typeof rowValue === 'string') return rowValue.includes(value.toLowerCase())

      return false
    })
  )
}

export const InvoiceTable = <T extends Invoice[]>({
  invoices,
  filters,
  onFilterApiChange,
  onClientFilterInvoiceChange: _onClientFilterInvoiceChange,
  isLoading,
  actions,
  formatAmount,
  page
}: InvoiceTableProps<T>) => {
  const apiRef = useRef<GridApiPro>({} as GridApiPro)
  const [quickFilter, setQuickFilter] = useState<Record<string, string>>({})

  const onClientFilterInvoiceChange = (field, value) => {
    if (_onClientFilterInvoiceChange) return _onClientFilterInvoiceChange(field, value)

    setQuickFilter((prev) => ({ ...prev, [field]: value }))
  }

  const handleFilterChange = (model: GridFilterModel, details) => {
    if (!details.reason) return

    const updatedFilters = applyUpdatedFilters({ filters, model, onClientFilterInvoiceChange })

    onFilterApiChange(updatedFilters)
  }

  const rows = applyQuickFilter(quickFilter, invoices)
  const showMinHeight = rows.length === 0 || isLoading

  return (
    <DataGridPro
      // the overlayWrapperInner height is not correct when using the header filters
      sx={{ height: showMinHeight ? 300 : 'auto', '.css-1akuw9y-MuiDataGrid-overlayWrapperInner': { height: '89px !important' } }}
      pagination
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
        sorting: { sortModel: [{ field: 'invoiceDate', sort: 'desc' }] }
      }}
      pageSizeOptions={[10, 25, 50, 100]}
      filterMode="server"
      getRowHeight={() => 'auto'}
      onFilterModelChange={handleFilterChange}
      getDetailPanelContent={({ row }) => (
        <FigmaBox fullWidth fullPadding spacing={Spacings.large}>
          <InvoiceDetails invoice={row} />
        </FigmaBox>
      )}
      getDetailPanelHeight={() => 'auto'}
      filterModel={{
        items: [
          {
            id: 1,
            field: 'state',
            operator: 'customAnyOf',
            value: filters.state
          },
          {
            id: 2,
            field: 'type',
            operator: 'customType',
            value: filters.type
          },
          {
            id: 3,
            field: 'invoiceDate',
            operator: 'customDateRange',
            value: [
              filters.invoiceDate.from ? dayjs(filters.invoiceDate.from) : null,
              filters.invoiceDate.to ? dayjs(filters.invoiceDate.to) : null
            ]
          },
          {
            id: 4,
            field: 'settleDate',
            operator: 'customDateRange',
            value: [
              filters.settleDate.from ? dayjs(filters.settleDate.from) : null,
              filters.settleDate.to ? dayjs(filters.settleDate.to) : null
            ]
          }
        ]
      }}
      loading={isLoading}
      apiRef={apiRef}
      unstable_headerFilters
      disableColumnFilter
      slots={{
        headerFilterMenu: null,
        loadingOverlay: LinearProgress,
        noRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No invoices found 😔
          </Stack>
        )
      }}
      rows={rows}
      columnHeaderHeight={64}
      columns={[
        {
          field: 'no',
          headerName: 'Invoice #'
        },
        {
          field: 'companyName',
          headerName: 'Customer',
          width: 200
        },
        {
          field: 'receiverName',
          headerName: 'Receiver',
          width: 200
        },
        {
          field: 'state',
          headerName: 'State',
          minWidth: 150,
          filterOperators: [createSelectOperator('Any of', InvoiceState, 'customAnyOf')],
          valueFormatter: ({ value }) => prettifyEnumLabel(value)
        },
        {
          field: 'grossTotal.value',
          headerName: 'Gross total',
          valueGetter: ({ row }) => row.grossTotal.value,
          renderCell: ({ row }) => formatAmount?.(row.grossTotal) ?? row.grossTotal.value,
          width: 150
        },
        {
          field: 'invoiceDate',
          headerName: 'Invoice date',
          type: 'date',
          valueFormatter: ({ value }) => dayjs(value).format(YYYY_MM_DD),
          valueGetter: ({ value }) => dayjs(value).toDate(),
          filterOperators: [
            {
              label: 'Date range',
              value: 'customDateRange',
              getApplyFilterFn: noop, // noop when using server filters
              InputComponent: ({ applyValue, ...params }) => (
                <FigmaBox fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      value={params.item.value}
                      format="YYYY-MM-DD"
                      label=""
                      slots={{ field: SingleInputDateRangeField }}
                      onAccept={(value) => applyValue({ ...params.item, value })}
                      slotProps={{ actionBar: { actions: ['clear'] } }}
                    />
                  </LocalizationProvider>
                </FigmaBox>
              ),
              headerLabel: 'Date range'
            }
          ],
          minWidth: 150
        },
        {
          field: 'dueDate',
          headerName: 'Due date',
          valueGetter: ({ value }) => dayjs(value).toDate(),
          valueFormatter: ({ value }) => dayjs(value).format(YYYY_MM_DD),
          minWidth: 135,
          type: 'date'
        },
        {
          field: 'settleDate',
          headerName: 'Settle date',
          type: 'date',
          valueFormatter: ({ value }) => (value ? dayjs(value).format(YYYY_MM_DD) : ''),
          filterOperators: [
            {
              label: 'Date range',
              value: 'customDateRange',
              getApplyFilterFn: noop, // noop when using server filters
              InputComponent: ({ applyValue, ...params }) => (
                <FigmaBox fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      value={params.item.value}
                      format="YYYY-MM-DD"
                      label=""
                      slots={{ field: SingleInputDateRangeField }}
                      onAccept={(value) => applyValue({ ...params.item, value })}
                      slotProps={{ actionBar: { actions: ['clear'] } }}
                    />
                  </LocalizationProvider>
                </FigmaBox>
              ),
              headerLabel: 'Date range'
            }
          ],
          minWidth: 150
        },
        {
          field: 'type',
          headerName: 'Type',
          minWidth: 250,
          filterOperators: [createSelectOperator('Any of', InvoiceType, 'customAnyOfawd')],
          valueFormatter: ({ value }) => prettifyEnumLabel(value)
        },
        {
          field: 'remaining',
          headerName: 'Remaining',
          valueGetter: ({ value: remaining }) => formatAmount?.(remaining) ?? remaining.value,
          width: 150
        },
        {
          field: 'grossTotal.currency',
          headerName: 'Currency',
          valueGetter: ({ row }) => row.grossTotal.currency
        },
        {
          field: '',
          headerName: 'Actions',
          filterable: false,
          renderCell: ({ row }) => <ActionsColumn actions={actions} invoice={row} />
        }
      ].filter((column) => {
        if (page === 'CUSTOMER') {
          if (column.field === 'companyName') return false

          if (column.field === 'grossTotal.currency') return false
        }
        return true
      })}
    />
  )
}

type ActionsColumnProps = {
  invoice: Invoice
  actions: (invoice, onClose?: () => unknown) => React.ReactNode
}

const ActionsColumn = ({ invoice, actions }: ActionsColumnProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton data-testid="more-actions" onClick={() => setOpen(true)} ref={ref}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu onClose={() => setOpen(false)} open={open} anchor={ref.current} actions={actions} invoice={invoice} />
    </>
  )
}

type ActionsMenuProps = {
  invoice: Invoice
  open: boolean
  actions: (invoice, onClose?: () => unknown) => React.ReactNode
  onClose?: () => unknown
  anchor: HTMLButtonElement | null
}

const ActionsMenu = ({ actions, invoice, open, onClose, anchor }: ActionsMenuProps) => (
  <Menu open={Boolean(open)} anchorEl={anchor} onClose={onClose}>
    <Paper sx={{ width: 325, maxWidth: '100%' }}>
      <MenuList>{actions(invoice, onClose)}</MenuList>
    </Paper>
  </Menu>
)
