import { useQueryClient } from '@tanstack/react-query'
import { FormValues } from '../../components/ModalManuallyOrderCard.tsx'
import { ModalEnablePlatinumAccount, ModalManuallyOrderCard } from '../../components/Modals.tsx'
import Spacings from 'figma/tokens/Spacings.ts'
import FigmaBox from 'mynt-components/components/FigmaBox.tsx'
import React, { useMemo, useState } from 'react'
import { CARDS, CARDS_V2, CORPORATE_CARDS, PRIVATE_CARDS } from '../../api/react-query/keys.ts'
import {
  BackOfficeAccountGetDto,
  BackOfficeCustomerDtoV2,
  CardStatus,
  CardStatusPatch,
  EnfuceAccountType
} from '../../api/swagger/definitions/backoffice'
import { useCardsV2, usePatchCardIdInPayload, usePatchCustomer } from '../../api/react-query'
import useMaterialNotification from '../../hooks/useMaterialNotification.tsx'
import MyntLoader from '../../mynt-components/components/MyntLoader.tsx'
import CustomerCardTabOverviewAccount from './CustomerCardTabOverviewAccount.tsx'
import CustomerCardTabOverviewSingleCardDetails from './CustomerCardTabOverviewSingleCardDetails.tsx'
import { Card, CardOverviewTable } from '../CardsOverview'
import { DeleteCardsModal } from '../CardsOverview/DeleteCardsModal.tsx'
import { Access, useIsLoadingWithPermission, usePermissions } from 'contexts/permissions.tsx'
import Button from '@mui/material/Button'
import { CoreMyntModal } from '../../components/modals/CoreMyntModal.tsx'
import Box from '@mui/material/Box'
import { useNotesContext } from 'contexts/Notes.tsx'
import { SelectAccount } from './SelectAccount.tsx'
import { useGetAccounts, useGetAccountStatus, useGetPersons, useOrderCardV3 } from '../../api/cards/queries.ts'
import useConfigByCountry from '../../flamingo/hooks/useConfigByCountry.ts'
import {
  AccountType,
  CardType,
  isPersonalAccount,
  OrderCardRequest,
  OrderPhysicalCardRequest,
  OrderPhysicalPersonalRequest,
  OrderVirtualCardRequest
} from '../../api/cards/types.ts'

interface CustomerCardTabOverviewProps {
  customer: BackOfficeCustomerDtoV2
  currentCardAccount?: BackOfficeAccountGetDto
  setIsChangeStatusModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomerCardTabOverview: React.FC<CustomerCardTabOverviewProps> = ({ customer, currentCardAccount, setIsChangeStatusModalOpen }) => {
  const notify = useMaterialNotification()
  const queryClient = useQueryClient()
  const { formatAmount } = useConfigByCountry()

  const [showEnablePlatinumModal, setShowEnablePlatinumModal] = useState(false)
  const [enablePlatinumLoading, setEnablePlatinumLoading] = useState(false)
  const [showOrderCardModal, setShowOrderCardModal] = useState(false)
  const [orderCardLoading, setOrderCardLoading] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState<string>()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [showDeleteCardsModal, setShowDeleteCardsModal] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<string>('')
  const [notesContext] = useNotesContext()

  const permissions = {
    overview: usePermissions('customers.cards.overview.view'),
    table: usePermissions('customers.cards.overview.table'),
    enfuce: usePermissions('customers.cards.overview.enfuce')
  }

  const { mutateAsync: orderCard } = useOrderCardV3(customer.id)
  const mutationEnablePlatinumAccount = usePatchCustomer(customer.id)

  const { data: accounts = [] } = useGetAccounts(customer.id)
  const { data: persons = [] } = useGetPersons(customer.id)

  const accountsWithPersons = accounts.map((account) => {
    const person = persons.find((person) => isPersonalAccount(account) && person.id === account.personId)
    return {
      ...account,
      personNameOnAccount: person ? person.fullName : accountTypeToReadable(account.accountType)
    }
  })

  const accountsWithPersonsOptions = accountsWithPersons.map((account) => ({
    label: account.personNameOnAccount,
    value: account.id
  }))

  const { data: accountBalance } = useGetAccountStatus(customer.id, selectedAccount)

  const { data: cards, isLoading: isLoadingCards } = useCardsV2(customer.id, {
    retryOnMount: false,
    enabled: permissions.overview
  })

  const selectedCard = useMemo(() => {
    if (!cards) return undefined

    return cards.find((card) => card.id === selectedCardId)
  }, [cards, selectedCardId])

  const cardMutation = usePatchCardIdInPayload(customer.id)

  const isLoading = useIsLoadingWithPermission(isLoadingCards, 'customers.cards.overview.view')

  const handleRowOpen = (card: Card) => {
    setSelectedCardId(card.id)
  }

  const handleCardSelect = (selectedCardIds: string[]) => {
    setSelectedRows(selectedCardIds)
  }

  const mappedCards = useMemo<Card[]>(() => {
    if (!cards) return []

    return cards.map((card) => ({
      id: card.id,
      externalId: card.externalId ?? '',
      number: card.lastFourDigits,
      status: card.status,
      type: card.type,
      variant: card.variant,
      personId: card.personId,
      address: card.address?.address1 || '',
      owner: card.personName === 'NON-EXISTING PERSON' ? '' : card.personName,
      singleAmountLimit: card.singleAmountLimit
    }))
  }, [cards])

  const internalIdToExternalIdMap = useMemo<Record<string, string>>(() => {
    if (!cards) return {}

    return cards?.reduce((acc, card) => ({ ...acc, [card.id]: card.externalId as string }), {})
  }, [cards])

  const handleOrderCard = (data: FormValues) => {
    const finalCardRequestData: OrderCardRequest = getOrderCardRequestFromFormValues(data)

    setOrderCardLoading(true)

    const onSuccess = () => {
      queryClient.invalidateQueries([CARDS, customer.id])
      queryClient.invalidateQueries([PRIVATE_CARDS, customer.id])
      queryClient.invalidateQueries([CORPORATE_CARDS, customer.id])
      notify(`Ordered card for ${data.fullName} at ${data.companyName}`)
    }

    const onComplete = () => {
      setOrderCardLoading(false)
      setShowOrderCardModal(false)
    }

    orderCard(finalCardRequestData).then(onSuccess).finally(onComplete)
  }

  const handleEnablePremium = () => {
    const EnablePlatinumRequestData: Partial<BackOfficeCustomerDtoV2> = {
      accountType: EnfuceAccountType.PLATINUM
    }
    setEnablePlatinumLoading(true)

    const onSuccess = () => notify(`Enable Platinum migration`)

    const onComplete = () => {
      setEnablePlatinumLoading(false)
      setShowEnablePlatinumModal(false)
    }

    mutationEnablePlatinumAccount.mutateAsync(EnablePlatinumRequestData).then(onSuccess).finally(onComplete)
  }

  const handleCopyCardUserInfo = (rows: Card[]) => {
    const formatted = rows.reduce((acc, row) => acc + `${row.owner} ${row.number}\n`, '')

    window.navigator.clipboard.writeText(formatted)
    notify(`Copied ${rows.length} users with card number to clipboard`)
  }

  if (isLoading) {
    return <MyntLoader fullWidth />
  }

  if (!currentCardAccount && permissions.enfuce) {
    return <h1>The customer does not have any card/prepaid/corporate accounts</h1>
  }

  return (
    <>
      <FigmaBox fullWidth gap={Spacings.medium} bottom={Spacings.medium}>
        <Access permissions="customers.cards.overview.enfuce">
          <Box
            sx={{
              display: 'flex',
              gap: 2
            }}
          >
            <CustomerCardTabOverviewAccount
              cardAccount={currentCardAccount as BackOfficeAccountGetDto}
              setIsChangeStatusModalOpen={setIsChangeStatusModalOpen}
            />
            <SelectAccount
              selectedAccountInfo={accounts.find((account) => account.id === selectedAccount)}
              accountOptions={accountsWithPersonsOptions}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              accountBalance={accountBalance}
              formatAmount={formatAmount}
            />
          </Box>
        </Access>
        <DeleteCardsModal
          endpoint={async (id) => cardMutation.mutateAsync({ id, status: CardStatusPatch.CARD_CLOSED })}
          cards={selectedRows.map((id) => internalIdToExternalIdMap[id])}
          open={showDeleteCardsModal}
          onClose={() => setShowDeleteCardsModal(false)}
          onDeleted={() => {
            setTimeout(() => {
              queryClient.invalidateQueries([CARDS_V2, customer.id])
              queryClient.invalidateQueries([PRIVATE_CARDS, customer.id])
            }, 1000)

            setShowDeleteCardsModal(false)
            setSelectedRows([])

            notify(`Terminated ${selectedRows.length} cards`)
          }}
        />
        <FigmaBox fullWidth>
          <FigmaBox spacing={Spacings.tiny} bottom direction="row" align="baseline" justify="space-between" fullWidth>
            <h4>Cards</h4>
            <Access permissions="customers.cards.overview.order">
              <Button onClick={() => setShowOrderCardModal(true)} variant="primary">
                Order new card
              </Button>
            </Access>
          </FigmaBox>
        </FigmaBox>
        <Box sx={{ maxWidth: notesContext.open ? '75vw' : '93vw' }}>
          <CardOverviewTable
            cards={mappedCards}
            onRowOpen={handleRowOpen}
            onUserInfoCopy={handleCopyCardUserInfo}
            onRowSelect={handleCardSelect}
            onDelete={() => {
              setShowDeleteCardsModal(true)
            }}
            selectedRows={selectedRows}
            initialFilters={[{ field: 'status', value: [CardStatus.OK] }]}
          />
        </Box>
      </FigmaBox>

      <ModalManuallyOrderCard
        loading={orderCardLoading}
        customerId={customer.id}
        visible={showOrderCardModal}
        onClose={() => setShowOrderCardModal(false)}
        onSubmit={handleOrderCard}
      />

      <ModalEnablePlatinumAccount
        visible={showEnablePlatinumModal}
        loading={enablePlatinumLoading}
        customerId={customer.id}
        onClose={() => setShowEnablePlatinumModal(false)}
        onSubmit={handleEnablePremium}
      />

      <CoreMyntModal
        title={`${selectedCard?.personName} - ${selectedCard?.lastFourDigits}`}
        open={!!selectedCardId}
        onClose={() => setSelectedCardId(undefined)}
        actions={() => null}
        wrapperProps={{ sx: { maxWidth: '1200px', width: '80vw' } }}
      >
        <>
          {selectedCard && (
            <CustomerCardTabOverviewSingleCardDetails
              card={{
                id: selectedCard.externalId as string,
                internalId: selectedCard.id,
                singleAmountLimit: selectedCard.singleAmountLimit
              }}
              customerId={customer.id}
            />
          )}
        </>
      </CoreMyntModal>
    </>
  )
}

const getOrderCardRequestFromFormValues = (data: FormValues): OrderCardRequest => {
  const cardRequestData = {
    address: {
      country: data.country,
      address1: data.address1,
      address2: data.address2,
      areaCode: data.areaCode,
      city: data.city
    },
    deliveryCompanyName: data.companyName,
    personId: data.personId,
    label: data.label ?? '',
    monthlyLimit: data.monthlyLimit,
    creditLimit: data.creditLimit
  }
  // We have to manually put monthlyLimit or creditLimit to 0 because of the type of the card even though it's not used in the form
  switch (data.type) {
    case CardType.PHYSICAL_CORPORATE:
      return {
        ...cardRequestData,
        type: CardType.PHYSICAL_CORPORATE
      } satisfies OrderPhysicalCardRequest
    case CardType.VIRTUAL_CORPORATE:
      return {
        ...cardRequestData,
        type: CardType.VIRTUAL_CORPORATE
      } satisfies OrderVirtualCardRequest
    case CardType.PHYSICAL_PERSONAL:
      return {
        ...cardRequestData,
        type: CardType.PHYSICAL_PERSONAL
      } satisfies OrderPhysicalPersonalRequest
  }
}

const accountTypeToReadable = (accountType: AccountType): string => {
  switch (accountType) {
    case 'TOP_ACCOUNT':
      return 'Top account'
    case 'PERSONAL_TOP_ACCOUNT':
      return 'Personal top account'
    case 'CORPORATE_ACCOUNT':
      return 'Corporate account'
    default:
      return 'Unknown account'
  }
}

export default CustomerCardTabOverview
