import dayjs from 'dayjs'
import { useParams } from 'react-router'
import {
  AgreementStatus,
  Amount,
  AuthenticationMethod,
  BackOfficeAgreementPersonDataV6,
  BackOfficeAgreementTypeV6,
  BackOfficeMinimumPaymentV6,
  BackOfficePaymentFrequencyV6,
  BackOfficePaymentMethodV6,
  CardCreditCompanyGuarantorV6,
  DeliveryMethod
} from '../api/swagger/definitions/backoffice'
import IconExport24LinkGreen from '../figma/images/iconExport24LinkGreen'
import Spacings from '../figma/tokens/Spacings'
import { useAllAgreementsV6 } from '../api/react-query'
import { BorderContainer } from '../helpers/CreditOnCardHelpers'
import TextKeys from '../libs/TextKeys'
import { YYYY_MM_DD } from '../mynt-components/Constants'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaTextLink from '../mynt-components/components/FigmaTextLink'
import DirectDebitTabStatusTag from './DirectDebitTabStatusTag'
import TextContainer from './TextContainer'

interface BackOfficeCardCreditAgreementResponseDto {
  id: string
  customerId: string
  agreementStatus: AgreementStatus
  sendDate?: string
  externalLink?: string
  maxLimit: Amount
  paymentFrequency: BackOfficePaymentFrequencyV6
  paymentTerm: number
  minimumPayment: BackOfficeMinimumPaymentV6
  interestRate: number
  fixedFee: number
  paymentMethod: BackOfficePaymentMethodV6
  signatoriesData: Array<BackOfficeAgreementPersonDataV6>
  personalGuarantorsData: Array<BackOfficeAgreementPersonDataV6>
  companyGuarantors: Array<CardCreditCompanyGuarantorV6>
  otherTerms?: string
  deliveryMethod: DeliveryMethod
  authenticationMethod: AuthenticationMethod
  mandateReference?: string
  bankAccount?: string
  iban?: string
}

interface BackOfficeDirectDebitAgreementResponseDto {
  id: string
  customerId: string
  agreementStatus: AgreementStatus
  sendDate?: string
  mandateReference?: string
  iban?: string
  bankAccount?: string
  externalLink?: string
}

type BackOfficeAgreementResponseDto = BackOfficeCardCreditAgreementResponseDto[] | BackOfficeDirectDebitAgreementResponseDto[] | undefined

const DirectDebitTabMandateAgreements = () => {
  const { customerId } = useParams<'customerId'>()
  const { data: agreements } = useAllAgreementsV6(customerId || '', [BackOfficeAgreementTypeV6.DIRECT_DEBIT_AGREEMENT], {
    enabled: !!customerId
  })

  const prettyDate = (date?: string) => {
    if (!date) return ''
    return dayjs(date).format(YYYY_MM_DD)
  }

  return (
    <BorderContainer fullWidth smallSpacing>
      <FigmaBox fullWidth bottom={Spacings.medium}>
        <TextContainer textKey={TextKeys.earlierCreditAgreementHeadline} text={'Mandate agreements'} />
      </FigmaBox>

      <FigmaBox fullWidth gap={Spacings.tiny}>
        {(agreements as BackOfficeAgreementResponseDto)?.map((agreement) => (
          <FigmaBox key={agreement.id} fullWidth direction="row" justify="space-between" align="center">
            <FigmaBox gap={Spacings.smallest}>
              {agreement.externalLink && (
                <FigmaTextLink
                  textKey={TextKeys.earlierCreditAgreementNumberLink}
                  text={agreement.mandateReference}
                  url={agreement.externalLink}
                  rightSvg={() => (
                    <FigmaBox spacing={Spacings.minimum} left>
                      <IconExport24LinkGreen />
                    </FigmaBox>
                  )}
                />
              )}
              <TextContainer textKey={TextKeys.earlierCreditAgreementDate} text={prettyDate(agreement.sendDate)} />
            </FigmaBox>

            <DirectDebitTabStatusTag status={agreement.agreementStatus} />
          </FigmaBox>
        ))}
      </FigmaBox>
    </BorderContainer>
  )
}

export default DirectDebitTabMandateAgreements
