import Box from '@mui/material/Box'
import { PremiumSwitch } from './PremiumSwitch'
import IconButton from '@mui/material/IconButton'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import FeatherIcon from 'feather-icons-react'
import Button from '@mui/material/Button'
import dayjs from 'dayjs'
import { Line } from 'mynt-components/components/StyledComponents'
import { When } from 'mynt-components/components/When'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TextFieldController } from './react-hook-components'
import LoadingButton from '@mui/lab/LoadingButton'
import { ModalAreYouSureWithBody } from './Modals'
import useMaterialNotification from 'hooks/useMaterialNotification'
import { useCustomerEvents, useCustomerNotes, useCreateCustomerNote, useDeleteCustomerNote, useUpdateCustomerNote } from 'api/react-query'
import { Access, usePermissions } from 'contexts/permissions'
import { useNotesContext } from 'contexts/Notes'

const sortNotesAndEvents = (notes: Note[] = [], events: Event[] = []) =>
  [...notes, ...events].toSorted((a, b) => {
    const aDate = dayjs(a.createdAt * 1000)
    const bDate = dayjs(b.createdAt * 1000)

    return aDate.isBefore(bDate) ? 1 : -1
  })

export type Event = {
  id: string
  createdAt: number
  text: string
  type: 'EVENT'
}

export type Note = {
  id: string
  createdAt: number
  text: string
  rev: number
  type: 'NOTE'
}

type CustomerNotesProps = {
  events: Event[]
  notes: Note[]
  isLoading?: boolean
  onCreateNote: (text: string) => Promise<unknown>
  onDeleteNote: (noteId: string) => Promise<unknown>
  onUpdateNote: ({ id, rev, text }: { id: string; rev: number; text: string }) => Promise<unknown>
}

export const CustomerNotes = ({ onCreateNote, onDeleteNote, onUpdateNote, ...props }: CustomerNotesProps) => {
  const [notesContext, setNotesContext] = useNotesContext()
  const [loading, setIsLoading] = useState(false)
  const [activeNote, setActiveNote] = useState<Note & { toBeDeleted?: boolean }>()
  const [view, setView] = useState<'notes' | 'create-note' | 'update-note'>('notes')
  const [filter, setFilter] = useState<'notes' | 'all'>('notes')

  const permissions = {
    create: usePermissions('customers.notes.create'),
    log: usePermissions('customers.log')
  }

  const notify = useMaterialNotification()

  const form = useForm({
    defaultValues: {
      text: ''
    }
  })

  const handleCreateNote = (text: string) => {
    setIsLoading(true)

    onCreateNote(text)
      .finally(() => {
        setIsLoading(false)
      })
      .then(() => {
        setView('notes')
      })
  }

  const handleNoteDelete = (noteId: string | undefined) => {
    if (!noteId) return

    setIsLoading(true)

    onDeleteNote(noteId)
      .finally(() => {
        setIsLoading(false)
      })
      .then(() => {
        setActiveNote(undefined)
      })
  }

  const handleNoteUpdate = (payload: { id: string; rev: number; text: string }) => {
    setIsLoading(true)

    onUpdateNote(payload)
      .finally(() => {
        setIsLoading(false)
      })
      .then(() => {
        setActiveNote(undefined)
        setView('notes')
      })
  }

  const handleSetUpdatingNote = (note: Note) => {
    form.setValue('text', note.text)

    setView('update-note')
    setActiveNote(note)
  }

  const allNotes = useMemo(() => {
    if (filter === 'notes') return sortNotesAndEvents(props.notes)

    return sortNotesAndEvents(props.notes, props.events)
  }, [props.notes, props.events, filter])

  if (!notesContext.open) {
    return (
      <Box>
        <SquareIconButton onClick={() => setNotesContext({ open: true })}>
          <FeatherIcon icon="chevron-left" />
        </SquareIconButton>
      </Box>
    )
  }

  const handleUpdateNote = (text: string) => {
    if (!activeNote) return notify('Unable to find active note, let #backoffice know', 'error')

    handleNoteUpdate({ id: activeNote.id, rev: activeNote.rev, text })
  }

  const ctrlEnterToSubmit = (handler: any) => (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const isCtrlOrCmd = e.ctrlKey || e.metaKey

    if (e.key === 'Enter' && isCtrlOrCmd) {
      form.handleSubmit(({ text }) => handler(text))()
    }
  }

  return (
    <>
      <When is={activeNote?.toBeDeleted}>
        <ModalAreYouSureWithBody
          onClose={() => setActiveNote(undefined)}
          onContinue={() => handleNoteDelete(activeNote?.id)}
          title="Delete note"
          bodyText="Are you sure you want to delete this note?"
          confirmText="Yes, delete note"
          loading={loading}
        />
      </When>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '400px',
          minWidth: '300px',
          padding: '16px',
          gap: '16px',
          backgroundColor: '#fdfdfd',
          border: '1px solid #eee',
          borderRadius: '8px',
          position: 'sticky',
          top: 0,
          minHeight: 'calc(100vh - 110px)',
          maxHeight: 'calc(100vh - 500px)'
        }}
      >
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <When is={view !== 'notes'}>
            <SquareIconButton onClick={() => setView('notes')}>
              <FeatherIcon icon="chevron-left" />
            </SquareIconButton>
          </When>
          <When is={view === 'notes'}>
            <Typography variant="h3">Notes</Typography>
          </When>
          <When is={view === 'create-note'}>
            <Typography variant="h3">Create note</Typography>
          </When>
          <When is={view === 'update-note'}>
            <Typography variant="h3">Update note</Typography>
          </When>
          <SquareIconButton onClick={() => setNotesContext({ open: false })}>
            <FeatherIcon icon="x" />
          </SquareIconButton>
        </Box>
        <When is={view === 'notes'}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <PremiumSwitch disabled={!permissions.log} value={filter} onChange={setFilter}>
              <PremiumSwitch.Left value="all">All</PremiumSwitch.Left>
              <PremiumSwitch.Right value="notes">Notes only</PremiumSwitch.Right>
            </PremiumSwitch>
            <Button
              disabled={!permissions.create}
              variant="outlined"
              onClick={() => {
                setView('create-note')
                form.setValue('text', '')
              }}
            >
              Add note
            </Button>
          </Box>
        </When>
        <When is={view === 'notes'}>
          <Notes sx={{ overflowY: 'auto' }}>
            <When is={allNotes.length === 0}>
              <Typography variant="subtitle1" color="textSecondary">
                No notes available
              </Typography>
            </When>
            {allNotes.map((note, index) => (
              <Box key={note.id} sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dayjs(note.createdAt * 1000).format('YYYY-MM-DD HH:mm')}
                  </Typography>
                  <When is={[note.type === 'NOTE', permissions.create]}>
                    <Box>
                      <Access permissions="customers.notes.edit">
                        <IconButton size="small" onClick={() => handleSetUpdatingNote(note as Note)}>
                          <FeatherIcon size={16} icon="edit-2" />
                        </IconButton>
                      </Access>
                      <Access permissions="customers.notes.delete">
                        <IconButton size="small" onClick={() => setActiveNote({ ...(note as Note), toBeDeleted: true })}>
                          <FeatherIcon size={16} icon="trash-2" />
                        </IconButton>
                      </Access>
                    </Box>
                  </When>
                </Box>
                <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  <Typography color="inherit" variant="subtitle1">
                    {note.text}
                  </Typography>
                </Box>
                <When is={index < allNotes.length - 1}>
                  <Box sx={{ height: '1px', padding: '16px 0' }}>
                    <Line fullWidth />
                  </Box>
                </When>
              </Box>
            ))}
          </Notes>
        </When>
        <When is={view === 'create-note'}>
          <Box sx={{ backgroundColor: '#FFFFFF', paddingTop: '8px' }}>
            <TextFieldController
              InputProps={{ onKeyDown: ctrlEnterToSubmit(handleCreateNote) }}
              autoFocus
              name="text"
              labelText="New note"
              control={form.control}
              rows={10}
              multiline
            />
          </Box>
          <Box>
            <LoadingButton loading={loading} variant="primary" onClick={form.handleSubmit(({ text }) => handleCreateNote(text))}>
              Create note
            </LoadingButton>
          </Box>
        </When>
        <When is={view === 'update-note'}>
          <Box sx={{ backgroundColor: '#FFFFFF', paddingTop: '8px' }}>
            <TextFieldController
              InputProps={{ onKeyDown: ctrlEnterToSubmit(handleUpdateNote) }}
              name="text"
              labelText="Update note"
              control={form.control}
              rows={10}
              multiline
            />
          </Box>
          <Box>
            <LoadingButton
              loading={loading}
              variant="primary"
              onClick={form.handleSubmit(({ text }) => {
                if (!activeNote) return notify('Unable to find active note, let #backoffice know', 'error')

                handleNoteUpdate({ id: activeNote.id, rev: activeNote.rev, text })
              })}
            >
              Update note
            </LoadingButton>
          </Box>
        </When>
      </Box>
    </>
  )
}

export const useCustomerNotesDataProvider = (customerId: string, { enabled = true }): CustomerNotesProps => {
  const hasLogPermission = usePermissions('customers.log')

  const { data: events = [], isLoading: isCustomerLogLoading } = useCustomerEvents(customerId, { enabled: enabled && hasLogPermission })
  const { data: notes = [], isLoading: isCustomerNotesLoading } = useCustomerNotes(customerId, { enabled })

  const createNoteMutation = useCreateCustomerNote(customerId)
  const deleteNoteMutation = useDeleteCustomerNote(customerId)
  const updateNoteMutation = useUpdateCustomerNote(customerId)

  const allCustomerNotes = useMemo<{ notes: Note[]; events: Event[] }>(
    () => ({
      notes: notes
        .filter((note) => !note.deleted)
        .map((note) => ({
          createdAt: note.createdAt as unknown as number,
          text: note.text as string,
          id: note.id as string,
          type: 'NOTE' as const,
          rev: note.rev as number
        })),
      events: events.map((event) => ({
        id: event.hash as string,
        createdAt: event.createdAt as unknown as number,
        text: event.description as string,
        type: 'EVENT' as const
      }))
    }),
    [events, notes]
  )

  const onCreateNote = (text: string) => createNoteMutation.mutateAsync(text)
  const onDeleteNote = (noteId: string) => deleteNoteMutation.mutateAsync(noteId)
  const onUpdateNote = (payload: { id: string; rev: number; text: string }) => updateNoteMutation.mutateAsync(payload)

  return {
    onCreateNote,
    onDeleteNote,
    onUpdateNote,
    notes: allCustomerNotes.notes,
    events: allCustomerNotes.events,
    isLoading: isCustomerLogLoading || isCustomerNotesLoading
  }
}

const Notes = styled(Box)`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  color: #68676a;
  display: flex;
  flex-direction: column;
`

const SquareIconButton = styled(IconButton)`
  padding: 7px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #eeeeee;
`
