import React from 'react'
import { AccountingTabs, TabSets } from '../helpers/TabsHelpers'
import Tabs from '../mynt-components/components/Tabs'
import { TextKeys } from '../tiger/libs/TextRepository'
import AccountsTable from './AccountsTable'
import { CardPayoutsTable } from './CardPayoutsTable'
import { PaymentsTable } from './PaymentsTable'
import TextContainer from './TextContainer'
import VouchersTable from './VouchersTable'
import YearsTable from './YearsTable'
import { Invoices } from '../pages/invoices'
import { InvoiceState } from 'api/swagger/definitions/backoffice'
import dayjs from 'dayjs'

const Accounting: React.FC = () => (
  <Tabs
    fullPage
    enableNestedTabs
    alwaysShowNavHeadings
    tabKeyNameSpace={TabSets.ACCOUNTING}
    tabs={[
      {
        labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={AccountingTabs.VOUCHERS} />,
        contentElement: <VouchersTable />,
        enable: true
      },
      {
        labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={AccountingTabs.ACCOUNTS} />,
        contentElement: <AccountsTable />,
        enable: true
      },
      {
        labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={AccountingTabs.YEARS} />,
        contentElement: <YearsTable />,
        enable: false
      },
      {
        labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={AccountingTabs.PAYOUTS} />,
        contentElement: <CardPayoutsTable />,
        enable: true
      },
      {
        labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={AccountingTabs.PAYMENTS} />,
        contentElement: <PaymentsTable />,
        enable: true
      },
      {
        labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={AccountingTabs.INVOICES} />,
        contentElement: (
          <Invoices
            page={'ACCOUNTING'}
            defaultFilters={{
              state: [
                InvoiceState.BOOKED,
                InvoiceState.DEBT_COLLECTION,
                InvoiceState.DRAFT,
                InvoiceState.OVER_DUE,
                InvoiceState.REMINDED,
                InvoiceState.REMINDER_OVERDUE
              ],
              invoiceDate: {
                from: dayjs().subtract(1, 'months'),
                to: dayjs()
              }
            }}
          />
        ),
        enable: true
      }
    ]}
  />
)

export default Accounting
