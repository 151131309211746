import {
  BackOfficeAccountStatusV5,
  BackOfficeOrderCardRequestDtoV3,
  CustomerRole as CustomerRoleDTO,
  GetAccountsV5200ResponseInner,
  PersonDto
} from '../swagger/definitions/backoffice'
import {
  Accounts,
  AccountStatus,
  CardType,
  CorporateAccount,
  CustomerRole,
  OrderCardRequest,
  Person,
  PersonalAccount,
  PersonalTopAccount,
  TopAccount
} from './types.ts'

export const mapAccounts = (accounts: GetAccountsV5200ResponseInner[]): Accounts[] =>
  accounts.map((account) => {
    switch (account.accountType) {
      case 'PERSONAL_ACCOUNT':
        return {
          personId: account.personId,
          id: account.id,
          name: account.name,
          externalId: account.externalId,
          customerId: account.customerId,
          no: account.no,
          hierarchyId: account.hierarchyId,
          currency: account.currency,
          createdAt: account.createdAt,
          parentAccount: account.parentAccount,
          accountType: 'PERSONAL_ACCOUNT'
        } as PersonalAccount
      case 'CORPORATE_ACCOUNT':
        return {
          id: account.id,
          name: account.name,
          externalId: account.externalId,
          customerId: account.customerId,
          no: account.no,
          hierarchyId: account.hierarchyId,
          currency: account.currency,
          createdAt: account.createdAt,
          parentAccount: account.parentAccount,
          accountType: 'CORPORATE_ACCOUNT'
        } as CorporateAccount
      case 'TOP_ACCOUNT':
        return {
          id: account.id,
          name: account.name,
          externalId: account.externalId,
          customerId: account.customerId,
          no: account.no,
          hierarchyId: account.hierarchyId,
          currency: account.currency,
          createdAt: account.createdAt,
          accountType: 'TOP_ACCOUNT'
        } as TopAccount
      case 'PERSONAL_TOP_ACCOUNT':
        return {
          id: account.id,
          name: account.name,
          externalId: account.externalId,
          customerId: account.customerId,
          no: account.no,
          hierarchyId: account.hierarchyId,
          currency: account.currency,
          createdAt: account.createdAt,
          parentAccount: account.parentAccount,
          accountType: 'PERSONAL_TOP_ACCOUNT'
        } as PersonalTopAccount
      default:
        throw new Error(`Unknown account type: ${account}`)
    }
  })

export const mapAccountStatus = (accountStatus: BackOfficeAccountStatusV5): AccountStatus => ({
  creditLimit: {
    value: accountStatus.creditLimit.value,
    currency: accountStatus.creditLimit.currency
  },
  available: {
    value: accountStatus.available.value,
    currency: accountStatus.available.currency
  },
  balance: {
    value: accountStatus.balance.value,
    currency: accountStatus.balance.currency
  }
})
export const mapPersons = (persons: PersonDto[]): Person[] =>
  persons.map(
    (person) =>
      ({
        customerId: person.customerId,
        log: person.log,
        id: person.id,
        rev: person.rev,
        deleted: person.deleted,
        fullName: person.fullName,
        email: person.email,
        modifiedAt: person.modifiedAt,
        phoneNumber: person.phoneNumber,
        partnerExternalId: person.partnerExternalId,
        enfuceCustomerId: person.enfuceCustomerId,
        findityEmployeeId: person.findityEmployeeId,
        blPersonExternalId: person.blPersonExternalId,
        peEmployeeId: person.peEmployeeId,
        roles: mapCustomerRoles(person.roles),
        inviteSentAt: person.inviteSentAt,
        ssn: person.ssn
      } satisfies Person)
  )

const mapCustomerRoles = (roles: CustomerRoleDTO[]): CustomerRole[] =>
  roles.map((role) => {
    switch (role) {
      case CustomerRole.ADMIN:
        return CustomerRole.ADMIN
      case CustomerRole.SIGNATORY:
        return CustomerRole.SIGNATORY
      case CustomerRole.CONTACT:
        return CustomerRole.CONTACT
      case CustomerRole.BENEFICIAL_OWNER:
        return CustomerRole.BENEFICIAL_OWNER
      case CustomerRole.CARD_HOLDER:
        return CustomerRole.CARD_HOLDER
      case CustomerRole.ACCOUNTANT:
        return CustomerRole.ACCOUNTANT
      case CustomerRole.USER_ADMIN:
        return CustomerRole.USER_ADMIN
      default:
        throw new Error(`Unknown role: ${role}`)
    }
  })

export const orderCardDtoMapper = (data: OrderCardRequest): BackOfficeOrderCardRequestDtoV3 => {
  switch (data.type) {
    case CardType.PHYSICAL_CORPORATE: {
      return {
        address: data.address,
        deliveryCompanyName: data.deliveryCompanyName,
        label: data.label,
        personId: data.personId,
        monthlyLimit: data.monthlyLimit ?? 0,
        type: 'CORPORATE_PHYSICAL' as const
      }
    }
    case CardType.VIRTUAL_CORPORATE: {
      return {
        label: data.label,
        personId: data.personId,
        monthlyLimit: data.monthlyLimit ?? 0,
        type: 'CORPORATE_VIRTUAL' as const
      }
    }
    case CardType.PHYSICAL_PERSONAL: {
      return {
        address: data.address,
        deliveryCompanyName: data.deliveryCompanyName,
        label: data.label,
        personId: data.personId,
        creditLimit: data.creditLimit,
        type: 'PERSONAL' as const
      }
    }
  }
}
