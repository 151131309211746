import styled from '@emotion/styled'
import FigmaBox from 'mynt-components/components/FigmaBox'
import React, { useState } from 'react'
import { AntiloopTextType } from 'tiger/interfaces/Antiloop'
import { formatAmount } from 'tiger/libs/Common'
import { TextKeys } from 'tiger/libs/TextRepository'
import { BackOfficeInvoiceResponseDtoV2, InvoiceState } from '../../api/swagger/definitions/backoffice'
import JSONDetailPanel from '../../components/JSONDetailPanel'
import TextContainer from '../../components/TextContainer'
import { TooltipInvoiceInformationPDF } from '../../components/Tooltips'
import BorderRadius from '../../figma/tokens/BorderRadius'
import ColorsDeprecated from '../../figma/tokens/ColorsDeprecated'
import Spacings from '../../figma/tokens/Spacings'
import { getDownloadablePdfUrl } from '../../api'
import { When } from '../../mynt-components/components/When'
import { Line } from '../../mynt-components/components/StyledComponents'
import config from '../../config'

// TODO Create enum maybe ?

const _type = { textKey: '' as unknown as AntiloopTextType, color: '' }

const InfoButton: React.FC<{ invoice: BackOfficeInvoiceResponseDtoV2 }> = ({ invoice }) => {
  const type = invoice.state

  switch (type) {
    case InvoiceState.SETTLED:
      _type.textKey = TextKeys.invoiceInfoSettled
      _type.color = ColorsDeprecated.baseGreen
      break
    case InvoiceState.BOOKED:
      _type.textKey = TextKeys.invoiceInfoBooked
      _type.color = ColorsDeprecated.baseWarning
      break
    case InvoiceState.CREDITED:
      _type.textKey = TextKeys.invoiceInfoCredited
      _type.color = ColorsDeprecated.baseStone
      break
    case InvoiceState.OVER_DUE || InvoiceState.REMINDER_OVERDUE:
      _type.textKey = TextKeys.invoiceInfoOverdue
      _type.color = ColorsDeprecated.baseGreen
      break
    case InvoiceState.DEBT_COLLECTION:
      _type.textKey = TextKeys.invoiceInfoDebtCollection
      _type.color = ColorsDeprecated.baseStone
      break
    case InvoiceState.REMINDED:
      _type.textKey = TextKeys.invoiceInfoReminder
      _type.color = ColorsDeprecated.baseStone
      break
    case InvoiceState.CANCELLED:
      _type.textKey = TextKeys.invoiceInfoCancelled
      _type.color = ColorsDeprecated.baseStone
      break
    default:
      _type.textKey = TextKeys.invoiceInfoDraft
      _type.color = ColorsDeprecated.baseStone
      break
  }

  return (
    <InfoButtonWrapper color={_type.color} direction="row" justify="center" align="center">
      <TextContainer textKey={_type.textKey} />
    </InfoButtonWrapper>
  )
}

const InvoiceDetails = ({ invoice }: { invoice: BackOfficeInvoiceResponseDtoV2 }) => {
  const [showAll, setShowAll] = useState(false)

  const onClickPdf = async (url: string) =>
    getDownloadablePdfUrl(url).then(({ url }) => {
      window.open(url)
    })

  return (
    <Wrapper fullWidth className="detailTableRow">
      <FigmaBox fullWidth direction="row">
        <FigmaBox>
          <FigmaBox direction="row" gap={Spacings.minimum} bottom={Spacings.small}>
            <TextContainer
              textKey={TextKeys.customersCardCreditOnCardCreditSettingsPrepaidLabelCurrentCreditLimit}
              text={'Credit number:'}
            />
            <TextContainer
              textKey={TextKeys.customersCardCreditOnCardCreditSettingsPrepaidCurrentCreditLimitValue}
              text={String(invoice.no || '-')}
            />
          </FigmaBox>
          <InvoiceInfoTitle direction="row" bottom spacing={Spacings.tiny}>
            <TextContainer textKey={TextKeys.invoiceInfoHeading} />
            <InfoButton invoice={invoice} />
          </InvoiceInfoTitle>
          <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfo} />
            <TextContainer textKey={TextKeys.invoiceInfoValue} text={`${invoice.no}`} />
          </InvoiceContainer>
          <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoOCR} />
            <TextContainer textKey={TextKeys.invoiceInfoOCRValue} text={invoice.ocr} />
          </InvoiceContainer>
          <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoCustomer} />
            <TextContainer textKey={TextKeys.invoiceInfoCustomerValue} text={invoice.companyName} />
          </InvoiceContainer>
          <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoCustomer} text={'Receiver name'} />
            <TextContainer textKey={TextKeys.invoiceInfoCustomerValue} text={invoice.receiverName} />
          </InvoiceContainer>
          <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoType} />
            <TextContainer textKey={TextKeys.invoiceInfoTypeValue} text={invoice.type} />
          </InvoiceContainer>
          <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoAccountingMethod} />
            <TextContainer textKey={TextKeys.invoiceInfoAccountingMethodValue} text={invoice.method} />
          </InvoiceContainer>
          <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoInvoiceDate} />
            <TextContainer textKey={TextKeys.invoiceInfoInvoiceDateValue} text={invoice.invoiceDate} />
          </InvoiceContainer>
          {invoice.bookDate && (
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoBookedDate} />
              <TextContainer textKey={TextKeys.invoiceInfoBookedDateValue} text={invoice.bookDate} />
            </InvoiceContainer>
          )}
          <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoDueDate} />
            <TextContainer textKey={TextKeys.invoiceInfoDueDateValue} text={invoice.dueDate} />
          </InvoiceContainer>
          {invoice.reminderDate && (
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoReminderDate} />
              <TextContainer textKey={TextKeys.invoiceInfoReminderDateValue} text={invoice.reminderDate} />
            </InvoiceContainer>
          )}
          {invoice.reminderDueDate && (
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoReminderDueDate} />
              <TextContainer textKey={TextKeys.invoiceInfoReminderDateDueValue} text={invoice.reminderDueDate} />
            </InvoiceContainer>
          )}
          {invoice.debtCollectionDate && (
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceDebtCollectionDate} />
              <TextContainer textKey={TextKeys.invoiceDebtCollectionDateValue} text={invoice.debtCollectionDate} />
            </InvoiceContainer>
          )}
          {invoice.settleDate && (
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceSettledDate} />
              <TextContainer textKey={TextKeys.invoiceSettledDateValue} text={invoice.settleDate} />
            </InvoiceContainer>
          )}
          {invoice.externalInvoiceId && (
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceExternalReference} />
              <TextContainer textKey={TextKeys.invoiceExternalReferenceValue} text={invoice.externalInvoiceId} />
            </InvoiceContainer>
          )}
        </FigmaBox>
        <FigmaBox>
          <FigmaBox fullWidth justify="space-between" direction="row" align="center" bottom spacing={Spacings.tiny}>
            <TextContainer textKey={TextKeys.invoiceSpecHeading} />
            <span onClick={() => setShowAll(!showAll)}>
              <StyledLinkTextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoShowAll}
                text={showAll ? 'Hide 0kr' : 'Show all'}
              />
            </span>
          </FigmaBox>
          <When is={showAll || invoice.interest.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceSpecItemDetailsInterest} />
              <TextContainer textKey={TextKeys.invoiceSpecItemDetailsInterestNum} text={formatAmount(invoice.interest)} />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.cardsFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationCardsFee} />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationAdministrationAmount}
                text={formatAmount(invoice.cardsFee)}
              />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.invoiceFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceSpecItemDetailsInvoiceFee} />
              <TextContainer textKey={TextKeys.invoiceSpecItemDetailsInvoiceFeeNum} text={formatAmount(invoice.invoiceFee)} />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.latePaymentFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceSpecItemDetailsPenaltyFee} text="Late payment fee" />
              <TextContainer textKey={TextKeys.invoiceSpecItemDetailsPenaltyFeeNum} text={formatAmount(invoice.latePaymentFee)} />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.amortization.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceSpecItemDetailsAmortization} />
              <TextContainer textKey={TextKeys.invoiceSpecItemDetailsAmortizationNum} text={formatAmount(invoice.amortization)} />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.cardOrderFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationCardOrderFee} />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationAdministrationAmount}
                text={formatAmount(invoice.cardOrderFee)}
              />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.subscriptionFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationSubscriptionFee} />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationSubscriptionFeeAmount}
                text={formatAmount(invoice.subscriptionFee)}
              />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.administrationFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationAdministrationFee} />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationAdministrationAmount}
                text={formatAmount(invoice.administrationFee)}
              />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.travelInsuranceFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationTravelInsuranceFee} />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationAdministrationAmount}
                text={formatAmount(invoice.travelInsuranceFee)}
              />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.creditLimitFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationFixedCreditFee} text="Fixed credit limit fee" />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationFixedCreditFeeAmount}
                text={formatAmount(invoice.creditLimitFee)}
              />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.latePaymentInterest.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationFixedCreditFee} text="Late payment interest" />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationFixedCreditFeeAmount}
                text={formatAmount(invoice.latePaymentInterest)}
              />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.cardCreditMonthlyFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationFixedCreditFee}
                text="Card Credit Monthly Fee"
              />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationFixedCreditFeeAmount}
                text={formatAmount(invoice.cardCreditMonthlyFee)}
              />
            </InvoiceContainer>
          </When>
          <When is={showAll || invoice.cardCreditUsageFee.value !== 0}>
            <InvoiceContainer fullWidth direction="row" justify="space-between" align="center">
              <TextContainer textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationFixedCreditFee} text="Card Credit Usage Fee" />
              <TextContainer
                textKey={TextKeys.invoiceInfoAccountingInvoiceInfoSpecificationFixedCreditFeeAmount}
                text={formatAmount(invoice.cardCreditUsageFee)}
              />
            </InvoiceContainer>
          </When>
          <Line fullWidth marginTop={Spacings.minimum} marginBottom={Spacings.minimum} />
          <InvoiceContainer fullWidth left direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceSpecItemDetailsTotalSubTotal} />
            <TextContainer textKey={TextKeys.invoiceSpecItemDetailsTotalSubTotalNum} text={formatAmount(invoice.netTotal)} />
          </InvoiceContainer>
          <InvoiceContainer fullWidth left direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceSpecItemDetailsTotalVATAmount} />
            <TextContainer textKey={TextKeys.invoiceSpecItemDetailsTotalVATAmountNum} text={formatAmount(invoice.vat)} />
          </InvoiceContainer>
          <Line fullWidth marginTop={Spacings.minimum} marginBottom={Spacings.minimum} />
          <InvoiceContainer fullWidth left direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceSpecItemDetailsTotal} />
            <TextContainer textKey={TextKeys.invoiceSpecItemDetailsTotalNum} text={formatAmount(invoice.grossTotal)} />
          </InvoiceContainer>
          <InvoiceContainer fullWidth left direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoPaidAmount} />
            <TextContainer textKey={TextKeys.invoiceInfoRemainingAmountValue} text={formatAmount(invoice.accumulatedPayments)} />
          </InvoiceContainer>
          <Line fullWidth marginTop={Spacings.minimum} marginBottom={Spacings.minimum} />
          <InvoiceContainer fullWidth left direction="row" justify="space-between" align="center">
            <TextContainer textKey={TextKeys.invoiceInfoRemainingAmount} />
            <TextContainer textKey={TextKeys.invoiceInfoRemainingAmountValue} text={formatAmount(invoice.remaining)} />
          </InvoiceContainer>
        </FigmaBox>
        <FigmaBox>
          <FigmaBox direction="row" bottom spacing={Spacings.tiny}>
            <TextContainer textKey={TextKeys.invoicesPDFHeading} />
          </FigmaBox>
          <FigmaBox direction="row" bottom>
            <TooltipInvoiceInformationPDF invoiceDate={invoice.invoiceDate} onClickPdf={async () => onClickPdf(invoice.invoiceUrl)} />
            {invoice.repaymentInvoiceUrl && (
              <TooltipInvoiceInformationPDF
                invoiceDate={invoice.invoiceDate}
                onClickPdf={async () => invoice.repaymentInvoiceUrl && onClickPdf(invoice.repaymentInvoiceUrl)}
              />
            )}
          </FigmaBox>
          <JSONDetailPanel src={invoice} collapsed />
          {config.enableVouchersInfoInInvoiceInformation && (
            <FigmaBox>
              <FigmaBox bottom spacing={Spacings.minimum}>
                <TextContainer textKey={TextKeys.accountingVouchersHeading} />
              </FigmaBox>
              <FigmaBox direction="row">
                <FigmaBox right spacing={Spacings.big}>
                  <FigmaBox bottom spacing={Spacings.minimum}>
                    <TextContainer textKey={TextKeys.accountingVouchersBooking} />
                  </FigmaBox>
                  <TextContainer textKey={TextKeys.accountingVouchersBookingLink} />
                </FigmaBox>
                <FigmaBox>
                  <FigmaBox bottom spacing={Spacings.minimum}>
                    <TextContainer textKey={TextKeys.accountingVouchersPayment} />
                  </FigmaBox>
                  <TextContainer textKey={TextKeys.accountingVouchersPaymentLink} />
                </FigmaBox>
              </FigmaBox>
            </FigmaBox>
          )}
        </FigmaBox>
      </FigmaBox>
      {config.enableInvoiceHistory && (
        <FigmaBox top spacing={Spacings.tiny}>
          <FigmaBox direction="row" bottom spacing={Spacings.minimum}>
            <TextContainer textKey={TextKeys.invoiceHistoryHeading} />
          </FigmaBox>
          <TextContainer textKey={TextKeys.invoiceHistoryString} />
        </FigmaBox>
      )}
    </Wrapper>
  )
}

const StyledLinkTextContainer = styled(TextContainer)`
  cursor: pointer;
  user-select: none;
`

const Wrapper = styled(FigmaBox)`
  & > div {
    & > div {
      :not(:last-child) {
        margin-right: ${Spacings.max};
      }
    }
  }
`

const InfoButtonWrapper = styled(FigmaBox)<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: ${BorderRadius.rounded};
  padding: 0 ${Spacings.min};
`

const InvoiceInfoTitle = styled(FigmaBox)`
  & > div {
    :not(:last-child) {
      margin-right: ${Spacings.tiny};
    }
  }
`

const InvoiceContainer = styled(FigmaBox)`
  & > div {
    :not(:last-child) {
      margin-bottom: ${Spacings.minimum};
      margin-right: ${Spacings.max};
    }
  }
`

export default InvoiceDetails
