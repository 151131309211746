import styled from '@emotion/styled'
import OpenInNew from '@mui/icons-material/OpenInNew'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { BackOfficeTx2Status2, CardActiveStatus } from '../../api/swagger/definitions/backoffice'
import TextContainer from '../../components/TextContainer'
import { AutoCompleteController, TextFieldController } from '../../components/react-hook-components'
import Colors from '../../figma/panda/Colors'
import BorderRadius from '../../figma/tokens/BorderRadius'
import ColorsDeprecated from '../../figma/tokens/ColorsDeprecated'
import Spacings from '../../figma/tokens/Spacings'
import { useCardsV2, usePersons } from '../../api/react-query'
import { prettifyEnumLabel } from '../../helpers/CreditOnCardHelpers'
import useDebounce from '../../helpers/useDebounce'
import useMaterialNotification from '../../hooks/useMaterialNotification'
import TextKeys from '../../libs/TextKeys'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import { YYYY_MM_DD } from '../../tiger/Constants'
import { Access, usePermissions } from 'contexts/permissions'
import { useEffect } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'

export const LINKS = {
  CONDITION_CODES:
    'https://console.myedge.enfuce.com/guides/service-descriptions/general-data/export-files/transaction-file#transaction-conditions',
  RESPONSE_CODES: 'https://console.myedge.enfuce.com/guides/service-descriptions/transaction/authorisation#authorisation-response'
}

export const OpenInNewButton = ({ link }: { link: string }) => (
  <IconButton size="small" onClick={() => window.open(link, '_BLANK')}>
    <OpenInNew sx={{ height: 18 }} />
  </IconButton>
)

export const StyledTextContainerWrapped = styled.span`
  cursor: pointer;
  * :hover {
    color: ${Colors.bluePrimary600Cta} !important;
  }

  * :active {
    color: ${Colors.bluePrimary400} !important;
  }
`

export const StyledActionWrapper = styled(FigmaBox)`
  &:hover {
    cursor: pointer;
    border-radius: ${BorderRadius.circle};
    background-color: ${ColorsDeprecated.baseStone};
    transition: all 0.3s ease-in-out;
    color: ${ColorsDeprecated.baseBlack};
  }
`

const DataRowHover = styled(FigmaBox)`
  &:hover span {
    text-decoration: underline;
    color: #2a73ff !important;
  }
`

export const DataRow = ({ label, value, adornment }: { label: string; value?: string; adornment?: JSX.Element }) => (
  <DataRowHover fullWidth direction="row" justify="space-between" align="center">
    <TextContainer textKey={TextKeys.transactionTableResponseCode} text={label} />
    <FigmaBox direction="row" align="center">
      {adornment && adornment}
      <CopyTextContainer textKey={TextKeys.transactionTableResponseCodeValue} text={value ?? '-'} />
    </FigmaBox>
  </DataRowHover>
)

const TransactionBadgeContainer = styled(FigmaBox)<{ status: BackOfficeTx2Status2 }>`
  padding: ${Spacings.smallest} ${Spacings.minimum};
  background-color: ${(props) => StatusToColor(props.status)};
  border-radius: ${BorderRadius.rounded};
`
const StatusToColor = (status: BackOfficeTx2Status2) => {
  switch (status) {
    case BackOfficeTx2Status2.SETTLED: {
      return ColorsDeprecated.baseSuccess
    }

    case BackOfficeTx2Status2.RESERVED: {
      return ColorsDeprecated.baseWarning
    }

    case BackOfficeTx2Status2.CANCELLED: {
      return ColorsDeprecated.baseStone
    }

    case BackOfficeTx2Status2.REJECTED: {
      return ColorsDeprecated.baseCritical
    }

    default: {
      return ColorsDeprecated.decorativeBlue
    }
  }
}

export const TransactionStateBadge = ({ state }: { state: BackOfficeTx2Status2 | undefined }) => {
  switch (state) {
    case BackOfficeTx2Status2.SETTLED: {
      return (
        <TransactionBadgeContainer status={state}>
          <TextContainer textKey={TextKeys.settledBadge} />
        </TransactionBadgeContainer>
      )
    }

    case BackOfficeTx2Status2.RESERVED: {
      return (
        <TransactionBadgeContainer status={state}>
          <TextContainer textKey={TextKeys.reservedBadge} />
        </TransactionBadgeContainer>
      )
    }

    case BackOfficeTx2Status2.CANCELLED: {
      return (
        <TransactionBadgeContainer status={state}>
          <TextContainer textKey={TextKeys.cancelledBadge} />
        </TransactionBadgeContainer>
      )
    }

    case BackOfficeTx2Status2.REJECTED: {
      return (
        <TransactionBadgeContainer status={state}>
          <TextContainer textKey={TextKeys.rejectedStatusTypeBadge} />
        </TransactionBadgeContainer>
      )
    }

    default: {
      return <TextContainer textKey={TextKeys.unknownBadge} />
    }
  }
}

export const CopyTextContainer = (props: Parameters<typeof TextContainer>[0]) => {
  const notify = useMaterialNotification()

  const onCopy = () => {
    const value = props.text || props.textKey.characters

    window.navigator.clipboard.writeText(value).then(() => {
      notify(`Copied '${value}' to clipboard`, 'info')
    })
  }

  return (
    <StyledTextContainerWrapped onClick={onCopy}>
      <TextContainer {...props} />
    </StyledTextContainerWrapped>
  )
}

export type TableFilters = {
  cards: string[]
  persons: string[]
  vendor: string
  from: string
  to: string
}

type TableFilterProps = {
  customerId: string
  onFilterChange: (filters: TableFilters) => void
}

const defaultFrom = dayjs().subtract(1, 'month')
const defaultTo = dayjs()

export const TableFilters = ({ customerId, onFilterChange }: TableFilterProps) => {
  const permissions = {
    cards: usePermissions('customers.cards.overview.view')
  }
  const { data: cards = [] } = useCardsV2(customerId, { enabled: permissions.cards })
  const { data: persons = [] } = usePersons(customerId)

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      person: '',
      card: '',
      from: defaultFrom,
      to: defaultTo,
      vendor: null
    }
  })

  const from = form.watch('from')
  const to = form.watch('to')
  const vendor = form.watch('vendor')

  const onFilterChangeDebounced = useDebounce(onFilterChange, 500)

  const handleDateChange = ([from, to]) => {
    if (dayjs(from).isValid() && dayjs(to).isValid()) {
      dayjs(from).isValid() && form.setValue('from', dayjs(from))
      dayjs(to).isValid() && form.setValue('to', dayjs(to))

      // This is needed because the onChange does not get triggered in the form element when the value is changed
      form.handleSubmit(handleSubmit)()
    }
  }

  const handleSubmit = (data) => {
    onFilterChangeDebounced({
      cards: [data.card],
      persons: [data.person],
      vendor: data.vendor,
      from: data.from.format(YYYY_MM_DD),
      to: data.to.format(YYYY_MM_DD)
    })
  }

  useEffect(() => {
    if (vendor !== null) form.handleSubmit(handleSubmit)()
  }, [vendor])

  const cardOptions = cards.map((card) => ({
    label: `${card.lastFourDigits} ${
      card.activeStatus === CardActiveStatus.ACTIVE ? '' : `(${prettifyEnumLabel(card.activeStatus)})`
    }`.trim(),
    value: card.id
  }))

  const personsOptions = persons.map((person) => ({
    label: person.deleted ? `${person.fullName} (deleted)` : person.fullName,
    value: person.id
  }))

  return (
    <FigmaBox justify="flex-end" direction="row" gap={Spacings.medium} align="flex-start" fullWidth>
      <FigmaBox style={{ flexGrow: 1 }} gap={Spacings.tiny} direction="row">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'sv'}>
          <DateRangePicker format={YYYY_MM_DD} onChange={handleDateChange} value={[from, to]} />
        </LocalizationProvider>
      </FigmaBox>
      <FigmaBox style={{ minWidth: 300 }}>
        <AutoCompleteController
          clearable
          control={form.control}
          onChange={form.handleSubmit(handleSubmit)}
          name="person"
          label="Card holder"
          options={personsOptions}
        />
      </FigmaBox>
      <Access permissions="customers.cards.overview.view">
        <FigmaBox style={{ minWidth: 200 }}>
          <AutoCompleteController
            clearable
            control={form.control}
            onChange={form.handleSubmit(handleSubmit)}
            name="card"
            label="Card"
            options={cardOptions}
          />
        </FigmaBox>
      </Access>
      <FigmaBox style={{ minWidth: 200 }}>
        <TextFieldController
          control={form.control}
          name="vendor"
          labelTextKey={TextKeys.transactionsFilterFiltersValueNumber}
          labelText="Vendor"
        />
      </FigmaBox>
    </FigmaBox>
  )
}
