import TableCell from '@mui/material/TableCell'
import dayjs from 'dayjs'
import TextContainer from '../../components/TextContainer'
import Spacings from '../../figma/tokens/Spacings'
import useConfigByCountry from '../../flamingo/hooks/useConfigByCountry'
import { BackOfficeTopUpTransactionDto2ConstEnum } from '../../helpers/ProcessHandler'
import TextKeys from '../../libs/TextKeys'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import { Line } from '../../mynt-components/components/StyledComponents'
import { YYYY_MM_DD } from '../../tiger/Constants'
import { DataRow, LINKS, OpenInNewButton, TransactionStateBadge } from './support-components'

type TopupTransactionRowProps = { transaction: BackOfficeTopUpTransactionDto2ConstEnum }

export const TopupTransactionRow = ({ transaction }: TopupTransactionRowProps) => {
  const { formatAmount } = useConfigByCountry()

  return (
    <>
      <TableCell component="th" scope="row">
        <TextContainer textKey={TextKeys.transactionVendor} text={transaction.description} />
      </TableCell>
      <TableCell>
        <TextContainer textKey={TextKeys.transactionDate} text={`${dayjs(transaction.transactionDate).format('YYYY-MM-DD - HH:mm')}`} />
      </TableCell>
      <TableCell>
        <TransactionStateBadge state={transaction.status} />
      </TableCell>
      <TableCell />
      <TableCell>
        <TextContainer textKey={TextKeys.transactionDate} text={transaction.accountOwner} />
      </TableCell>
      <TableCell />
      <TableCell>
        <TextContainer textKey={TextKeys.transactionAmount} text={formatAmount(transaction.amount)} />
      </TableCell>
    </>
  )
}

export const TopupTransactionDetails = ({ transaction }: TopupTransactionRowProps) => {
  const { formatAmount } = useConfigByCountry()

  return (
    <FigmaBox fullWidth direction="column">
      <FigmaBox gap={Spacings.tiny} spacing={Spacings.tiny} fullPadding fullWidth>
        <FigmaBox fullWidth>
          <h2 style={{ fontWeight: 'bold' }}>Transaction</h2>
          <FigmaBox gap={Spacings.medium} fullWidth justify="space-between" direction="row">
            <FigmaBox gap={Spacings.minimum} fullWidth>
              <DataRow label="Transaction ID" value={transaction.id} />
              <DataRow label="Enfuce link ID" value={transaction.linkId} />
              <DataRow
                label="Settlement date"
                value={transaction.settlementDate ? dayjs(transaction.settlementDate).format(YYYY_MM_DD) : '-'}
              />
              <DataRow
                label="Response code"
                value={transaction.responseCode.code}
                adornment={<OpenInNewButton link={LINKS.RESPONSE_CODES} />}
              />
              <DataRow label="Response description" value={transaction.responseCode.description} />
              <FigmaBox style={{ flexGrow: 1 }} top={Spacings.tiny} justify="flex-end" fullWidth>
                <Line color="#ccc" fullWidth />
              </FigmaBox>
            </FigmaBox>
            <FigmaBox gap={Spacings.minimum} fullWidth>
              <DataRow label="SEK amount" value={transaction.amount ? formatAmount(transaction.amount) : '-'} />
              <DataRow label="Type" value={transaction.txType} />
              <DataRow
                label="Condition code"
                value={transaction.condition?.code}
                adornment={<OpenInNewButton link={LINKS.CONDITION_CODES} />}
              />
              <DataRow label="Condition description" value={transaction.condition?.description} />
              <FigmaBox style={{ flexGrow: 1 }} top={Spacings.tiny} justify="flex-end" fullWidth>
                <Line color="#ccc" fullWidth />
              </FigmaBox>
            </FigmaBox>
          </FigmaBox>
        </FigmaBox>
        <FigmaBox fullWidth>
          <FigmaBox gap={Spacings.medium} fullWidth justify="space-between" direction="row">
            <FigmaBox gap={Spacings.minimum} fullWidth>
              <FigmaBox gap={Spacings.medium} fullWidth justify="space-between" direction="row">
                <FigmaBox gap={Spacings.minimum} fullWidth>
                  <h2 style={{ fontWeight: 'bold' }}>Accounting</h2>
                  <DataRow label="Attest status" value={transaction.attestStatus} />
                  <DataRow label="Comment" value={transaction.comment} />
                  <DataRow label="Has receipt" value={transaction.hasReceipt ? 'YES' : 'NO'} />
                </FigmaBox>
              </FigmaBox>
              <FigmaBox top={Spacings.tiny} style={{ flexGrow: 1 }} justify="flex-end" fullWidth>
                <Line color="#ccc" fullWidth />
              </FigmaBox>
            </FigmaBox>
            <FigmaBox top={Spacings.tiny} style={{ flexGrow: 1 }} justify="flex-end" fullWidth>
              <Line color="#ccc" fullWidth />
            </FigmaBox>
          </FigmaBox>
        </FigmaBox>
      </FigmaBox>
    </FigmaBox>
  )
}
